export type SortOrdersMenu = {
  text: string;
  label?: string;
  filter: string;
};

export type FloorPrice = {
  ticker: string;
  price: number;
};

export enum OrderType {
  TICKER = "Ticker",
  STXS = ".stxs",
  STXMAP = ".stxmap",
}
export const orderTypes = [OrderType.TICKER, OrderType.STXS, OrderType.STXMAP];
