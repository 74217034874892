import React from "react";
import classes from "./StatusButtonStyles.module.scss";
import classNames from "classnames";
import { OrderStatusIcon } from "../../OrderStatusIcon/OrderStatusIcon.tsx";
import { SellRequestStatus } from "../../../types/ApiResponseTypes.ts";
import { Loader } from "../../Loader/Loader.tsx";

type Props = {
  text: string;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  status?: SellRequestStatus | "Buy" | "Cancel";
};
export const StatusButton = ({
  text,
  onClick,
  disabled,
  className,
  status,
}: Props) => {
  return (
    <button
      className={classNames(
        classes.component,
        status === SellRequestStatus.WAITING_FOR_TRANSACTION &&
          classes.componentProcessing,
        className || ""
      )}
      onClick={onClick && onClick}
      disabled={disabled}
    >
      {status === SellRequestStatus.WAITING_FOR_TRANSACTION ? (
        <Loader small noMargin />
      ) : (
        <OrderStatusIcon status={status} />
      )}
      <span className={classes.text}>{text}</span>
    </button>
  );
};
