import React from "react";
import classes from "./Switch.module.scss";
import classNames from "classnames";

type Props = {
  checked: boolean;
  onChecked: () => void;
  className?: string;
};

export const Switch = ({ checked, onChecked, className }: Props) => {
  return (
    <div
      className={classNames(
        classes.component,
        className || "",
        checked && classes.checkedWrapper
      )}
      onClick={onChecked}
    >
      <input
        className={classes.customSwitch}
        type="checkbox"
        name="switch"
        value="1"
        checked={checked}
        onChange={onChecked}
      />
      <span className={classes.sliderRound}></span>
    </div>
  );
};
