import React from "react";
import { useAtom } from "jotai";
import { PropsWithChildren, useEffect } from "react";
import { useOkxWallet } from "./hooks/useOkxWallet.ts";
import { getProvider } from "./utils/getProvider.ts";
import { NETWORK, Network, PROVIDERS } from "./constants.ts";
import { AppConfig, UserSession } from "@stacks/connect";
import {useAddress} from './store/address.ts'
import { getUserSessionData } from './utils/getUserSessionData.ts';

export const WalletConnection: React.FC<PropsWithChildren> = ({children}) => {
    const [address, setAddress] = useAddress();
    const {requestAddressOtxWallet} = useOkxWallet();
    useEffect(() => {   
        const listener = () => {
            const selectedProvider = getProvider();
            if (!selectedProvider) {
                return;
            }
           if (selectedProvider === getProvider(PROVIDERS.Okx)) {
                requestAddressOtxWallet().then(response => setAddress(response.address));
                return;
           } else {
            const stxAddress  = getUserSessionData();
            setAddress(stxAddress)
           }
        }
        window.addEventListener('load', listener);
    }, []);

    return <>
        {children}
    </>
}