import { hcl, color } from 'd3-color'

export function hlToHex ({h, l}) {
  return hcl(h * 360, 78.225, l * 150);
}

function hexToHl (hex) {
  return color(hex);
}

export function mixColor (startColor, endColor, min, max, value) {
  const dx = Math.max(0, Math.min(1, (value - min) / (max - min)))
  return {
    h: startColor.h + (dx *(endColor.h - startColor.h)),
    l: startColor.l + (dx *(endColor.l - startColor.l))
  }
}
export const pink = { h: 0.825, l: 0.62 }
export const bluegreen = { h: 0.99, l: 0.99 }
export const orange = { h: 0.181, l: 0.472 }
export const teal = { h: 0.99, l: 0.99 }
export const blue = { h: 0.99, l: 0.99 }
export const green = { h: 0.99, l: 0.99 }
export const purple = { h: 0.825, l: 0.27 }
export const gray = {h: 0.475, l: 0.55}

export const highlightA = { h: 0.01, l: 0.01 } //pink
