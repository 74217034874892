import React from "react";
import classes from "./Radio.module.scss";
import classNames from "classnames";

type Props = {
  name: string;
  value: string;
  label: string;
  checked: boolean;
  onChecked: () => void;
  className?: string;
};

export const Radio = ({
  name,
  value,
  label,
  checked,
  onChecked,
  className,
}: Props) => {
  return (
    <div
      className={classNames(
        classes.component,
        checked && classes.checkedWrapper
      )}
    >
      <input
        className={classes.customRadio}
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={onChecked}
      />
      <label
        onClick={onChecked}
        className={classNames(classes.label, className || "")}
      >
        {label}
      </label>
    </div>
  );
};
