import BigNumber from "bignumber.js";
import {
  TransactionStatus,
  MemfloorTransactionStatus,
} from "@stacks/stacks-blockchain-api-types";

export interface Balance {
  ticker: string;
  balance: string;
  updateDate: string;
}

export interface BalanceAddress {
  address: string;
  balances: Balance[];
}

export interface TokenTicker {
  ticker: string;
  totalSupply: string;
  mintLimit: string;
  creationDate: string;
  supplyLeftToMint: string;
}

export interface BaseDomainModel {
  domain: string;
  txId: string;
  minter: string;
  owner: string;
  creationDate: Date;
}
export interface BaseStxmapModel {
  stxmap: string;
  txId: string;
  minter: string;
  owner: string;
  creationDate: Date;
}
export interface RefreshResponse {
  success: boolean;
  message?: string;
  data?: {
    jwtToken: string;
    mainNetAddress: string;
    testNetAddress: string;
    publicKey: string;
    refreshToken: {
      refreshToken: string;
    };
  };
}

type Estimation = {
  fee: number;
  fee_rate: number;
};

export interface StacksTxFeeEstimation {
  cost_scalar_change_by_byte: number;
  estimated_cost: object;
  estimated_cost_scalar: number;
  estimations: Estimation[];
  error?: string;
}

export interface Money {
  readonly amount: BigNumber;
  readonly symbol: string;
  readonly decimals: number;
}

export interface StacksFeeEstimate {
  fee: Money;
  feeRate: number;
}

export interface BuyTokenType {
  price?: string;
  orderId?: string;
  data?: any;
}

export interface IUserCreateSellRequest {
  ticker?: string;
  domain?: string;
  map?: string;
  value: string;
  beneficiary: string;
  stxValue: string;
}

export enum SellRequestStatus {
  WAITING_FOR_TRANSACTION = "waiting_for_transaction",
  CREATED = "created",
  BUYING_IN_PROCESS = "buying_in_process",
  BUYING_COMPLETED = "buying_completed",
  CANCEL_IN_PROCESS = "cancel_in_process",
  CANCEL_COMPLETED = "cancel_completed",
  DRAFT = "draft",
}

export interface IBaseSellRequest extends IUserCreateSellRequest {
  _id: string;
  marketFeeValue: string;
  totalStxValue: string;
  gasFeeValueSeller: string;
  gasFeeValueBuyer: string;
  creatorAddress: string;
  requestStatus: SellRequestStatus;
  tokenReceiverMarketplaceAddress: string;
  tokenSentConfirmed: boolean;
  stxSentConfirmed: boolean;
  tokenTxId?: string;
  stxTxId?: string;
  buyerAddress?: string;
  priceRate: number;
}

export interface IResultSellRequest {
  _id: string;
  ticker?: string;
  domain?: string;
  map?: string;
  value: string;
  beneficiary: string;
  stxValue: string;
  creatorAddress: string;
  marketFeeValue: string;
  totalStxValue: string;
  gasFeeValueBuyer: string;
  requestStatus: SellRequestStatus;
  tokenReceiverMarketplaceAddress: string;
  tokenSentConfirmed: boolean;
  stxSentConfirmed: boolean;
  tokenTxId?: string;
  stxTxId?: string;
  buyerAddress?: string;
  priceRate: number;
  pendingPurchaseTx?: string[];
  creationTxId?: string;
}

export interface OrdersFeeResponse {
  success: boolean;
  data?: {
    order_gas_fee: number;
    purchase_order_gas_fee: number;
    purchase_order_platform_fee_percent: number;
  };
}
export interface IPagination {
  page: number;
  limit: number;
  count: number;
}

export interface ResponseWithPagination<T> {
  success: boolean;
  data: T[];
  pagination: IPagination;
}

export interface IPurchaseTransaction {
  tx_id: string;
  tx_status: TransactionStatus | MemfloorTransactionStatus;
  sender_address: string;
  fee_rate: string;
}
export interface FloorPriceResponse {
  success: boolean;
  data?: {
    minPriceRate: number;
    maxPriceRate: number;
    medianPriceRate: number;
    meanPriceRate: number;
    medianMinPriceRate: number;
    medianMaxPriceRate: number;
  };
}
