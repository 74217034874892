export function logTxSize (value, max) {
  let scale = (Math.ceil(Math.log10(value)) - 5)
  return Math.min(max || Infinity,Math.max(1, scale)) 
}

export function byteTxSize (vbytes, max, log) {
  if (!vbytes) vbytes = 1
  let scale = Math.max(1,Math.ceil(Math.sqrt(vbytes/256)))
  return Math.min(max || Infinity,Math.max(1, scale)) 
}
