import React, { useCallback, useEffect, useState } from "react";
import classes from "./InscribeMint.module.scss";
import { validateStacksAddress } from "micro-stacks/crypto";
import { Input } from "../InscribeInput/Input.tsx";
import { BigPurpuleButton } from "../../Buttons/BigPurpuleButton/BigPurpuleButton.tsx";
import { getTokenTicker } from "../../../Axios/AxiosInstance.ts";
import { TokenTicker } from "../../../types/ApiResponseTypes.ts";
import useData from "../../../hooks/useData.ts";
import { openSTXTransfer } from "@stacks/connect";
import { Status } from "../../../constants.ts";
import { SuccessModal } from "../../Modals/SuccessModal/SuccessModal.tsx";
import { debounce } from "lodash";
import { useAddress } from "../../../store/address.ts";
import { resolveBnsName, resolveStxsName } from "../../../utils/validation.ts";
import { shortenAddress } from "../../../helpers.ts";

type Props = {};
export const InscribeMint = ({}: Props) => {
  const { stacksNetwork, makeStxTransfer } = useData();
  const [address] = useAddress();
  const [ticker, setTicker] = useState("");
  const [tickerError, setTickerError] = useState("");
  const [destination, setDestination] = useState("");
  const [destinationError, setDestinationError] = useState("");
  const [amount, setAmount] = useState("");
  const [amountError, setAmountError] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [searchTickerResult, setSearchTickerResult] = useState<
    TokenTicker | undefined
  >();
  const [totalCharacterSizeError, setTotalCharacterSizeError] = useState("");
  const [status, setStatus] = useState<Status | null>();
  const [bnsContent, setBnsContent] = useState<string | null>("");

  const fetchTicker = async (val: string) => {
    setTickerError("");
    if (val.length === 0) {
      return;
    }
    if (
      !/^[A-Z]+$/.test(val.toUpperCase()) ||
      val.length < 3 ||
      val.length > 8
    ) {
      setTickerError("Invalid ticker");
      return;
    }
    setLoading(true);

    try {
      const tokenResponse = await getTokenTicker(val.toUpperCase());
      // console.log(tokenResponse);
      setSearchTickerResult(tokenResponse);
      if (Number(amount) > Number(tokenResponse?.mintLimit)) {
        setAmountError("Minting limit exceeded");
      }
      tokenResponse.supplyLeftToMint === "0" &&
        setTickerError("Token minted out");
    } catch (error) {
      console.error(error);
      setTickerError("Ticker does not exist");
    } finally {
      setLoading(false);
    }
  };

  const debounceFn = useCallback(debounce(fetchTicker, 300), []);

  const handleMint = async () => {
    const mintMemo = `m${ticker}${amount}`;
    if (mintMemo.length > 33) {
      setTotalCharacterSizeError(
        "Total character limit exceeded for a single inscription. Try reducing the amount of decimals."
      );
      return;
    } else {
      setTotalCharacterSizeError("");
    }
    setLoading(true);
    // await handleSearch(ticker);
    try {
      await makeStxTransfer({
        data: {
          recipient: bnsContent ? bnsContent : destination,
          amount: "1",
          memo: mintMemo,
          network: stacksNetwork,
          stxAddress: address || "",
        },
        onFinish: () => {
          setStatus(Status.SUCCESS);
          setTicker("");
          setAmount("");
          setDestination("");
          setTickerError("");
          setLoading(false);
        },
        onCancel: () => {
          setStatus(Status.FAIL);
          setLoading(false);
        },
      });
    } catch (error) {
      setStatus(Status.FAIL);
      setLoading(false);
      console.error(error);
    }
  };

  const handleDestinationChange = (val) => {
    setDestination(val);
    setDestinationError("");
    if (!validateStacksAddress(val) && val.length > 0) {
      setDestinationError("Invalid STX address");
    }
    if (val === address) {
      setDestinationError("Connected address");
    }
    if (val.endsWith(".stxs")) {
      resolveStxsName(val).then((res) => {
        if (res) {
          setDestinationError("");
          setBnsContent(res);
        } else {
          setBnsContent(null);
          setDestinationError("Invalid STXS name");
        }
      });
    } else if (val.includes(".")) {
      resolveBnsName(val).then((res) => {
        if (res) {
          setDestinationError("");
          setBnsContent(res);
        } else {
          setDestinationError("Invalid BNS name");
        }
      });
    } else setBnsContent(null);
  };

  const handleAmountChange = (val) => {
    setAmount(val);
    setAmountError("");
    if (isNaN(Number(val))) {
      setAmountError("Invalid amount");
      return;
    }
    if (Number(val) > Number(searchTickerResult?.mintLimit)) {
      setAmountError("Minting limit exceeded");
    }
  };

  return (
    <div className={classes.wrapper}>
      {!!status && (
        <SuccessModal status={status} onClose={() => setStatus(null)} />
      )}
      <div className={classes.inputWrapper}>
        <div className={classes.item}>
          <Input
            name="ticker"
            placeholder="Ticker"
            value={ticker}
            onChange={(val) => {
              setTicker(val);
              debounceFn(val);
            }}
            error={tickerError}
          />
        </div>
        <div className={classes.item}>
          <Input
            name="destination"
            placeholder="Enter Address or BNS name"
            value={destination}
            onChange={handleDestinationChange}
            error={destinationError}
            info={shortenAddress(bnsContent || "")}
          />
        </div>
        <div className={classes.item}>
          <Input
            name="amount"
            placeholder="Amount"
            value={amount}
            onChange={handleAmountChange}
            error={amountError}
          />
        </div>
      </div>
      <span className={classes.warning}>
        Make sure you are minting to a different stacks address than the wallet
        you have connected
      </span>
      <div className={classes.buttonWrapper}>
        {totalCharacterSizeError && (
          <span className={classes.error}>{totalCharacterSizeError}</span>
        )}
        <BigPurpuleButton
          onClick={handleMint}
          text={"Mint"}
          disabled={
            amount === "" ||
            amountError !== "" ||
            ticker === "" ||
            tickerError !== "" ||
            destination === "" ||
            destinationError !== ""
          }
          className={classes.button}
        />
      </div>
    </div>
  );
};
