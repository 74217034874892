import React, { useState } from "react";
import classes from "./ConnectButtonStyles.module.scss";
import classNames from "classnames";

import { BigPurpuleButton } from "../Buttons/BigPurpuleButton/BigPurpuleButton.tsx";
import { uniqueId } from "lodash";

import { NETWORK, Network, PROVIDERS, ProviderInstallLinks } from "../../constants.ts";
import useData from "../../hooks/useData.ts";
import { shortenAddress } from "../../helpers.ts";
import { getProvider } from "../../utils/getProvider.ts";
import { useAddress } from "../../store/address.ts";

type ConnectWalletItem = {
  install: boolean;
  link: string;
  logo: string;
  title: string;
};

type Props = {
  className?: string;
};

export const ConnectButton = ({ className }: Props) => {
  const [menuOpened, setMenuOpened] = useState(false);
  const [loggedInButtonOpened, setLoggedInButtonOpened] = useState(false);
  const { makeAuthRequest, logout, isAuthorized } = useData();
  const [address] = useAddress();

  const WalletConnectors = [
    {
      title: "Leather",
      logo: "/images/logo-leather.svg",
      // @ts-ignore
      install: !!window.HiroProvider || !!window.LeatherProvider,
      link: ProviderInstallLinks.LEATHER,
    },
    {
      title: "Xverse",
      logo: "/images/logo-xverse.svg",
      // @ts-ignore
      install: !!window.XverseProviders?.StacksProvider,
      link: ProviderInstallLinks.XVERSE,
    },
    {
      title: "OKX",
      logo: "/images/logo-okx.png",
      install: !!getProvider(PROVIDERS.Okx),
      link: ProviderInstallLinks.OKX,
    },
  ];

  const handleWalletClick = async (item: ConnectWalletItem) => {
    const provider = getProvider(item.title as PROVIDERS);
    if (!provider) {
      window.open(item.link);
      return;
    }
    if (item.install && item.link !== "") {
      makeAuthRequest(item.title as PROVIDERS);
    }
  };

  return (
    <div className={classes.component}>
      {loggedInButtonOpened && (
        <div
          onClick={() => setLoggedInButtonOpened(false)}
          className={classes.overflowTransparent}
        />
      )}
      {isAuthorized && (
        <div className={classes.logWrapperBtn}>
          <div
            onClick={() => setLoggedInButtonOpened((prevState) => !prevState)}
            className={classes.loggedInWrapper}
          >
            <div className={classes.loggedInContent}>
              <span className={classes.walletAddress}>
                {shortenAddress(address || '')}
              </span>
              <span className={classes.currentNetwork}>{NETWORK}</span>
            </div>
            <img
              style={
                loggedInButtonOpened ? {} : { transform: "rotate(180deg)" }
              }
              className={classes.shevron}
              src="/images/shevron.svg"
              alt=""
            />
          </div>
          {loggedInButtonOpened && (
            <div className={classes.loggedInMenu}>
              <button className={classes.logoutButton} onClick={logout}>
                <img width={20} height={20} src="/images/power.svg" alt="" />
                <span className={classes.disconnectText}>Disconnect</span>
              </button>
            </div>
          )}
        </div>
      )}
      {!isAuthorized && (
        <BigPurpuleButton
          onClick={() => setMenuOpened((prevState) => !prevState)}
          text={"Connect wallet"}
          className={classNames(classes.connectWalletButton, className || "")}
        />
      )}
      {menuOpened && (
        <div
          onClick={() => setMenuOpened(false)}
          className={classes.overflow}
        />
      )}
      {menuOpened && (
        <div className={classes.menuContainer}>
          <div className={classes.menuHeader}>
            <span className={classes.menuTitle}>Connect wallet</span>
            <img
              src="/images/icon-close.svg"
              alt="Close"
              onClick={() => setMenuOpened(false)}
              className={classes.link}
            />
          </div>
          <div className={classes.menuButtons}>
            {WalletConnectors.map((item) => (
              <div
                key={uniqueId(item.title)}
                className={classNames(
                  classes.connectMenuItem2,
                  !item.install && classes.notinstall
                )}
                onClick={() => {
                  handleWalletClick(item);
                  setMenuOpened(false);
                }}
              >
                <img src={item.logo} alt="" />
                <span>{item.title}</span>
                {!item.install && (
                  <a
                    href={item.link}
                    target={"_blank"}
                    className={classes.installButton}
                  >
                    <span>Install</span>
                    <img src="/images/arrow.svg" />
                  </a>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
