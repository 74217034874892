import React from "react";
import classes from "./Header.module.scss";
import { uniqueId } from "lodash";
import { Wrapper } from "../Wrapper/Wrapper.tsx";
import classNames from "classnames";
import { ConnectButton } from "../ConnectButton/ConnectButton.tsx";
import { DisplayType, Links } from "../../constants.ts";
import { NavLink } from "react-router-dom";

export const Header: React.FC = () => {
  return (
    <div className={classes.component}>
      <Wrapper className={classes.wrapper}>
        <div className={classes.logoWrapper}>
          <img src="/images/logo.png" width={43} height={51} alt="" />
          <h3 className={classes.txtLogo}>
            STX<span>20</span>
          </h3>
        </div>
        <div className={classes.switchWrapper}>
          {Object.values(DisplayType).map((type) => (
            <NavLink
              key={uniqueId(type)}
              className={({ isActive }) =>
                classNames(
                  isActive
                    ? classes.switchButtonGradientActive
                    : classes.switchButtonGradientNew,
                  type === DisplayType.POINTS && classes.switchItemDisabled
                )
              }
              onClick={(ev) => {
                if (type === DisplayType.POINTS) {
                  ev.preventDefault();
                }
              }}
              to={type.toLocaleLowerCase()}
            >
              <span>{type}</span>
            </NavLink>
          ))}
        </div>
        <div className={classes.buttonsWrapper}>
          <button
            onClick={() => window.open(Links.PROTOCOL, "_blank")}
            className={classes.protocolButton}
          >
            Protocol docs
          </button>
          <ConnectButton />
        </div>
      </Wrapper>
    </div>
  );
};
