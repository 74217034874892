import React, { useCallback, useEffect, useState } from "react";
import classes from "./InscribeDeploy.module.scss";
import { validateStacksAddress } from "micro-stacks/crypto";
import { Input } from "../InscribeInput/Input.tsx";
import { BigPurpuleButton } from "../../Buttons/BigPurpuleButton/BigPurpuleButton.tsx";
import { getTokenTicker } from "../../../Axios/AxiosInstance.ts";
import useData from "../../../hooks/useData.ts";
import { openSTXTransfer } from "@stacks/connect";

import { Status } from "../../../constants.ts";
import { SuccessModal } from "../../Modals/SuccessModal/SuccessModal.tsx";
import { debounce } from "lodash";
import { validateIntegerValue } from "../../../helpers.ts";
import { useAddress } from "../../../store/address.ts";
import { resolveBnsName, resolveStxsName } from "../../../utils/validation.ts";
import { shortenAddress } from "../../../helpers.ts";

type Props = {};

export const InscribeDeploy = ({}: Props) => {
  const { stacksNetwork, makeStxTransfer } = useData();
  const [address] = useAddress();

  const [ticker, setTicker] = useState("");
  const [tickerError, setTickerError] = useState("");
  const [destination, setDestination] = useState("");
  const [destinationError, setDestinationError] = useState("");
  const [total, setTotal] = useState("");
  const [totalError, setTotalError] = useState("");
  const [limit, setLimit] = useState("");
  const [limitError, setLimitError] = useState("");
  const [totalCharacterSizeError, setTotalCharacterSizeError] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [status, setStatus] = useState<Status | null>();
  const [bnsContent, setBnsContent] = useState<string | null>("");

  const fetchTicker = async (val: string) => {
    setTickerError("");
    if (val.length === 0) {
      return;
    }
    if (
      !/^[A-Z]+$/.test(val.toUpperCase()) ||
      val.length < 3 ||
      val.length > 8
    ) {
      setTickerError("Invalid ticker");
      return;
    }

    setLoading(true);

    try {
      const tokenResponse = await getTokenTicker(val.toUpperCase());
      console.log(tokenResponse);
      setTickerError("Ticker already exists");
    } catch (error) {
      setTickerError("");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const debounceFn = useCallback(debounce(fetchTicker, 300), []);

  const handleDeploy = async () => {
    const deployMemo = `d${ticker}${total};${limit}`;
    if (deployMemo.length > 33) {
      setTotalCharacterSizeError(
        "Total character limit exceeded for a single inscription. Try reducing the amount of symbols or decimals."
      );
      return;
    } else {
      setTotalCharacterSizeError("");
    }
    setLoading(true);
    // console.log(ticker, total, destination, limit);
    // await handleSearch(ticker);
    try {
      await makeStxTransfer({
        data: {
          recipient: bnsContent ? bnsContent : destination,
          amount: "1",
          memo: deployMemo,
          network: stacksNetwork,
          stxAddress: address || "",
        },
        onFinish: () => {
          setStatus(Status.SUCCESS);
          setTicker("");
          setDestination("");
          setTotal("");
          setLimit("");
          setTickerError("");
          setLoading(false);
        },
        onCancel: () => {
          setStatus(Status.FAIL);
          setLoading(false);
        },
      });
    } catch (error) {
      setStatus(Status.FAIL);
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (!limit || !total) return;
    if (Number(limit) > Number(total)) {
      setLimitError("Limit =< Total");
      return;
    }
    if (Number(limit) < Number(total)) {
      validateIntegerValue(limit, setLimitError, "", "Invalid limit");
      return;
    }
  }, [limit, total]);

  const handleDestinationChange = (val) => {
    setDestination(val);
    setDestinationError("");
    if (!validateStacksAddress(val) && val.length > 0) {
      setDestinationError("Invalid STX address");
    }
    if (val.endsWith(".stxs")) {
      resolveStxsName(val).then((res) => {
        if (res) {
          setDestinationError("");
          setBnsContent(res);
        } else {
          setBnsContent(null);
          setDestinationError("Invalid STXS name");
        }
      });
    } else if (val.includes(".")) {
      resolveBnsName(val).then((res) => {
        if (res) {
          setDestinationError("");
          setBnsContent(res);
        } else {
          setDestinationError("Invalid BNS name");
        }
      });
    } else setBnsContent(null);
  };

  return (
    <div className={classes.wrapper}>
      {!!status && (
        <SuccessModal status={status} onClose={() => setStatus(null)} />
      )}
      <div className={classes.inputWrapper}>
        <div className={classes.item}>
          <Input
            name="ticker"
            placeholder="Ticker"
            value={ticker}
            onChange={(val) => {
              setTicker(val);
              debounceFn(val);
            }}
            error={tickerError}
          />
        </div>
        <div className={classes.item}>
          <Input
            name="destination"
            placeholder="Enter Address or BNS name (does not affect the deploy process)"
            value={destination}
            onChange={handleDestinationChange}
            error={destinationError}
            info={shortenAddress(bnsContent || "")}
          />
        </div>
        <div className={classes.item}>
          <Input
            name="total"
            placeholder="Total supply"
            value={total}
            onChange={(val) => {
              setTotal(val);
              validateIntegerValue(val, setTotalError, "", "Invalid total");
            }}
            error={totalError}
          />
        </div>
        <div className={classes.item}>
          <Input
            name="limit"
            placeholder="Limit per mint"
            value={limit}
            onChange={(val) => {
              setLimit(val);
              validateIntegerValue(val, setLimitError, "", "Invalid limit");
            }}
            error={limitError}
          />
        </div>
      </div>
      <div className={classes.buttonWrapper}>
        {totalCharacterSizeError && (
          <span className={classes.error}>{totalCharacterSizeError}</span>
        )}
        <BigPurpuleButton
          onClick={handleDeploy}
          text={"Deploy"}
          disabled={
            total === "" ||
            totalError !== "" ||
            ticker === "" ||
            tickerError !== "" ||
            limit === "" ||
            limitError !== "" ||
            destination === "" ||
            destinationError !== ""
          }
          className={classes.button}
        />
      </div>
    </div>
  );
};
