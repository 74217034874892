import { LocalStorageKeys, PROVIDERS } from "../constants.ts";

export const getProvider = (connectionProvider?: PROVIDERS) => {
    const provider = connectionProvider || localStorage.getItem(LocalStorageKeys.WALLET_TYPE);
    // @ts-ignore
    // return provider === 'Xverse' ? window.XverseProviders?.StacksProvider : window.StacksProvider;
    switch (provider) {
        /*case PROVIDERS.Asigna:
            // @ts-ignore
            return window.AsignaProvider;*/
        case PROVIDERS.Okx:
            // @ts-ignore
            return window.okxwallet?.stacks;
        case PROVIDERS.Xverse:
            // @ts-ignore
            return window.XverseProviders?.StacksProvider || window.StacksProvider;
        case PROVIDERS.Leather:
            // @ts-ignore
            return window.HiroProvider || window.LeatherProvider || window.StacksProvider;
    }
};