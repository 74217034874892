import React from 'react';
import classes from './CopyButtonStyles.module.scss';
import classNames from 'classnames';

type Props = {
  address: string;
  title?: string;
  className?: string;
};
export const CopyButton = ({ address, title, className }: Props) => {
  return (
    <div
      className={classNames(
        classes.component,
        title && classes.withTitle,
        className || ''
      )}
      onClick={() => navigator.clipboard.writeText(address)}
    >
      <img src="/images/icon-copy.svg" alt="" />
      {title && <span className={classes.title}>{title}</span>}
    </div>
  );
};
