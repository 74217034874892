import React from "react";
import classes from "./PendingButtonStyles.module.scss";
import classNames from "classnames";
import { Loader } from "../../Loader/Loader.tsx";

type Props = {
  countTx: number;
  onClick?: () => void;
  className?: string;
};
export const PendingButton = ({ countTx, onClick, className }: Props) => {
  return (
    <button
      className={classNames(classes.component, className || "")}
      onClick={onClick && onClick}
    >
      <Loader small />
      <span className={classes.text}>
        {countTx} pending purchase{countTx > 1 ? "s" : ""}
      </span>
    </button>
  );
};
