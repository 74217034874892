import { Network, STX_DECIMALS } from "./constants.ts";
import { StacksMainnet, StacksNetwork, StacksTestnet } from "@stacks/network";
import { SellRequestStatus } from "./types/ApiResponseTypes.ts";

export function shortenAddress(address = "", slice = 5, endSlice = 3) {
  if (address.length < 11) {
    return address;
  }

  return `${address.slice(0, slice)}...${address.slice(-endSlice)}`;
}

export function shortenContract(address = "", slice = 7, endSlice = 5) {
  if (address.length < 11) {
    return address;
  }

  return `${address.split(".")[0].slice(0, slice)}...${address
    .split(".")[0]
    .slice(-endSlice)}.${address.split(".")[1]}`;
}

export function removeEmptyFields(obj: any) {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([key, value]) => value !== null && value !== undefined && value !== ""
    )
  );
}

export const getStacksNetwork = (network: Network): StacksNetwork => {
  switch (network) {
    case Network.MAINNET:
      return new StacksMainnet();
    case Network.TESTNET:
      return new StacksTestnet();
  }
};

export const validateIntegerValue = (
  value: string,
  onInvalid: (status: string) => void,
  fieldName: string,
  customError?: string
) => {
  const numbersRegexp = /[^0-9]/;
  if (Number(value) % 1 !== 0 || numbersRegexp.test(value))
    return onInvalid(
      customError ? customError : `${fieldName} must be integer`
    );
  return onInvalid("");
};

export const validateNonIntegerValue = (
  value: string,
  onInvalid: (status: string) => void,
  fieldName: string
) => {
  const nonIntegerRegexp = /[^0-9.]/;
  if (nonIntegerRegexp.test(value))
    return onInvalid(`${fieldName} must be a valid number`);
  return onInvalid("");
};

export const countStxAmount = (amount: string) =>
  amount / Math.pow(10, STX_DECIMALS);

export const SellRequestStatusTitle = (status: SellRequestStatus) => {
  switch (status) {
    case SellRequestStatus.BUYING_IN_PROCESS: {
      return "Buying in process";
    }
    case SellRequestStatus.CANCEL_COMPLETED: {
      return "Cancel completed";
    }
    case SellRequestStatus.BUYING_COMPLETED: {
      return "Buying completed";
    }
    case SellRequestStatus.CANCEL_IN_PROCESS: {
      return "Cancel in process";
    }
    case SellRequestStatus.CREATED: {
      return "Created";
    }
    case SellRequestStatus.WAITING_FOR_TRANSACTION: {
      return "In processing queue";
    }
    case SellRequestStatus.DRAFT: {
      return "Draft";
    }
    default:
      return "";
  }
};

export const titleDbTxTypeId = (type: number) => {
  const typeIds = {
    0: "Token Transfer",
    1: "Smart Contract",
    2: "Contract Call",
    3: "Poison Microblock",
    4: "Coinbase",
    5: "Coinbase To Alt Recipient",
    6: "Versioned Smart Contract",
    7: "Tenure Change",
    8: "Nakamoto Coinbase",
  };
  return typeIds[type];
};
