import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import {
  DEV_MARKETPLACE_URL,
  DEV_STXMAP_URL,
  DEV_URL,
  PROD_MARKETPLACE_URL,
  PROD_STXMAP_URL,
  PROD_URL,
} from "./constants.ts";
import {
  BalanceAddress,
  BuyTokenType,
  IBaseSellRequest,
  IPurchaseTransaction,
  IResultSellRequest,
  IUserCreateSellRequest,
  OrdersFeeResponse,
  FloorPriceResponse,
  RefreshResponse,
  ResponseWithPagination,
  TokenTicker,
  BaseStxmapModel,
  BaseDomainModel,
  SellRequestStatus,
} from "../types/ApiResponseTypes.ts";
import { LocalStorageKeys, IS_PROD } from "../constants.ts";
import { OrderType } from "../types/orders.ts";
import { wrapTxId } from "../utils/validation.ts";

export const getTokens = () => {
  return {
    authToken: localStorage.getItem(LocalStorageKeys.JWT_TOKEN),
    refreshToken: localStorage.getItem(LocalStorageKeys.JWT_REFRESH),
  };
};

const REFRESH_ROUTE = "auth/refresh";

interface InternalAxiosRequestConfig extends AxiosRequestConfig {
  isRetry?: boolean;
}

const Stx20AxiosInstance: AxiosInstance = axios.create({
  baseURL: IS_PROD ? PROD_URL : DEV_URL,
});

const MarketplaceStx20AxiosInstance: AxiosInstance = axios.create({
  baseURL: IS_PROD ? PROD_MARKETPLACE_URL : DEV_MARKETPLACE_URL,
});

const StxMapAxiosInstance: AxiosInstance = axios.create({
  baseURL: IS_PROD ? PROD_STXMAP_URL : DEV_STXMAP_URL,
});

MarketplaceStx20AxiosInstance.interceptors.request.use((config) => {
  const { authToken } = getTokens();
  if (config.headers) {
    if (!config.headers["Authorization"]) {
      config.headers["Authorization"] = `Bearer ${authToken}`;
    }
  }

  return config;
});

MarketplaceStx20AxiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  async (error: AxiosError) => {
    const originalConfig = error.config as InternalAxiosRequestConfig;

    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403) &&
      error.config &&
      error.config.url === REFRESH_ROUTE
    ) {
      localStorage.clear();
      window.location.reload();

      return;
    }

    if (error.response?.status === 401 && !originalConfig.isRetry) {
      originalConfig.isRetry = true;
      try {
        const { refreshToken } = getTokens();
        const refreshResponse =
          await MarketplaceStx20AxiosInstance.post<RefreshResponse>(
            "auth/refresh",
            {
              refreshToken,
            }
          );
        if (refreshResponse.data.data) {
          const newAuthToken = refreshResponse.data.data.jwtToken;
          const newRefresh =
            refreshResponse.data.data.refreshToken.refreshToken;
          localStorage.setItem(LocalStorageKeys.JWT_TOKEN, newAuthToken);
          localStorage.setItem(LocalStorageKeys.JWT_REFRESH, newRefresh);

          if (!originalConfig.headers) return;
          originalConfig.headers["Authorization"] = `Bearer ${newAuthToken}`;

          return MarketplaceStx20AxiosInstance(originalConfig);
        }
      } catch (refreshError) {
        console.error(`${refreshError}`);

        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  }
);

export const getBalanceAddress = async (address: string) =>
  Stx20AxiosInstance.get<BalanceAddress>(`balance/${address}`, {}).then(
    (data) => {
      return data.data;
    }
  );

export const getDomainsByAddress = async (
  address: string,
  page: number,
  limit: number
) =>
  Stx20AxiosInstance.get<ResponseWithPagination<BaseDomainModel>>(
    `domain?address=${address}&page=${page}&limit=${limit}`,
    {}
  ).then((data) => {
    return data.data;
  });

export const getStxMapByAddress = async (
  address: string,
  page: number,
  limit: number
) =>
  Stx20AxiosInstance.get<ResponseWithPagination<BaseStxmapModel>>(
    `stxmap?address=${address}&page=${page}&limit=${limit}`,
    {}
  ).then((data) => {
    return data.data;
  });

export const getTokenTicker = async (ticker: string) =>
  Stx20AxiosInstance.get<TokenTicker>(`token/${ticker}`, {}).then((data) => {
    return data.data;
  });

export const getDomainDetail = async (domain: string) =>
  Stx20AxiosInstance.get<{ data: BaseDomainModel }>(
    `domain/${domain}`,
    {}
  ).then((data) => {
    return data.data;
  });

export const getStxMapDetail = async (stxmap: string) =>
  Stx20AxiosInstance.get<{ data: BaseStxmapModel }>(
    `stxmap/${stxmap}`,
    {}
  ).then((data) => {
    return data.data;
  });

export const login = async ({
  signature,
  address,
  publicKey,
  setIsAuthorized,
}: {
  signature: string;
  address: string;
  publicKey: string;
  setIsAuthorized: (status: boolean) => void;
}) => {
  try {
    const response = await MarketplaceStx20AxiosInstance.post("/auth", {
      signature,
      address,
      publicKey,
    });
    const token = response.data.data;
    localStorage.setItem(LocalStorageKeys.JWT_TOKEN, token.jwtToken);
    localStorage.setItem(
      LocalStorageKeys.JWT_REFRESH,
      token.refreshToken.refreshToken
    );
    setIsAuthorized(true);
    MarketplaceStx20AxiosInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${token.jwtToken}`;
  } catch (error) {
    console.error(error);
  }
};

export const createOrder = async (
  nameValue: string,
  value: string,
  beneficiary: string,
  stxValue: string,
  orderType: OrderType
): Promise<IBaseSellRequest> => {
  return orderType === OrderType.TICKER
    ? ((
        await MarketplaceStx20AxiosInstance.post<BuyTokenType>(
          `/sell-requests`,
          {
            ticker: nameValue,
            value,
            beneficiary,
            stxValue,
          } as IUserCreateSellRequest
        )
      ).data.data as any)
    : orderType === OrderType.STXS
    ? ((
        await MarketplaceStx20AxiosInstance.post<BuyTokenType>(
          `/domain-sell-requests`,
          {
            domain: nameValue,
            value: "0",
            beneficiary,
            stxValue,
          } as IUserCreateSellRequest
        )
      ).data.data as any)
    : ((
        await MarketplaceStx20AxiosInstance.post<BuyTokenType>(
          `/map-sell-requests`,
          {
            // map: nameValue.toLowerCase() + ".stxmap",
            map: nameValue.toLowerCase(),
            value: "0",
            beneficiary,
            stxValue,
          } as IUserCreateSellRequest
        )
      ).data.data as any);
};

export const finalizeOrderCreation = async (
  requestId: string,
  txId: string,
  orderType: OrderType
): Promise<IBaseSellRequest> => {
  const url =
    orderType === OrderType.TICKER
      ? `/sell-requests/${requestId}`
      : orderType === OrderType.STXMAP
      ? `/map-sell-requests/${requestId}`
      : `/domain-sell-requests/${requestId}`;
  return (
    await MarketplaceStx20AxiosInstance.put<BuyTokenType>(url, {
      txId: wrapTxId(txId),
    } as IUserCreateSellRequest)
  ).data.data as any;
};

export const finalizeOrderPurchase = async (
  requestId: string,
  txId: string,
  orderType: OrderType
): Promise<IBaseSellRequest> => {
  const url =
    orderType === OrderType.TICKER
      ? `/sell-requests/buy/${requestId}`
      : orderType === OrderType.STXMAP
      ? `/map-sell-requests/buy/${requestId}`
      : `/domain-sell-requests/buy/${requestId}`;
  return (
    await MarketplaceStx20AxiosInstance.put<BuyTokenType>(url, {
      txId: wrapTxId(txId),
    } as IUserCreateSellRequest)
  ).data.data as any;
};

export const cancelOrder = async (
  id: string,
  signature: string,
  orderType: OrderType
) => {
  const url =
    orderType === OrderType.TICKER
      ? `/sell-requests/${id}?signature=${signature}`
      : orderType === OrderType.STXMAP
      ? `/map-sell-requests/${id}?signature=${signature}`
      : `/domain-sell-requests/${id}?signature=${signature}`;

  return MarketplaceStx20AxiosInstance.delete<void>(url, {});
};

export const getAllListedOrders = async (
  ticker: string,
  pendingTx: boolean,
  filter: Record<string, string>
): Promise<ResponseWithPagination<IResultSellRequest>> => {
  const urlParams = new URLSearchParams(filter).toString();
  return (
    await MarketplaceStx20AxiosInstance.post<BuyTokenType>(
      `/sell-requests/search?${urlParams}`,
      {
        ticker,
        pendingTx,
      }
    )
  ).data as any;
};

export const getStxPrice = async (): Promise<{
  data: { priceUsd: string };
}> => {
  return (await MarketplaceStx20AxiosInstance.get<{}>(`/exchange-rates`))
    .data as any;
};

export const getAllMyOrders = async (
  ticker: string,
  statuses: string[],
  role: string,
  filter: Record<string, string>
): Promise<ResponseWithPagination<IResultSellRequest>> => {
  const urlParams = new URLSearchParams(filter).toString();
  return (
    await MarketplaceStx20AxiosInstance.post<BuyTokenType>(
      `/sell-requests/search-my?${urlParams}`,
      {
        ticker,
        status: statuses,
        role,
      }
    )
  ).data as any;
};
export const getAllMyDomainOrders = async (
  ticker: string,
  statuses: string[],
  role: string,
  filter: Record<string, string>
): Promise<ResponseWithPagination<IResultSellRequest>> => {
  const urlParams = new URLSearchParams(filter).toString();
  return (
    await MarketplaceStx20AxiosInstance.post<BuyTokenType>(
      `/domain-sell-requests/search-my?${urlParams}`,
      {
        domain: ticker,
        status: statuses,
        role,
      }
    )
  ).data as any;
};
export const getAllMyMapOrders = async (
  ticker: string,
  statuses: string[],
  role: string,
  filter: Record<string, string>
): Promise<ResponseWithPagination<IResultSellRequest>> => {
  const urlParams = new URLSearchParams(filter).toString();
  return (
    await MarketplaceStx20AxiosInstance.post<BuyTokenType>(
      `/map-sell-requests/search-my?${urlParams}`,
      {
        map: ticker,
        status: statuses,
        role,
      }
    )
  ).data as any;
};

export const getOrdersFee = async () =>
  MarketplaceStx20AxiosInstance.get<OrdersFeeResponse>(
    `/sell-requests/fees`,
    {}
  ).then((data) => {
    return data.data;
  });

export const getPurchaseTransactionInformation = async (
  requestId: string,
  orderType: OrderType
) => {
  const url =
    orderType === OrderType.TICKER
      ? `/sell-requests/tx/${requestId}`
      : orderType === OrderType.STXMAP
      ? `/map-sell-requests/tx/${requestId}`
      : `/domain-sell-requests/tx/${requestId}`;

  return MarketplaceStx20AxiosInstance.get<{ data: IPurchaseTransaction[] }>(
    url,
    {}
  ).then((data) => {
    return data.data.data;
  });
};

export const getFloorPriceInfo = async (ticker: string) => {
  // console.log("getFloorPrice", ticker);
  return MarketplaceStx20AxiosInstance.get<FloorPriceResponse>(
    `/sell-requests/floor-price/${ticker}`,
    {}
  ).then((data) => {
    return data.data;
  });
};

export const getBlockHeight = async (height: string) => {
  return StxMapAxiosInstance.get("/" + height).then((data) => {
    return data.data;
  });
};

export const getAllListedStxmaps = async (
  map: string,
  pendingTx: boolean,
  filter: Record<string, string>,
  mapStart: string,
  mapEnd: string
): Promise<ResponseWithPagination<IResultSellRequest>> => {
  const urlParams = new URLSearchParams(filter).toString();
  return (
    await MarketplaceStx20AxiosInstance.post<BuyTokenType>(
      `/map-sell-requests/search?${urlParams}`,
      {
        map,
        pendingTx,
        mapStart,
        mapEnd,
      }
    )
  ).data as any;
};

export const getAllListedDomains = async (
  domain: string,
  pendingTx: boolean,
  filter: Record<string, string>
): Promise<ResponseWithPagination<IResultSellRequest>> => {
  const urlParams = new URLSearchParams(filter).toString();
  return (
    await MarketplaceStx20AxiosInstance.post<BuyTokenType>(
      `/domain-sell-requests/search?${urlParams}`,
      {
        domain,
        pendingTx,
      }
    )
  ).data as any;
};
