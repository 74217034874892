// @ts-nocheck
export class FastVertexArray {
  constructor (length, stride, counter) {
    this.length = length
    this.counter = counter
    this.count = 0
    this.stride = stride
    this.sprites = []
    this.data = new Float32Array(this.length * this.stride)
    this.freeSlots = []
    this.lastSlot = 0
    this.nullSprite = new Float32Array(this.stride)
  }

  print () {
  }

  insert (sprite) {
    this.count++
    if (this.counter) this.counter++;

    let position
    if (this.freeSlots.length) {
      position = this.freeSlots.shift()
    } else {
      position = this.lastSlot
      this.lastSlot++
      if (this.lastSlot > this.length) {
        this.expand()
      }
    }
    this.sprites[position] = sprite
    return position
  }

  remove (index) {
    this.count--
    if (this.counter) this.counter--;
    this.setData(index, this.nullSprite)
    this.freeSlots.push(index)
    this.sprites[index] = null
    if (this.length > 2048 && this.count < (this.length * 0.4)) this.compact()
  }

  setData (index, dataChunk) {
    this.data.set(dataChunk, (index * this.stride))
  }

  getData (index) {
    return this.data.subarray(index, this.stride)
  }

  expand () {
    this.length *= 2
    const newData = new Float32Array(this.length * this.stride)
    newData.set(this.data)
    this.data = newData
    this.print()
  }

  compact () {
    const newLength = Math.max(512, Math.pow(2, Math.ceil(Math.log2(this.count))))
    if (this.newLength != this.length) {
      this.length = newLength
      this.data = new Float32Array(this.length * this.stride)
      let sprite
      const newSprites = []
      let i = 0
      for (var index in this.sprites) {
        sprite = this.sprites[index]
        if (sprite) {
          newSprites.push(sprite)
          sprite.moveVertexPointer(i)
          sprite.compile()
          i++
        }
      }
      this.sprites = newSprites
      this.freeSlots = []
      this.lastSlot = i
    }
    this.print()
  }

  getVertexData () {
    return this.data
  }
}
