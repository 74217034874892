import {
  ContractCallOptions,
  STXTransferOptions,
  SignatureData,
} from "@stacks/connect";
import { PROVIDERS } from "../constants.ts";
import { getProvider } from "../utils/getProvider.ts";

export const useOkxWallet = () => {
  const requestAddressOtxWallet = async (): Promise<{ address: string }> => {
    return await getProvider(PROVIDERS.Okx).connect();
  };

  const authenticateOkxWallet = async (): Promise<{
    address: string;
    publicKey: string;
  }> => {
    return await getProvider(PROVIDERS.Okx).connect();
  };

  const signMessageOkxWallet = async (data: {
    message: string;
  }): Promise<SignatureData> => {
    return await getProvider(PROVIDERS.Okx).signMessage(data);
  };

  const signTransactionOkxWallet = async (
    transaction: STXTransferOptions
  ): Promise<{
    txHash: string;
    signature: string;
  }> => {
    return getProvider(PROVIDERS.Okx).signTransaction({
      ...transaction,
      txType: "token_transfer",
    });
  };

  const signContractCallOkxWallet = async (
    transaction: ContractCallOptions
  ): Promise<{
    txHash: string;
    signature: string;
  }> => {
    return getProvider(PROVIDERS.Okx).signTransaction({
      ...transaction,
      txType: "contract_call",
    });
  };

  return {
    authenticateOkxWallet,
    signMessageOkxWallet,
    signTransactionOkxWallet,
    signContractCallOkxWallet,
    requestAddressOtxWallet,
  };
};
