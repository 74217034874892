import { useAtom } from "jotai";
import { floorPricesAtom } from "../store/floorPrices.ts";
import { getFloorPriceInfo } from "../Axios/AxiosInstance.ts";
import { FloorPrice } from "../types/orders.ts";

export const useFloorPrices = () => {
  const [floorPrices, setFloorPrices] = useAtom(floorPricesAtom);

  const getFloorPrice = (ticker: string) => {
    const tickerPrice = floorPrices?.find(
      (floorPrice) => floorPrice.ticker === ticker
    );
    return tickerPrice?.price || 0;
  };

  const loadFloorPrice = async (ticker: string) => {
    const tickerPrice = floorPrices?.find(
      (floorPrice) => floorPrice.ticker === ticker
    );
    if (tickerPrice) return;
    try {
      const res = await getFloorPriceInfo(ticker);
      if (res?.success) {
        setFloorPrices((prevState) => {
          const result = Object.values(
            [
              ...prevState,
              { ticker: ticker, price: res.data?.medianMinPriceRate || 0 },
            ].reduce((acc, obj) => ({ ...acc, [obj.ticker]: obj }), {})
          );
          return result as FloorPrice[];
        });
      }
    } catch (error) {
      setFloorPrices((prevState) => {
        const result = Object.values(
          [...prevState, { ticker: ticker, price: 0 }].reduce(
            (acc, obj) => ({ ...acc, [obj.ticker]: obj }),
            {}
          )
        );
        return result as FloorPrice[];
      });

      console.error("Error fetching floor price:", error);
    }
  };

  return { getFloorPrice, loadFloorPrice };
};
