import React, { useEffect, useState } from "react";
import classes from "./SearchTicker.module.scss";
import classNames from "classnames";
import { uniqueId } from "lodash";
import { useFloorPrices } from "../../hooks/useFloorPrices.ts";
import { useFees } from "../../hooks/useFees.ts";
import { STX_DECIMALS } from "../../constants.ts";
import { displayFullAmount } from "../../types/balance-tools.ts";

type Props = {
  name: string;
  placeholder: string;
  value: string;
  onChange: (val: string) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onSearch: (val: string) => void;
  className?: string;
  classNameWrapper?: string;
  listTickers?: string[];
  error: string;
};
export const SearchTicker = ({
  name,
  placeholder,
  value,
  onChange,
  className,
  classNameWrapper,
  listTickers = [],
  error,
  onKeyDown,
  onSearch,
}: Props) => {
  const { getFloorPrice, loadFloorPrice } = useFloorPrices();
  const { stxPrice } = useFees();
  const [isOpenListTickers, setOpenListTickers] = useState(false);

  useEffect(() => {
    if (listTickers.length === 0) return;
    listTickers.map((ticker) => {
      loadFloorPrice(ticker);
    });
  }, [listTickers]);

  return (
    <div className={classNames(classes.component, classNameWrapper || "")}>
      <img src="/images/icon-search.svg" alt="" className={classes.icon} />
      <input
        value={value}
        type="text"
        autoComplete="off"
        name={name}
        placeholder={placeholder}
        className={classNames(
          classes.input,
          className || "",
          error.length > 0 && classes.inputError
        )}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        // onKeyDown={(e) => {
        //   onKeyDown && onKeyDown(e);
        // }}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            onSearch(value);
            setOpenListTickers(false);
          }
        }}
        onFocus={() => setOpenListTickers(true)}
      />
      {error && <span className={classes.error}>{error}</span>}
      {value.length > 0 && <div className={classes.clear}></div>}
      {isOpenListTickers && listTickers.length > 0 && (
        <>
          <div
            className={classes.menuBg}
            onClick={() => setOpenListTickers(false)}
          />
          <div className={classes.outerWrapper}>
            <div className={classes.dropdownWrapper}>
              {listTickers.map((ticker, index) => {
                const floorPrice =
                  getFloorPrice(ticker) / Math.pow(10, STX_DECIMALS);
                const priceUsd = floorPrice * Number(stxPrice);
                return (
                  <div
                    key={uniqueId(ticker)}
                    className={classes.tickerItem}
                    onClick={() => {
                      onChange(ticker);
                      onSearch(ticker);
                      setOpenListTickers(false);
                    }}
                  >
                    <div className={classes.tickerId}>{`#${index + 1}`}</div>
                    <div className={classes.tickerTitleWrapper}>
                      <div className={classes.tickerTitle}>{ticker}</div>
                      <div className={classes.tickerFloorPrice}>
                        <span>{displayFullAmount(floorPrice, 4)} STX</span> / $
                        {displayFullAmount(priceUsd, 2)} / {ticker}
                      </div>
                    </div>
                    <div className={classes.tickerDataWrapper}></div>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
