import React from "react";
import classes from "./InscribeInput.module.scss";
import classNames from "classnames";
import { shortenContract } from "../../../helpers.ts";

type Props = {
  name: string;
  placeholder: string;
  value: string;
  error?: string;
  info?: string;
  onChange: (val: string) => void;
  onSearch?: () => void;
  className?: string;
  errorAndInfo?: boolean;
  max?: number;
  disabled?: boolean;
  contract?: boolean;
};

//TODO: Move this file to components
export const Input = ({
  name,
  placeholder,
  value,
  onChange,
  onSearch,
  error,
  info,
  className,
  errorAndInfo = false,
  max = 0,
  disabled = false,
  contract = false,
}: Props) => {
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onSearch && onSearch();
    }
  };

  return (
    <div
      className={classNames(
        classes.component,
        contract && classes.componentContract,
        className
      )}
    >
      <input
        type="text"
        autoComplete="off"
        name={name}
        value={contract ? shortenContract(value) : value}
        placeholder={placeholder}
        className={classNames(classes.input, contract && classes.inputContract)}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        onKeyDown={handleKeyDown}
        disabled={disabled}
      />
      {max > 0 && (
        <div
          className={classes.maxBtn}
          onClick={() => onChange(max.toString())}
        >
          Max
        </div>
      )}
      {error && <span className={classes.error}>{error}</span>}
      {(!error || errorAndInfo) && info && (
        <span
          className={classNames(classes.info, contract && classes.infoContract)}
        >
          {info}
        </span>
      )}
    </div>
  );
};
