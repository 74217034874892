import React from "react";
import classes from "./TxTip.module.scss";
import BitcoinTx from "../models/BitcoinTx";
import classNames from "classnames";
import {
  countStxAmount,
  shortenAddress,
  titleDbTxTypeId,
} from "../../../helpers.ts";
import {
  displayFullAmount,
  formatBalance,
} from "../../../types/balance-tools.ts";
import { STX_DECIMALS } from "../../../constants.ts";
import { wrapTxId } from "../../../utils/validation.ts";

export const TxTip: React.FC<{
  selectedBlock: any;
  size: number;
}> = ({ selectedBlock, size }) => {
  if (!selectedBlock) return <></>;
  const txType = selectedBlock.block.txns.find(
    (x) => x.id === selectedBlock.id
  )?.type_id;
  let baseOffset = 15 + 10 * selectedBlock.gridPosition.r;
  return (
    <div
      className={classNames(
        classes.wrapper,
        selectedBlock && classes.wrapperSelected
      )}
      style={{
        left: selectedBlock.screenPosition.x + baseOffset,
        top: size - selectedBlock.screenPosition.y,
      }}
    >
      <div className={classes.popoverRoot}>
        <div className={classes.dataRow}>
          <span>Tx ID:</span>
          <span>{wrapTxId(shortenAddress(selectedBlock.id))}</span>
        </div>
        <div className={classes.dataRow}>
          <span>Fee:</span>
          <span>{countStxAmount(selectedBlock.fee)} STX</span>
        </div>
        <div className={classes.dataRow}>
          <span>Total value:</span>
          <span>{countStxAmount(selectedBlock.value)} STX</span>
        </div>
        <div className={classes.dataRow}>
          <span>Tx Type:</span>
          <span>{titleDbTxTypeId(txType)}</span>
        </div>
      </div>
    </div>
  );
};
