import React from "react";
import classes from "./CancelOrderModalStyles.module.scss";
import { BigPurpuleButton } from "../../Buttons/BigPurpuleButton/BigPurpuleButton.tsx";
import { IPurchaseTransaction } from "../../../types/ApiResponseTypes.ts";
import { PendingData } from "../BuyStx20Modal/BuyStx20Modal.tsx";
import { OrderType } from "../../../types/orders.ts";

type Props = {
  onClose: () => void;
  onApprove: () => void;
  isError: boolean;
  purchaseTx: IPurchaseTransaction[];
  orderType: OrderType;
};

export const CancelOrderModal = ({
  onClose,
  onApprove,
  isError,
  purchaseTx,
  orderType,
}: Props) => {
  return (
    <div>
      <div className={classes.menuBg} onClick={onClose} />
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <div className={classes.head}>
            <span className={classes.title}>Cancel Order</span>
            <button className={classes.close} onClick={onClose}>
              <img src="/images/close.svg" width={24} height={24} alt="" />
            </button>
          </div>
          <div className={classes.dataNote}>
            <div className={classes.noteText}>
              {isError
                ? "Cancellation isn't available anymore as the purchase transaction for this order has already been confirmed."
                : "There are active purchase transactions for this order. Wish to proceed with cancellation despite this? Please consider that cancel transaction may affect the ongoing transactions."}
            </div>
          </div>
          <PendingData purchaseTx={purchaseTx} />
          <div className={classes.buttons}>
            <BigPurpuleButton
              onClick={onApprove}
              text="Cancel Order"
              className={classes.simpleButton}
              disabled={isError}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
