import React, { useCallback, useEffect, useMemo, useState } from "react";
import classes from "./CreateOrderModalStyles.module.scss";
import {
  CONFIRMATION_BLOCKS,
  Status,
  STX_DECIMALS,
} from "../../../constants.ts";
import { BigPurpuleButton } from "../../Buttons/BigPurpuleButton/BigPurpuleButton.tsx";
import { Input } from "../../InscribeForm/InscribeInput/Input.tsx";
import useData from "../../../hooks/useData.ts";
import {
  createOrder,
  finalizeOrderCreation,
} from "../../../Axios/AxiosInstance.ts";
import { SuccessModal } from "../SuccessModal/SuccessModal.tsx";
import { isEmpty } from "lodash";
import { AddressInput } from "../../AddressInput/AddressInput.tsx";
import {
  validateIntegerValue,
  validateNonIntegerValue,
} from "../../../helpers.ts";
import { SuccessOrderModal } from "../SuccessOrderModal/SuccessOrderModal.tsx";
import { useFees } from "../../../hooks/useFees.ts";
import { useFloorPrices } from "../../../hooks/useFloorPrices.ts";
import {
  castCreateOrderFee,
  formatBalance,
} from "../../../types/balance-tools.ts";
import { TickerDropdown } from "../../TickerDropdown/TickerDropdown.tsx";
import { Radio } from "../../Radio/Radio.tsx";
import { Switch } from "../../Switch/Switch.tsx";
import { useAddress } from "../../../store/address.ts";
import { OrderType, orderTypes } from "../../../types/orders.ts";

type Props = {
  onClose: () => void;
  onSuccess: () => void;
};

export const CreateOrderModal = ({ onClose, onSuccess }: Props) => {
  const [nameValue, setNameValue] = useState("");
  const [tickerError, setTickerError] = useState("");
  const [amount, setAmount] = useState("");
  const [amountError, setAmountError] = useState("");
  const [stxAmount, setStxAmount] = useState("");
  const [stxAmountError, setStxAmountError] = useState("");
  const [bnsContent, setBnsContent] = useState<string | null>("");
  const [receiver, setReceiver] = useState("");
  const [receiverError, setReceiverError] = useState("");
  const [useCurrentWallet, setUseCurrentWallet] = useState(false);
  const { stacksNetwork, makeStxTransfer } = useData();
  const [address] = useAddress();
  const [status, setStatus] = useState<Status | null>();
  const [txId, setTxId] = useState("");
  const [balance, setBalance] = useState("");
  const [listingPrice, setListingPrice] = useState("Per token");
  const [isLessFloorPrice, setLessFloorPrice] = useState(false);
  const { fees, stxPrice } = useFees();
  const { getFloorPrice } = useFloorPrices();
  const [ticketFloorPrice, setTicketFloorPrice] = useState(0);
  const [orderType, setOrderType] = useState(orderTypes[0]);

  // useEffect(() => console.log("bnsContent", bnsContent), [bnsContent]);
  const handleCreate = () => {
    const tokenAmount = BigInt(amount).toString();
    const realStxAmount =
      listingPrice === "Total amount" || orderType !== OrderType.TICKER
        ? BigInt(Number(stxAmount) * Math.pow(10, STX_DECIMALS)).toString()
        : BigInt(
            Number(stxAmount) * Number(amount) * Math.pow(10, STX_DECIMALS)
          ).toString();

    createOrder(
      nameValue,
      tokenAmount,
      bnsContent ? bnsContent : receiver,
      realStxAmount,
      orderType
    )
      .then(async (sellRequest) => {
        // console.log("sell-requests Create torder", sellRequest);
        const memoValue =
          orderType === OrderType.TICKER
            ? `t${nameValue}${tokenAmount}`
            : orderType === OrderType.STXS
            ? nameValue.toLowerCase()
            : nameValue.toLowerCase() + ".stxmap";
        await makeStxTransfer({
          data: {
            recipient: sellRequest.tokenReceiverMarketplaceAddress,
            amount: sellRequest.gasFeeValueSeller,
            memo: memoValue,
            network: stacksNetwork,
            stxAddress: address || "",
          },
          onFinish: (res) => {
            finalizeOrderCreation(sellRequest._id, res.txId, orderType).then();
            setTxId(res.txId);
          },
          onCancel: () => {
            setStatus(Status.FAIL);
          },
        });
      })
      .catch((err) => {
        console.log(err);
        setStatus(Status.FAIL);
      });
  };

  const calculatedFee = castCreateOrderFee(fees?.data?.order_gas_fee || 0);

  const amountChangeHandler = (val) => {
    setAmount(val);
    if (val === "0") {
      setAmountError("Amount must be more then 0");
      return;
    }
    validateIntegerValue(val, setAmountError, "Amount");
    if (balance && val) {
      if (Number(val) > Number(balance) && tickerError.length === 0) {
        setAmountError("Insufficient funds");
      }
    }
  };
  useEffect(() => {
    amountChangeHandler(amount);
  }, [balance]);

  useEffect(() => {
    if (nameValue && orderType === OrderType.TICKER)
      setTicketFloorPrice(getFloorPrice(nameValue));
  }, [nameValue, orderType]);

  const stxAmountChangeHandler = (val) => {
    setStxAmount(val);
    if (Number(val) === 0) {
      setStxAmountError("STX amount must be more then 0");
      return;
    }
    validateNonIntegerValue(val, setStxAmountError, "STX amount to receive");
  };

  const perToken = useMemo(() => {
    return listingPrice === "Per token"
      ? Number(stxAmount)
      : Number(stxAmount) / Number(amount);
  }, [stxAmount, amount, listingPrice]);

  const isShowFloorPriceAlert = useMemo(() => {
    if (stxAmount.length === 0 || stxAmountError !== "") return false;
    if (amount.length === 0 || amountError !== "") return false;
    if (ticketFloorPrice === 0) return false;
    if (perToken < ticketFloorPrice / Math.pow(10, 6) / 2) return true;
    return false;
  }, [
    amount,
    amountError,
    stxAmount,
    stxAmountError,
    ticketFloorPrice,
    perToken,
  ]);

  if (!!txId) {
    return (
      <>
        {!!status && (
          <SuccessModal status={status} onClose={() => setStatus(null)} />
        )}
        <SuccessOrderModal
          txId={txId}
          onClose={() => {
            onClose();
            onSuccess();
          }}
          title={"Transaction broadcasted successfully"}
          info={
            <>
              {" "}
              Please wait for{" "}
              <span>{CONFIRMATION_BLOCKS} confirmation blocks</span> until the
              transfer is verified by the marketplace. Then the sell order will
              appear in My Orders tab and marketplace feed.
            </>
          }
        />
      </>
    );
  } else {
    return (
      <>
        <div className={classes.menuBg} onClick={onClose} />
        <div className={classes.root}>
          <div className={classes.component}>
            <div className={classes.wrapper}>
              <div className={classes.head}>
                <span className={classes.title}>Create Order</span>
                <button className={classes.close} onClick={onClose}>
                  <img src="/images/close.svg" width={24} height={24} alt="" />
                </button>
              </div>
              <div className={classes.content}>
                <div className={classes.inputsWrapper}>
                  <div className={classes.warningWrapper}>
                    <span className={classes.warningContent}>
                      Transaction with higher Gas fee has faster processing and
                      confirmation times. We recommend setting no less than{" "}
                      <span>0.2 STX</span>
                    </span>
                  </div>

                  <div className={classes.item}>
                    <TickerDropdown
                      onChangeTicker={setNameValue}
                      setBalance={setBalance}
                      orderType={orderType}
                      changeOrderType={setOrderType}
                    />
                  </div>
                  {orderType === OrderType.TICKER && (
                    <div className={classes.item}>
                      <Input
                        name="amount"
                        placeholder="Amount"
                        value={amount}
                        onChange={amountChangeHandler}
                        error={amountError}
                        errorAndInfo
                        info={balance ? `Balance: ${balance}` : ""}
                      />
                    </div>
                  )}
                  {orderType === OrderType.TICKER && (
                    <div className={classes.item}>
                      <div className={classes.radioRow}>
                        <span className={classes.radioLabel}>
                          Listing price
                        </span>
                        <div className={classes.radioBoxes}>
                          <Radio
                            name="listingPrice"
                            value="Total amount"
                            label="Total amount"
                            checked={listingPrice === "Total amount"}
                            onChecked={() => setListingPrice("Total amount")}
                          />
                          <Radio
                            name="listingPrice"
                            value="Per token"
                            label="Per token"
                            checked={listingPrice === "Per token"}
                            onChecked={() => setListingPrice("Per token")}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div className={classes.item}>
                    <Input
                      name="stxAmount"
                      placeholder="STX amount to receive"
                      value={stxAmount}
                      onChange={stxAmountChangeHandler}
                      error={stxAmountError}
                    />
                  </div>
                  {isShowFloorPriceAlert && (
                    <div className={classes.item}>
                      <div className={classes.floorAlertWrap}>
                        <span className={classes.floorAlertLabel}>
                          Wish to list your tokens at the price less than floor
                          price?
                        </span>
                        <div className={classes.switchWrap}>
                          <Switch
                            checked={isLessFloorPrice}
                            onChecked={() =>
                              setLessFloorPrice((prevState) => !prevState)
                            }
                          />
                          <span>{isLessFloorPrice ? "Yes" : "No"}</span>
                        </div>
                      </div>
                    </div>
                  )}
                  <AddressInput
                    onReceiverChange={(account) => setReceiver(account)}
                    receiver={receiver}
                    bns={bnsContent}
                    onBnsChange={setBnsContent}
                    onReceiverErrorChange={(error: string) =>
                      setReceiverError(error)
                    }
                    receiverError={receiverError}
                    onUseCurrentWalletChange={(status) =>
                      setUseCurrentWallet(status)
                    }
                    useCurrentWallet={useCurrentWallet}
                  />
                  <div className={classes.networkFee}>
                    <div className={classes.networkFeeLabel}>Network fee</div>
                    <div className={classes.networkFeeLabelValue}>
                      {formatBalance(calculatedFee, 3)} STX
                    </div>
                  </div>
                </div>
                <BigPurpuleButton
                  onClick={handleCreate}
                  text={"Create"}
                  className={classes.button}
                  disabled={
                    amountError ||
                    stxAmountError ||
                    receiverError ||
                    tickerError ||
                    (orderType === OrderType.TICKER && isEmpty(amount)) ||
                    isEmpty(nameValue) ||
                    isEmpty(stxAmount) ||
                    isEmpty(receiver) ||
                    (isShowFloorPriceAlert && !isLessFloorPrice)
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};
