import React, { useEffect, useState } from "react";
import classes from "./ExploreForm.module.scss";
import { validateStacksAddress } from "micro-stacks/crypto";
import { SearchInput } from "../SearchInput/SearchInput.tsx";
import { TickerResult } from "./TickerResult/TickerResult.tsx";
import { AddressResult } from "./AddressResult/AddressResult.tsx";
import { Wrapper } from "../Wrapper/Wrapper.tsx";
import {
  getBalanceAddress,
  getBlockHash,
  getBlockHeight,
  getDomainDetail,
  getDomainsByAddress,
  getStxMapByAddress,
  getStxMapDetail,
  getTokenTicker,
} from "../../Axios/AxiosInstance.ts";
import {
  BalanceAddress,
  BaseDomainModel,
  BaseStxmapModel,
  ResponseWithPagination,
  TokenTicker,
} from "../../types/ApiResponseTypes.ts";
import { useAddress } from "../../store/address.ts";
import useData from "../../hooks/useData.ts";
import { DomainHoldings } from "./DomainHoldings/DomainHoldings.tsx";
import {
  isValidRecipient,
  resolveBnsName,
  resolveStxsName,
} from "../../utils/validation.ts";
import { TxRender } from "../TxRender/index.tsx";

type Props = {};

export async function getAllPaginatedResults<T>(
  action: (
    address: string,
    page: number,
    limit: number
  ) => Promise<ResponseWithPagination<T>>,
  page: number = 0,
  address: string
): Promise<T[]> {
  const limit = 200;
  const response = await action(address, page, limit);
  const data = response.data;

  const totalFetched = (page + 1) * limit;

  if (totalFetched < response.pagination.count) {
    return data.concat(await getAllPaginatedResults(action, page + 1, address));
  } else {
    return data;
  }
}

export const ExploreForm = ({}: Props) => {
  const [search, setSearch] = useState("");
  const [is404, setIs404] = useState(false);
  const [inputError, setInputError] = useState("");
  const [isSingleSearch, setSingleSearch] = useState(false);
  const [searchAddressResult, setSearchAddressResult] = useState<
    BalanceAddress | undefined
  >();
  const [searchTickerResult, setSearchTickerResult] = useState<
    TokenTicker | undefined
  >();
  const [searchDomainResult, setSearchDomainResult] = useState<
    BaseDomainModel[] | undefined
  >();
  const [searchStxMapResult, setSearchStxMapResult] = useState<
    BaseStxmapModel[] | undefined
  >();
  const [isLoading, setLoading] = useState(false);
  const [address] = useAddress();
  const { isAuthorized } = useData();
  const [hash, setHash] = useState<any>();

  useEffect(() => {
    if (!isAuthorized || !address) return;
    setSearch(address);
    handleSearch(address);
  }, [isAuthorized, address]);

  const fetchDomain = async (searchValue: string) => {
    setLoading(true);
    setSearchTickerResult(undefined);
    setSearchAddressResult(undefined);
    setSearchDomainResult(undefined);
    setSearchStxMapResult(undefined);
    try {
      const result = await getDomainDetail(searchValue);
      setSearchDomainResult([result.data]);
      setSingleSearch(true);
    } catch (error) {
      setIs404(true);
    } finally {
      setLoading(false);
    }
  };

  const fetchStxMap = async (searchValue: string) => {
    setLoading(true);
    setSearchTickerResult(undefined);
    setSearchAddressResult(undefined);
    setSearchDomainResult(undefined);
    setSearchStxMapResult(undefined);
    try {
      const result = await getStxMapDetail(searchValue.split(".")[0]);
      setSearchStxMapResult([result.data]);
      setSingleSearch(true);
    } catch (error) {
      setIs404(true);
    } finally {
      setLoading(false);
    }
  };

  const fetchAddress = async (searchValue: string) => {
    setLoading(true);
    setSearchTickerResult(undefined);
    setSearchAddressResult(undefined);
    setSearchDomainResult(undefined);
    setSearchStxMapResult(undefined);
    setSingleSearch(false);
    try {
      const domainsFn = getAllPaginatedResults<BaseDomainModel>(
        getDomainsByAddress,
        0,
        searchValue
      );
      const stxmapFn = getAllPaginatedResults<BaseStxmapModel>(
        getStxMapByAddress,
        0,
        searchValue
      );
      const [balanceResponse, domainsResponse, stxMapResponse] =
        await Promise.all([
          getBalanceAddress(searchValue),
          domainsFn,
          stxmapFn,
        ]);
      balanceResponse.balances.length > 0;
      if (balanceResponse.balances.length > 0) {
        setSearchAddressResult(balanceResponse);
      }
      setSearchDomainResult(domainsResponse);
      setSearchStxMapResult(stxMapResponse);
      if (
        !balanceResponse.balances.length &&
        !domainsResponse.length &&
        !stxMapResponse.length
      ) {
        setIs404(true);
      } else {
        setIs404(false);
      }
    } catch (error) {
      console.error(error);
      setIs404(true);
    } finally {
      setLoading(false);
    }
  };

  const fetchTicker = async () => {
    setLoading(true);
    setSearchAddressResult(undefined);
    setSearchTickerResult(undefined);
    setSearchDomainResult(undefined);
    setSearchStxMapResult(undefined);
    setSingleSearch(true);
    try {
      const tokenResponse = await getTokenTicker(search.toUpperCase());
      setSearchTickerResult(tokenResponse);
      console.log(tokenResponse);
    } catch (error) {
      console.error(error);
      setIs404(true);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (search: string) => {
    setSearch(search);
    setInputError("");
    setIs404(false);
  };

  const handleSearch = (initialSearch?: string) => {
    setInputError("");
    let searchValue = initialSearch || search;

    if (!searchValue.trim().length) {
      setSearchTickerResult(undefined);
      setSearchAddressResult(undefined);
      setSearchStxMapResult(undefined);
      setSearchDomainResult(undefined);
      return;
    }
    if (searchValue.endsWith(".stxmap")) {
      fetchStxMap(searchValue);
    } else if (searchValue.endsWith(".stxs")) {
      fetchDomain(searchValue);
    } else {
      setLoading(true);
      setSearchTickerResult(undefined);
      setSearchAddressResult(undefined);
      setSearchStxMapResult(undefined);
      setSearchDomainResult(undefined);
      if (searchValue.includes(".")) {
        resolveBnsName(searchValue).then((res) => {
          if (res) {
            searchValue = res;
            if (!isValidRecipient(res) && res.length > 0) {
              setLoading(false);
              setInputError("No results");
              return;
            }
            fetchAddress(res);
          } else {
            setLoading(false);
            setInputError("No results");
            return;
          }
        });
      } else {
        if (searchValue.length > 8) {
          if (!validateStacksAddress(searchValue) && searchValue.length > 0) {
            setInputError("No results");
            setLoading(false);
            return;
          }
          fetchAddress(searchValue);
        } else {
          if (
            !/^[A-Z]+$/.test(searchValue.toUpperCase()) ||
            searchValue.length < 3 ||
            searchValue.length > 8
          ) {
            setInputError("No results");
            setLoading(false);
            return;
          }

          fetchTicker();
        }
      }
    }
  };

  return (
    <div className={classes.component}>
      <Wrapper className={classes.wrapper}>
        <div className={classes.tabsWrapper}>
          <SearchInput
            name="search"
            placeholder="Address, BNS name, Ticker, Stxmap"
            value={search}
            onChange={handleChange}
            onSearch={handleSearch}
          />
        </div>
        <div className={classes.tabContentWrapper}>
          {isLoading && (
            <div className={classes.loadingWrapper}>
              <img
                src="/images/loading-gif.gif"
                alt="Loading..."
                className={classes.loading}
              />
            </div>
          )}
          {!isLoading && is404 && (
            <div className={classes.empty}>No results</div>
          )}
          {!isLoading && inputError.length > 0 && (
            <div className={classes.empty}>{inputError}</div>
          )}

          {searchAddressResult && (
            <AddressResult search={searchAddressResult} />
          )}
          {searchTickerResult && <TickerResult search={searchTickerResult} />}
          {searchDomainResult && !!searchDomainResult.length && (
            <DomainHoldings
              isSingle={isSingleSearch}
              isDomain
              data={searchDomainResult.map((x) => ({ ...x, key: x.domain }))}
              title="Domains"
            />
          )}
          {searchStxMapResult && !!searchStxMapResult.length && (
            <>
              <DomainHoldings
                isSingle={isSingleSearch}
                data={searchStxMapResult.map((x) => ({
                  ...x,
                  key: x.stxmap + ".stxmap",
                }))}
                title="Stxmap blocks"
              />
            </>
          )}
        </div>
      </Wrapper>
    </div>
  );
};
