import React from "react";
import classes from "./OrderStatusIcon.module.scss";
import classNames from "classnames";
import { SellRequestStatus } from "../../types/ApiResponseTypes.ts";

type Props = {
  status?: SellRequestStatus | "Buy" | "Cancel";
};
export const OrderStatusIcon = ({ status }: Props) => {
  return (
    <div
      className={classNames(
        classes.component,
        status === SellRequestStatus.BUYING_IN_PROCESS &&
          classes.buyingInProgress,
        status === SellRequestStatus.BUYING_COMPLETED && classes.completed,
        status === SellRequestStatus.CANCEL_COMPLETED && classes.cancelled,
        status === SellRequestStatus.CANCEL_IN_PROCESS &&
          classes.cancellingProgress,
        status === SellRequestStatus.CANCEL_COMPLETED && classes.completed,
        status === SellRequestStatus.CREATED && classes.created
      )}
    ></div>
  );
};
