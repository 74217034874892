import React from "react";
import classes from "./HeaderCard.module.scss";
import {Wrapper} from "../Wrapper/Wrapper.tsx";

type Props = {
    title: string;
    caption: string;
    image: string;
    afterText?: string;
};
export const HeaderCard = ({title, caption, image, afterText}: Props) => {
    return (
        <div className={classes.component}>
            <Wrapper className={classes.wrapper}>
                <img src={image} alt="" className={classes.image}/>
                <div className={classes.descriptionWrapper}>
                    <h1 className={classes.title}>{title}</h1>
                    <span className={classes.caption}>{caption}</span>
                    {afterText && <span className={classes.caption}>{afterText}</span>}
                </div>
            </Wrapper>
        </div>
    );
};
