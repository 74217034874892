import { SignatureData, useConnect } from "@stacks/connect-react";
import useData from "./useData.ts";
import { useContext } from "react";
import {
  IBaseSellRequest,
  IResultSellRequest,
} from "../types/ApiResponseTypes.ts";
import { CANCEL_MESSAGE, PROVIDERS } from "../constants.ts";
import { cancelOrder, login } from "../Axios/AxiosInstance.ts";
import { useOkxWallet } from "./useOkxWallet.ts";
import { getProvider } from "../utils/getProvider.ts";
import { useAddress } from "../store/address.ts";
import { OrderType } from "../types/orders.ts";

export const UseBlockchainCalls = () => {
  const { sign } = useConnect();
  const { stacksNetwork } = useData();
  const [address] = useAddress();
  const { signMessageOkxWallet } = useOkxWallet();

  const signMessage = async (
    message: string,
    onFinish?: (res: SignatureData) => void,
    onError?: () => void
  ) => {
    const selectedProvider = getProvider();
    if (!selectedProvider) return;
    if (selectedProvider === getProvider(PROVIDERS.Okx)) {
      try {
        const response = await signMessageOkxWallet({ message });
        onFinish && onFinish(response);
      } catch (e) {
        onError && onError();
      }
      return;
    }
    sign(
      {
        message: message,
        network: stacksNetwork,
        onFinish: (res) => {
          onFinish && onFinish(res);
        },
        onCancel: () => {
          onError && onError();
        },
      },
      selectedProvider
    );
  };

  const cancelOrderWithSignature = (
    order: IResultSellRequest,
    orderType: OrderType,
    onFinish: (txId: string) => void,
    onError: () => void
  ) => {
    const message = `${CANCEL_MESSAGE}${order._id}`;
    signMessage(
      message,
      async (res) => {
        const response = await cancelOrder(order._id, res.signature, orderType);
        // @ts-ignore
        onFinish(response.data.data.txid);
      },
      onError
    );
  };

  return { signMessage, cancelOrderWithSignature };
};
