export type UserData = {
    testnet: string;
    mainnet: string;
} | null;

export type BASE_NETWORK = 'mainnet' | 'testnet';

export enum DbTxTypeId {
    TokenTransfer = 0x00,
    SmartContract = 0x01,
    ContractCall = 0x02,
    PoisonMicroblock = 0x03,
    Coinbase = 0x04,
    CoinbaseToAltRecipient = 0x05,
    VersionedSmartContract = 0x06,
    TenureChange = 0x07,
    NakamotoCoinbase = 0x08,
  }
