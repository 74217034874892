import React from "react";
import classes from "./OrderCard.module.scss";
import { Skeleton } from "../Skeleton/Skeleton.tsx";
import classNames from "classnames";

type Props = {
  isBig?: boolean;
};
export const SkeletonStxmap = ({ isBig = false }: Props) => (
  <div
    className={classNames(
      classes.stxmapSkeleton,
      isBig && classes.stxmapSkeletonBig
    )}
  >
    <Skeleton height="100%" width="100%" />
    <div className={classes.stxmapSkeletonBg}>
      <div className={classes.stxmapSkeletonRow} />
      <div className={classes.stxmapSkeletonRow} />
      <div className={classes.stxmapSkeletonRow} />
      <div className={classes.stxmapSkeletonRow} />
      <div className={classes.stxmapSkeletonRow} />
      <div className={classes.stxmapSkeletonRow} />
      <div className={classes.stxmapSkeletonRow} />
      <div className={classes.stxmapSkeletonRow} />
    </div>
    <div
      className={classNames(
        classes.stxmapSkeletonBg,
        classes.stxmapSkeletonRows
      )}
    >
      <div className={classes.stxmapSkeletonRow} />
      <div className={classes.stxmapSkeletonRow} />
      <div className={classes.stxmapSkeletonRow} />
      <div className={classes.stxmapSkeletonRow} />
      <div className={classes.stxmapSkeletonRow} />
      <div className={classes.stxmapSkeletonRow} />
      <div className={classes.stxmapSkeletonRow} />
      <div className={classes.stxmapSkeletonRow} />
    </div>
    <div className={classes.processed}>
      <img src="/images/loader.svg" alt="" />
      Processing
    </div>
  </div>
);
