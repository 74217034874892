import React, { useEffect, useState } from "react";
import {
  getBalanceAddress,
  getDomainsByAddress,
  getStxMapByAddress,
} from "../../Axios/AxiosInstance.ts";
import {
  BalanceAddress,
  BaseDomainModel,
  BaseStxmapModel,
} from "../../types/ApiResponseTypes.ts";
import useData from "../../hooks/useData.ts";
import { uniqueId } from "lodash";
import classes from "./TickerDropdownStyles.module.scss";
import classNames from "classnames";
import { displayFullAmount } from "../../types/balance-tools.ts";
import { useFees } from "../../hooks/useFees.ts";
import { useFloorPrices } from "../../hooks/useFloorPrices.ts";
import { STX_DECIMALS } from "../../constants.ts";
import { useAddress } from "../../store/address.ts";
import { getAllPaginatedResults } from "../ExploreForm/ExploreForm.tsx";
import { OrderType, orderTypes } from "../../types/orders.ts";

type Props = {
  onChangeTicker: (ticker: string) => void;
  className?: string;
  setBalance?: (balance: string) => void;
  orderType: OrderType;
  changeOrderType: (orderType: OrderType) => void;
};
export const TickerDropdown = ({
  onChangeTicker,
  setBalance,
  className,
  orderType,
  changeOrderType,
}: Props) => {
  const [selectedTicker, setSelectedTicker] = useState<string>("Select ticker");
  const [selectedStxmap, setSelectedStxmap] =
    useState<string>("Select .stxmap");
  const [selectedStxs, setSelectedStxs] = useState<string>("Select .stxs");
  const [isLoading, setLoading] = useState(false);
  const [searchAddressResult, setSearchAddressResult] = useState<
    BalanceAddress | undefined
  >();
  const [searchDomainResult, setSearchDomainResult] = useState<
    BaseDomainModel[] | undefined
  >();
  const [searchStxMapResult, setSearchStxMapResult] = useState<
    BaseStxmapModel[] | undefined
  >();

  const [address] = useAddress();
  const { stxPrice } = useFees();
  const { loadFloorPrice, getFloorPrice } = useFloorPrices();
  const [isEmpty, setEmpty] = useState(false);

  const fetchAddres = async () => {
    setLoading(true);
    setSearchAddressResult(undefined);
    try {
      const balanceResponse = await getBalanceAddress(address || "");
      balanceResponse.balances.length > 0
        ? setSearchAddressResult(balanceResponse)
        : setSelectedTicker("No tickers available");
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAddress = async () => {
    setLoading(true);
    setSearchAddressResult(undefined);
    setSearchDomainResult(undefined);
    setSearchStxMapResult(undefined);
    try {
      const domainsFn = getAllPaginatedResults<BaseDomainModel>(
        getDomainsByAddress,
        0,
        address || ""
      );
      const stxmapFn = getAllPaginatedResults<BaseStxmapModel>(
        getStxMapByAddress,
        0,
        address || ""
      );
      const [balanceResponse, domainsResponse, stxMapResponse] =
        await Promise.all([
          getBalanceAddress(address || ""),
          domainsFn,
          stxmapFn,
        ]);
      balanceResponse.balances.length > 0;
      if (balanceResponse.balances.length > 0) {
        setSearchAddressResult(balanceResponse);
      }
      setSearchDomainResult(domainsResponse);
      setSearchStxMapResult(stxMapResponse);
      if (
        !balanceResponse.balances.length &&
        !domainsResponse.length &&
        !stxMapResponse.length
      ) {
        setEmpty(true);
      } else {
        setEmpty(false);
      }
    } catch (error) {
      console.error(error);
      setEmpty(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAddress();
  }, [address]);

  useEffect(() => {
    if (searchAddressResult?.balances.length === 0) return;
    searchAddressResult?.balances.map((balance) => {
      loadFloorPrice(balance.ticker);
    });
  }, [searchAddressResult?.balances]);

  const [showMenuSelectors, setShowMenuSelectors] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [floorPrice, setFloorPrice] = useState(0);

  useEffect(() => {
    if (selectedTicker === "Select ticker") return;

    const ticketFloorPrice = getFloorPrice(selectedTicker);
    setFloorPrice(Number(ticketFloorPrice) / Math.pow(10, STX_DECIMALS));
  }, [selectedTicker]);

  return (
    <div className={classes.component}>
      <div className={classes.rowDropdowns}>
        <div
          className={classNames(className || "", classes.selectedWrapper)}
          onClick={(e) => {
            e.stopPropagation();
            setShowMenuSelectors(!showMenuSelectors);
          }}
        >
          <div className={classes.caption}>
            <span className={classes.title}>{orderType}</span>
          </div>
          <img
            src="/images/icon-dropdown.svg"
            alt=""
            className={classNames(
              classes.arrow,
              showMenuSelectors && classes.arrowOpened
            )}
          />
        </div>
        <div
          className={classNames(className || "", classes.selectedWrapper)}
          onClick={(e) => {
            e.stopPropagation();
            !isEmpty && setShowMenu(!showMenu);
          }}
        >
          <div className={classes.caption}>
            <span
              className={classNames(
                classes.title,
                orderType === OrderType.TICKER &&
                  selectedTicker === "Select ticker" &&
                  classes.placeholder,
                orderType === OrderType.STXS &&
                  selectedStxs === "Select .stxs" &&
                  classes.placeholder,
                orderType === OrderType.STXMAP &&
                  selectedStxmap === "Select .stxmap" &&
                  classes.placeholder
              )}
            >
              {orderType === OrderType.TICKER && selectedTicker}
              {orderType === OrderType.STXS && selectedStxs}
              {orderType === OrderType.STXMAP && selectedStxmap}
            </span>
          </div>
          {!isEmpty && (
            <img
              src="/images/icon-dropdown.svg"
              alt=""
              className={classNames(
                classes.arrow,
                showMenu && classes.arrowOpened
              )}
            />
          )}
        </div>
      </div>
      {showMenuSelectors && (
        <>
          <div
            className={classes.menuBg}
            onClick={() => setShowMenuSelectors(false)}
          />
          <div className={classes.menuItems}>
            {orderTypes.map((orderType) => (
              <div
                key={uniqueId(orderType)}
                onClick={() => {
                  changeOrderType(orderType);
                  setShowMenuSelectors(false);
                }}
                className={classes.menuItem}
              >
                <div className={classes.caption}>
                  <span className={classes.title}>{orderType}</span>
                </div>
              </div>
            ))}
          </div>
        </>
      )}

      {showMenu && (
        <>
          <div className={classes.menuBg} onClick={() => setShowMenu(false)} />
          <div className={classes.menuItems}>
            {orderType === OrderType.TICKER &&
              searchAddressResult?.balances.map((balance) => (
                <div
                  key={uniqueId(balance.ticker)}
                  onClick={() => {
                    setSelectedTicker(balance.ticker);
                    onChangeTicker(balance.ticker);
                    if (setBalance) setBalance(balance.balance);
                    setShowMenu(false);
                  }}
                  className={classes.menuItem}
                >
                  <div className={classes.caption}>
                    <span className={classes.title}>{balance.ticker}</span>
                  </div>
                  <span className={classes.title}>{balance.balance}</span>
                </div>
              ))}
            {orderType === OrderType.STXS &&
              searchDomainResult?.map((domain) => (
                <div
                  key={uniqueId(domain.domain)}
                  onClick={() => {
                    setSelectedStxs(domain.domain);
                    onChangeTicker(domain.domain);
                    setShowMenu(false);
                  }}
                  className={classes.menuItem}
                >
                  <div className={classes.caption}>
                    <span className={classes.title}>{domain.domain}</span>
                  </div>
                </div>
              ))}
            {orderType === OrderType.STXMAP &&
              searchStxMapResult?.map((map) => (
                <div
                  key={uniqueId(map.stxmap)}
                  onClick={() => {
                    setSelectedStxmap(map.stxmap);
                    onChangeTicker(map.stxmap);
                    setShowMenu(false);
                  }}
                  className={classes.menuItem}
                >
                  <div className={classes.caption}>
                    <span className={classes.title}>{map.stxmap}</span>
                  </div>
                </div>
              ))}
            {((orderType === OrderType.STXMAP &&
              searchStxMapResult?.length === 0) ||
              (orderType === OrderType.STXS &&
                searchDomainResult?.length === 0) ||
              (orderType === OrderType.TICKER &&
                !searchAddressResult?.balances)) && (
              <div key={uniqueId("empty")} className={classes.menuItemEmpty}>
                <div className={classes.caption}>
                  <span className={classes.titleEmpty}>Empty</span>
                </div>
              </div>
            )}
          </div>
        </>
      )}
      {orderType === OrderType.TICKER && selectedTicker !== "Select ticker" && (
        <div className={classes.floorPrice}>
          <div className={classes.floorPriceLabel}>
            Floor price:{" "}
            <span>{displayFullAmount(floorPrice, STX_DECIMALS)} STX</span>
            {" · "}
            <span>${displayFullAmount(floorPrice * Number(stxPrice), 2)}</span>
          </div>
        </div>
      )}
    </div>
  );
};
