
import {useAtom} from "jotai";
import {feeAtom} from "../store/fees.ts";
import useData from "./useData.ts";
import {useEffect, useState} from "react";
import {getOrdersFee, getStxPrice} from "../Axios/AxiosInstance.ts";
import {stxPriceAtom} from "../store/stxPrice.ts";

export const useFees = () => {
    const [fees, setFees] = useAtom(feeAtom);
    const [stxPrice, setStxPrice] = useAtom(stxPriceAtom);

    useEffect(() => {
        if (!fees) {
            getOrdersFee().then(fees => {
                setFees(fees);
            })
        }
    }, [fees]);

    return {fees, stxPrice};
};
