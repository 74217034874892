import React from 'react';
import classes from './SkeletonStyles.module.scss';

type Props = {
  width?: string;
  height?: string;
};
export const Skeleton = ({ width, height }: Props) => {
  return <div className={classes.component} style={{ width, height }} />;
};
