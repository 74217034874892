import React from "react";
import classes from "./CheckBox.module.scss";
import classNames from "classnames";

type Props = {
  label: string;
  checked: boolean;
  onChecked: () => void;
  className?: string;
};

export const CheckBox = ({ label, checked, onChecked, className }: Props) => {
  return (
    <div
      className={classNames(
        classes.component,
        checked && classes.checkedWrapper
      )}
    >
      <div className={classes.checkbox}>
        <input
          className={classes.customCheckbox}
          type="checkbox"
          name="checkBox"
          value="1"
          checked={checked}
          onChange={onChecked}
        />
        <label
          onClick={onChecked}
          className={classNames(classes.label, className || "")}
        >
          {label}
        </label>
      </div>
    </div>
  );
};
