import React, { useCallback, useEffect, useState } from "react";
import { SuccessModal } from "../../Modals/SuccessModal/SuccessModal.tsx";
import { Status } from "../../../constants.ts";
import classes from "./StxsmapForm.module.scss";
import { Input } from "../InscribeInput/Input.tsx";
import { BigPurpuleButton } from "../../Buttons/BigPurpuleButton/BigPurpuleButton.tsx";
import { validateStacksAddress } from "micro-stacks/crypto";
import { debounce } from "lodash";
import isValidDomain from "is-valid-domain";
import { getStxMapDetail } from "../../../Axios/AxiosInstance.ts";
import useData from "../../../hooks/useData.ts";
import { useAddress } from "../../../store/address.ts";
import {
  resolveBnsName,
  resolveStxsName,
  validateStxmap,
} from "../../../utils/validation.ts";
import { shortenAddress } from "../../../helpers.ts";

type Props = {};

export const StxsmapForm = ({}: Props) => {
  const [status, setStatus] = useState<Status>();
  const [blockNumberValue, setBlockNumberValue] = useState("");
  const [destinationValue, setDestinationValue] = useState("");
  const [blockNumberError, setBlockNumberError] = useState("");
  const [destinationError, setDestinationError] = useState("");
  const [blockNumberLoading, setBlockNumberLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const { stacksNetwork, makeStxTransfer } = useData();
  const [address] = useAddress();
  const [bnsContent, setBnsContent] = useState<string | null>("");

  const fetchDomain = async (val: string) => {
    if (validateStxmap(val)) {
      return;
    }
    setBlockNumberError("");
    setBlockNumberLoading(true);
    try {
      await getStxMapDetail(val);
      setBlockNumberError("Block number already exists");
    } catch (error) {
      setBlockNumberError("");
      console.error(error);
    } finally {
      setBlockNumberLoading(false);
    }
  };

  const debounceFn = useCallback(debounce(fetchDomain, 300), []);

  useEffect(() => {
    setBlockNumberError(validateStxmap(blockNumberValue));
  }, [blockNumberValue]);

  const handleDestinationChange = (val) => {
    setDestinationValue(val);
    setDestinationError("");
    if (!validateStacksAddress(val) && val.length > 0) {
      setDestinationError("Invalid STX address");
    }
    if (val.endsWith(".stxs")) {
      resolveStxsName(val).then((res) => {
        if (res) {
          setDestinationError("");
          setBnsContent(res);
        } else {
          setBnsContent(null);
          setDestinationError("Invalid STXS name");
        }
      });
    } else if (val.includes(".")) {
      resolveBnsName(val).then((res) => {
        if (res) {
          setDestinationError("");
          setBnsContent(res);
        } else {
          setDestinationError("Invalid BNS name");
        }
      });
    } else setBnsContent(null);
  };

  const handleSubmit = async () => {
    if (!!blockNumberError || !!destinationError) {
      return;
    }
    try {
      await makeStxTransfer({
        data: {
          recipient: bnsContent ? bnsContent : destinationValue,
          amount: "1",
          memo: blockNumberValue + ".stxmap",
          network: stacksNetwork,
          stxAddress: address || "",
        },
        onFinish: () => {
          setStatus(Status.SUCCESS);
          setBlockNumberValue("");
          setDestinationValue("");
          setLoading(false);
        },
        onCancel: () => {
          setStatus(Status.FAIL);
          setLoading(false);
        },
      });
    } catch (error) {
      setStatus(Status.FAIL);
      setLoading(false);
      console.error(error);
    }
  };

  return (
    <div className={classes.component}>
      {!!status && (
        <SuccessModal status={status} onClose={() => setStatus(undefined)} />
      )}
      <div className={classes.wrapper}>
        <h2 className={classes.title}>Claim your .stxmap block here!</h2>
        <span className={classes.subtitle}>
          Only blocks that have been minted are eligible for claiming. The
          cutoff for valid blocks for the Stxmap project is set at the Nakamoto
          upgrade block.
        </span>
        <div className={classes.inputsWrapper}>
          <div className={classes.domainWrapper}>
            <Input
              className={classes.input}
              name={"blockNumber"}
              placeholder={"Block number"}
              value={blockNumberValue}
              onChange={(value) => {
                setBlockNumberLoading(true);
                setBlockNumberValue(value.trim());
                debounceFn(value.trim());
              }}
              error={blockNumberError}
            />
          </div>
          <Input
            name={"destination"}
            placeholder={"Enter Address or BNS name"}
            value={destinationValue}
            onChange={handleDestinationChange}
            error={destinationError}
            info={shortenAddress(bnsContent || "")}
          />
        </div>
        <BigPurpuleButton
          disabled={
            !blockNumberValue ||
            !destinationValue ||
            !!blockNumberError ||
            !!destinationError
          }
          className={classes.button}
          text={"Claim"}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};
