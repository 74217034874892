import { StacksNetwork } from "@stacks/network";

const HIRO_API_BASE_URL_MAINNET = "https://api.hiro.so";
const HIRO_API_BASE_URL_TESTNET = "https://api.testnet.hiro.so";

export interface AddressBalance {
  stx: {
    balance: string;
    locked: string;
  };
  fungible_tokens: Record<
    string,
    { balance: string; total_received: string; total_sent: string }
  >;
  non_fungible_tokens: Record<string, { count: string }>;
}

const getHiroAddress = (network: StacksNetwork) =>
  network.chainId === StacksNetwork.fromName("mainnet").chainId
    ? HIRO_API_BASE_URL_MAINNET
    : HIRO_API_BASE_URL_TESTNET;

export const resolveContractName = async (
  name: string,
  network: StacksNetwork
): Promise<boolean> => {
  try {
    const response = await fetch(
      `${getHiroAddress(network)}/extended/v1/search/${name}`
    );
    if (!response.ok) {
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
  return true;
};

export const getAccountBalances = (
  network: StacksNetwork,
  account: string
): Promise<AddressBalance> => {
  return fetch(
    `${getHiroAddress(network)}/extended/v1/address/${account}/balances`
  ).then((r) => r.json());
};
