import React, { useState } from "react";
import classes from "./TickerResult.module.scss";
import { uniqueId } from "lodash";
import classNames from "classnames";
import { Wrapper } from "../../Wrapper/Wrapper.tsx";
import { TokenTicker } from "../../../types/ApiResponseTypes.ts";

type Props = {
  search: TokenTicker;
};
export const TickerResult = ({ search }: Props) => {
  const mintedDate = new Date(search.creationDate);
  return search ? (
    <div className={classes.component}>
      <Wrapper className={classes.wrapper}>
        <div className={classes.nameWrapper}>
          <img src="/images/icon-ticker.svg" alt="" />
          <div className={classes.title}>
            <h4 className={classes.name}>{search.ticker}</h4>
            <span className={classes.description}>
              Minted at {mintedDate.toLocaleDateString("en-US")}
            </span>
          </div>
        </div>
        <div className={classes.contentWrapper}>
          <div className={classes.itemWrapper}>
            <h4 className={classes.value}>{search.totalSupply}</h4>
            <span className={classes.specification}>Total supply</span>
          </div>
          <div className={classes.itemWrapper}>
            <h4 className={classes.value}>
              {Number(search.totalSupply) - Number(search.supplyLeftToMint)}
            </h4>
            <span className={classes.specification}>Supply minted</span>
          </div>
          <div className={classes.itemWrapper}>
            <h4 className={classes.value}>{search.mintLimit}</h4>
            <span className={classes.specification}>limit per mint</span>
          </div>
          {/* <div className={classes.itemWrapper}>
            <h4 className={classes.value}>-</h4>
            <span className={classes.specification}>Total holders</span>
          </div> */}
        </div>
      </Wrapper>
    </div>
  ) : (
    <></>
  );
};
