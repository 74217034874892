import React from "react";
import classes from "./AddressInputStyles.module.scss";
import { Input } from "../InscribeForm/InscribeInput/Input.tsx";
import { validateStacksAddress } from "micro-stacks/crypto";
import { CheckBox } from "../CheckBox/CheckBox.tsx";
import { useAddress } from "../../store/address.ts";
import { resolveBnsName, resolveStxsName } from "../../utils/validation.ts";
import { shortenAddress } from "../../helpers.ts";

type Props = {
  onReceiverChange: (address: string) => void;
  receiver: string;
  bns: string | null;
  onBnsChange: (address: string | null) => void;
  onReceiverErrorChange: (error: string) => void;
  receiverError: string;
  onUseCurrentWalletChange: (status: boolean) => void;
  useCurrentWallet: boolean;
};
export const AddressInput = ({
  receiverError,
  onReceiverErrorChange,
  receiver,
  bns,
  onBnsChange,
  onReceiverChange,
  onUseCurrentWalletChange,
  useCurrentWallet,
}: Props) => {
  const [address] = useAddress();
  // const [bnsContent, setBnsContent] = React.useState<string | null>("");

  const handleChecked = () => {
    // setBnsContent(null);
    onBnsChange(null);
    if (!useCurrentWallet) {
      onReceiverChange(address || "");
      onReceiverErrorChange("");
    } else {
      onReceiverChange("");
    }
    onUseCurrentWalletChange(!useCurrentWallet);
  };

  return (
    <div className={classes.component}>
      <div className={classes.inputWrapper}>
        <Input
          name="receiver"
          placeholder="Enter payment receiver Address or BNS name"
          className={classes.input}
          value={receiver}
          onChange={(val) => {
            onReceiverChange(val);
            onReceiverErrorChange("");
            if (
              !validateStacksAddress(val) &&
              val.length > 0 &&
              !val.includes(".")
            ) {
              onReceiverErrorChange("Invalid STX address");
              onUseCurrentWalletChange(false);
            }
            if (val.endsWith(".stxs")) {
              resolveStxsName(val).then((res) => {
                if (res) {
                  onReceiverErrorChange("");
                  onBnsChange(res);
                } else {
                  onBnsChange(null);
                  onReceiverErrorChange("Invalid STXS name");
                }
              });
            } else if (val.includes(".")) {
              onUseCurrentWalletChange(false);
              resolveBnsName(val).then((res) => {
                if (res) {
                  onReceiverErrorChange("");
                  onBnsChange(res);
                } else {
                  onBnsChange(null);
                  onReceiverErrorChange("Invalid BNS name");
                }
              });
            } else onBnsChange(null);
          }}
        />
        {receiverError && <div className={classes.error}>{receiverError}</div>}
        {bns && <div className={classes.info}>{shortenAddress(bns || "")}</div>}
      </div>
      {/* {receiver && (
        <button
          onClick={() => {
            onReceiverChange("");
            onReceiverErrorChange("");
            onUseCurrentWalletChange(false);
          }}
          className={classes.clearAddress}
        >
          <img width={20} height={20} src="/images/icon-remove.svg" />
        </button>
      )} */}
      <CheckBox
        label="Use connected wallet"
        checked={!!useCurrentWallet}
        onChecked={handleChecked}
      />
    </div>
  );
};
