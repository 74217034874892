import React, { useMemo, useState } from "react";
import classes from "./WrapAsSip9Form.module.scss";
import { BigPurpuleButton } from "../../Buttons/BigPurpuleButton/BigPurpuleButton.tsx";
import useData from "../../../hooks/useData.ts";
import {
  Status,
  getWrapperContractAddress,
  getWrapperContractName,
  getWrapperTicker,
} from "../../../constants.ts";
import { SuccessModal } from "../../Modals/SuccessModal/SuccessModal.tsx";
import { validateIntegerValue } from "../../../helpers.ts";
import { useAddress } from "../../../store/address.ts";
import { Input } from "../../InscribeForm/InscribeInput/Input.tsx";
import { TickerInputWithBalance } from "../../TickerInput/TickerInputWithBalance.tsx";

type Props = {};
export const WrapAsSip9Form = ({}: Props) => {
  const { stacksNetwork, makeStxTransfer } = useData();
  const [address] = useAddress();
  const [ticker, setTicker] = useState(getWrapperTicker());
  const [tickerError, setTickerError] = useState("");
  const [destination] = useState(
    `${getWrapperContractAddress()}.${getWrapperContractName()}`
  );
  const [total, setTotal] = useState("");
  const [totalError, setTotalError] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [totalCharacterSizeError, setTotalCharacterSizeError] = useState("");
  const [balance, setBalance] = useState("");
  const [status, setStatus] = useState<Status | null>();

  const handleTransfer = async () => {
    let transferMemo = "";
    transferMemo = `t${ticker}${total}`;
    if (transferMemo.length > 33) {
      setTotalCharacterSizeError(
        "Total character limit exceeded for a single inscription. Try reducing the amount of decimals."
      );
      return;
    } else {
      setTotalCharacterSizeError("");
    }
    setLoading(true);

    try {
      await makeStxTransfer({
        data: {
          recipient: destination,
          amount: "1",
          memo: transferMemo,
          network: stacksNetwork,
          stxAddress: address || "",
        },
        onFinish: () => {
          setStatus(Status.SUCCESS);
          setTicker("");
          setTotal("");
          setTickerError("");
          setLoading(false);
        },
        onCancel: () => {
          setStatus(Status.FAIL);
          setLoading(false);
        },
      });
    } catch (error) {
      setStatus(Status.FAIL);
      setLoading(false);
      console.error(error);
    }
  };

  const handleTotalChange = (val) => {
    setTotal(val);
    validateIntegerValue(val, setTotalError, "", "Invalid total");
    if (
      Number(val) > Number(balance) &&
      tickerError.length === 0 &&
      ticker.length > 0
    ) {
      setTotalError("Insufficient funds");
    }
  };

  const isDisabled = useMemo(() => {
    return (
      total === "" || totalError !== "" || ticker === "" || tickerError !== ""
    );
  }, [total, totalError, ticker, tickerError]);

  return (
    <div className={classes.wrapper}>
      {!!status && (
        <SuccessModal status={status} onClose={() => setStatus(null)} />
      )}
      <div className={classes.inputWrapper}>
        <div className={classes.item}>
          <TickerInputWithBalance
            setBalance={setBalance}
            onTickerErrorHandler={setTickerError}
            ticker={ticker}
            onChangeTicker={setTicker}
            placeholder="STXS"
            tickerError={tickerError}
            editable={false}
          />
        </div>
        <div className={classes.item}>
          <Input
            name="total"
            placeholder="Amount"
            value={total}
            onChange={handleTotalChange}
            error={totalError}
            max={Number(balance)}
          />
        </div>
      </div>
      <div className={classes.buttonWrapper}>
        {totalCharacterSizeError && (
          <span className={classes.error}>{totalCharacterSizeError}</span>
        )}
        <BigPurpuleButton
          onClick={handleTransfer}
          text={"Wrap"}
          disabled={isDisabled}
          className={classes.button}
        />
      </div>
    </div>
  );
};
