import React, { useEffect, useState } from "react";
import classes from "./DomainHoldings.module.scss";
import { Wrapper } from "../../Wrapper/Wrapper.tsx";
import { shortenAddress } from "../../../helpers.ts";
import { CopyButton } from "../../CopyButton/CopyButton.tsx";
import { TxRender } from "../../TxRender/index.tsx";
import { getBlockHeight } from "../../../Axios/AxiosInstance.ts";
import classNames from "classnames";
import { countStxAmount } from "../../../helpers.ts";
import { displayFullAmount } from "../../../types/balance-tools.ts";
import { SkeletonStxmap } from "../../OrderCard/SkeletonStxmap.tsx";
import { DbTxTypeId } from "../../../types.ts";
import BitcoinBlock from '../../TxRender/models/BitcoinBlock';

type Props = {
  data: {
    minter: string;
    owner: string;
    creationDate: Date;
    key: string;
  }[];
  title: string;
  isSingle: boolean;
  isDomain?: boolean;
};
export const DomainHoldings = ({
  data,
  title,
  isSingle,
  isDomain = false,
}: Props) => {
  const [blockData, setBlockData] = useState<any>();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (data[0] && isSingle) {
      getBlockHeight(data[0].key.split(".")[0]).then(async (x) => {
        setBlockData(x);
        setLoading(false);
      });
    }
  }, [data, isSingle]);

  const noCoinbaseTransactions =
    //@ts-ignore
    blockData?.txns.filter(
      (x) =>
        x.type_id !== DbTxTypeId.Coinbase &&
        x.type_id !== DbTxTypeId.CoinbaseToAltRecipient &&
        x.type_id !== DbTxTypeId.NakamotoCoinbase
    ) || [];
  const fee = !noCoinbaseTransactions.length
    ? 0
    : noCoinbaseTransactions.map((x) => x.fee).reduce((acc, x) => acc + x, 0) /
      noCoinbaseTransactions.length;
  return data ? (
    <div className={classes.component}>
      <Wrapper className={classes.wrapper}>
        {!isSingle ? <div className={classes.title}>{title}</div> : <></>}
        <div
          className={classNames(
            classes.contentWrapper,
            isSingle && classes.contentWrapperSingle
          )}
        >
          {data.map((item) => (
            <div key={item.key}>
              {!isSingle && (
                <div>
                  <div key={item.key} className={classes.item}>
                    <div className={classes.iconWrapper}>
                      <img src="/images/domain.svg" />
                    </div>
                    <span className={classes.itemLabel}>{item.key}</span>
                  </div>
                </div>
              )}
              {isSingle && !isDomain && (
                <div>
                  <div className={classes.txRender}>
                    <div className={classes.renderContainer}>
                      {isLoading ? (
                        <SkeletonStxmap isBig />
                      ) : (
                        <TxRender
                          blockData={blockData}
                          onBlockDataChange={setBlockData}
                          size={300}
                        />
                      )}
                    </div>
                    <div className={classes.blockDetails}>
                      <div className={classes.blockRow}>
                        <div className={classes.blockLeft}>Average fee</div>
                        <div className={classes.blockRight}>
                          {!blockData
                            ? "-"
                            : displayFullAmount(countStxAmount(fee + ""), 4) +
                              " STX"}
                        </div>
                      </div>
                      <div className={classes.blockRow}>
                        <div className={classes.blockLeft}>Block number</div>
                        <div className={classes.blockRight}>
                          {blockData?.height || "-"}
                        </div>
                      </div>
                      <div className={classes.blockRow}>
                        <div className={classes.blockLeft}>Owner</div>
                        <div className={classes.blockRight}>
                          {shortenAddress(item.owner)}
                          {!!blockData && <CopyButton address={item.owner} />}
                        </div>
                      </div>
                      <div className={classes.blockRow}>
                        <div className={classes.blockLeft}>Minter</div>
                        <div className={classes.blockRight}>
                          {shortenAddress(item.minter)}
                          {!!blockData && <CopyButton address={item.minter} />}
                        </div>
                      </div>
                      <div className={classes.blockRow}>
                        <div className={classes.blockLeft}>
                          Transactions amount
                        </div>
                        <div className={classes.blockRight}>
                          {!blockData ? "-" : blockData?.txns.length}
                        </div>
                      </div>
                      <div className={classes.blockRow}>
                        <div className={classes.blockLeft}>Creation date</div>
                        <div className={classes.blockRight}>
                          {!blockData
                            ? "-"
                            : new Date(
                                blockData.timestamp * 1000
                              ).toLocaleTimeString([], {
                                year: "numeric",
                                month: "numeric",
                                day: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                              })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isSingle && isDomain && (
                <div className={classes.itemDomain}>
                  <div className={classes.iconWrapper}>
                    <img src="/images/domain.svg" />
                  </div>
                  <div className={classes.itemInfo}>
                    <div className={classes.row}>
                      <span>Owner:</span>
                      <div className={classes.itemAddress}>
                        {shortenAddress(item.owner)}
                      </div>
                      <CopyButton address={item.owner} />
                    </div>
                    <div className={classes.row}>
                      <span>Minter:</span>
                      <div className={classes.itemAddress}>
                        {shortenAddress(item.minter)}
                      </div>
                      <CopyButton address={item.minter} />
                    </div>
                    <div className={classes.row}>
                      <span>Creation date:</span>{" "}
                      {new Date(item.creationDate).toLocaleDateString("en-US")}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </Wrapper>
    </div>
  ) : (
    <></>
  );
};
