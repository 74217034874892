import React, { useState } from "react";
import classes from "./WrapForm.module.scss";
import { uniqueId } from "lodash";
import classNames from "classnames";
import { Wrapper } from "../Wrapper/Wrapper.tsx";
import { WrapType } from "../../constants.ts";
import { WrapAsSip9Form } from "./WrapAsSip9Form/WrapAsSip9Form.tsx";
import { UnwrapAsStx20Form } from "./UnwrapAsStx20Form/UnwrapAsStx20Form.tsx";

type Props = {};
export const WrapForm = ({}: Props) => {
  const [wrapType, setWrapType] = useState<WrapType>(WrapType.WRAP);

  return (
    <Wrapper className={classes.wrapper}>
      <div className={classes.tabsWrapper}>
        {Object.values(WrapType).map((type) => (
          <div
            key={uniqueId(type)}
            onClick={() => setWrapType(type)}
            className={classNames(
              classes.tabButton,
              wrapType === type ? classes.tabButtonActive : ""
            )}
          >
            {type}
          </div>
        ))}
      </div>
      <div className={classes.tabContentWrapper}>
        {wrapType === WrapType.WRAP && <WrapAsSip9Form />}
        {wrapType === WrapType.UNWRAP && <UnwrapAsStx20Form />}
      </div>
    </Wrapper>
  );
};
