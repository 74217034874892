import React, { useState } from "react";
import classes from "./Token.module.scss";
import { Wrapper } from "../Wrapper/Wrapper.tsx";

type Props = {
  name?: string;
  amount?: string;
};
export const Token = ({ name = "NKS", amount = "123.45" }: Props) => {
  return (
    <div className={classes.component}>
      <Wrapper className={classes.wrapper}>
        <div className={classes.icon}>
          <img
            src="/images/icon-ticker.svg"
            alt=""
            className={classes.iconImage}
          />
        </div>
        <div className={classes.content}>
          <h4 className={classes.name}>{name}</h4>
          <span className={classes.amount}>{amount}</span>
        </div>
      </Wrapper>
    </div>
  );
};
