import React, { useContext, useEffect, useState } from "react";
import styles from "./MainPageStyles.module.scss";
import { Header } from "../components/Header/Header.tsx";
import { Footer } from "../components/Footer/Footer.tsx";
import useData from "../hooks/useData.ts";
import {CONNECT_MESSAGE, DisplayType, LocalStorageKeys} from "../constants.ts";
import {login} from "../Axios/AxiosInstance.ts";
import {UseBlockchainCalls} from "../hooks/useBlockchainCalls.ts";
import { Outlet } from "react-router-dom";
import { getProvider } from "../utils/getProvider.ts";
import { useAddress } from "../store/address.ts";

export const MainPage = () => {
  const {setIsAuthorized} = useData();
  const [address] = useAddress();
  const {signMessage} = UseBlockchainCalls();

  useEffect(() => {
    const isAuthorized = localStorage.getItem(LocalStorageKeys.JWT_TOKEN);
    if (!address || isAuthorized) return;
    if (!isAuthorized && localStorage.getItem(LocalStorageKeys.WALLET_TYPE)) {
    const walletProvider = getProvider();

      if (walletProvider) {
        signMessage(CONNECT_MESSAGE, (res) => {
          return login({
            publicKey: res.publicKey,
            signature: res.signature,
            address,
            setIsAuthorized
          });
        });
      }
    }
  }, [address]);

  return (
    <div className={styles.container}>
      <Header />
      <main className={styles.main}>
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};
