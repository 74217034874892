import React, { useState } from "react";
import classes from "./RangeFilter.module.scss";
import classNames from "classnames";
import { BigPurpuleButton } from "../Buttons/BigPurpuleButton/BigPurpuleButton.tsx";
import { SimpleButton } from "../Buttons/SimpleButton/SimpleButton.tsx";
import { Input } from "../InscribeForm/InscribeInput/Input.tsx";

type Props = {
  fromValue: string;
  toValue: string;
  onApply: (from: string, to: string) => void;
  className?: string;
};
export const RangeFilter = ({
  fromValue,
  toValue,
  onApply,
  className,
}: Props) => {
  const [showMenu, setShowMenu] = useState(false);
  const [valueTo, setValueTo] = useState(toValue);
  const [valueFrom, setValueFrom] = useState(fromValue);
  const [error, setError] = useState("");

  return (
    <div className={classes.component}>
      <div
        className={classNames(className || "", classes.selectedWrapper)}
        onClick={(e) => {
          e.stopPropagation();
          setShowMenu(!showMenu);
        }}
      >
        <div className={classes.caption}>
          <span className={classes.label}>Numbers range:</span>
          <span className={classes.title}>
            {valueFrom}
            {(valueTo.length > 0 || valueFrom.length > 0) && ` - `}
            {valueTo}
          </span>
        </div>
        <img
          src="/images/icon-dropdown.svg"
          alt=""
          className={classNames(classes.arrow, showMenu && classes.arrowOpened)}
        />
      </div>
      {showMenu && (
        <>
          <div className={classes.menuBg} onClick={() => setShowMenu(false)} />
          <div className={classes.menuItems}>
            <div className={classes.inputs}>
              <Input
                value={valueFrom}
                name="from"
                placeholder="From"
                onChange={(val) => {
                  setError("");
                  const vvall = val.replace(/\D/g, "");
                  if (isNaN(Number(vvall))) setError("Wrong data");
                  else if (
                    vvall.length > 0 &&
                    valueTo.length > 0 &&
                    Number(valueTo) <= Number(vvall)
                  )
                    setError(
                      '"To" number cannot be less or equal "From" number'
                    );
                  setValueFrom(vvall);
                }}
                className={classes.input}
              />
              <Input
                value={valueTo}
                name="to"
                placeholder="To"
                onChange={(val) => {
                  setError("");
                  const vvall = val.replace(/\D/g, "");
                  if (
                    vvall.length > 0 &&
                    valueFrom.length > 0 &&
                    Number(valueFrom) >= Number(vvall)
                  )
                    setError(
                      '"To" number cannot be less or equal "From" number'
                    );
                  setValueTo(vvall);
                }}
                className={classes.input}
              />
            </div>
            {error && <div className={classes.error}>{error}</div>}
            <div className={classes.buttons}>
              <SimpleButton
                text="Clear filter"
                onClick={() => {
                  onApply("", "");
                  setValueFrom("");
                  setValueTo("");
                  setShowMenu(false);
                }}
                className={classes.button}
              />
              <BigPurpuleButton
                text="Apply"
                disabled={
                  error.length > 0 ||
                  valueFrom.length === 0 ||
                  valueTo.length === 0 ||
                  Number(valueFrom) >= Number(valueTo)
                }
                onClick={() => {
                  onApply(valueFrom, valueTo);
                  setShowMenu(false);
                }}
                className={classes.button}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
