import React, { useState } from "react";
import classes from "./SuccessOrderModalStyles.module.scss";
import { NETWORK, Network, Status } from "../../../constants.ts";
import { BigPurpuleButton } from "../../Buttons/BigPurpuleButton/BigPurpuleButton.tsx";
import { wrapTxId } from "../../../utils/validation.ts";

type Props = {
  title: string;
  info: JSX.Element;
  txId: string;
  note?: string;
  onClose: () => void;
};

export const SuccessOrderModal = ({
  // оставил временно для примера, info с тегом span
  title,
  info,
  txId,
  note,
  onClose,
}: Props) => {
  const [isLoading, setLoading] = useState(false);
  const [status, setStatus] = useState<Status | null>();

  const handleGotIt = () => {
    onClose();
  };

  return (
    <>
      <div className={classes.menuBg} onClick={onClose} />
      <div className={classes.root}>
        <div className={classes.component}>
          <div className={classes.wrapper}>
            <div className={classes.header}>
              <img src="/images/success.svg" alt="" />
            </div>
            <div className={classes.content}>
              <h2 className={classes.title}>{title}</h2>
              <div className={classes.info}>{info}</div>
              <div
                className={classes.txWrap}
                onClick={() =>
                  window.open(
                    `https://explorer.hiro.so/txid/${wrapTxId(txId)}?chain=${
                      NETWORK === Network.MAINNET ? "mainnet" : "testnet"
                    }`,
                    `_blank`
                  )
                }
              >
                <span className={classes.txLink}>Open in explorer</span>
                <img src="/images/icon-link-puple.svg" alt="" />
              </div>
              {note && (
                <div className={classes.noteWrap}>
                  <p className={classes.noteText}>
                    <span>Note: </span>
                    {note}
                  </p>
                </div>
              )}
              <BigPurpuleButton
                onClick={handleGotIt}
                text="Got It"
                className={classes.button}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
