import React, { useEffect, useMemo, useRef, useState } from "react";
import {verticalShader} from './shaders/vert.ts'
import {horShader} from './shaders/tx.ts'
import TxSprite from './models/TxSprite.ts'
import { color, hcl } from 'd3-color'
import TxController from "./controllers/TxController.ts";
import BitcoinTx from "./models/BitcoinTx.ts";
import { TxTip } from "./components/TxTip.tsx";
import BitcoinBlock from "./models/BitcoinBlock.ts";

let gl
let animationFrameRequest
let displayWidth
let displayHeight
let cssWidth
let cssHeight
let shaderProgram
let pointArray

const attribs = {
    offset: { type: 'FLOAT', count: 2, pointer: null },
    posX: { type: 'FLOAT', count: 4, pointer: null },
    posY: { type: 'FLOAT', count: 4, pointer: null },
    posR: { type: 'FLOAT', count: 4, pointer: null },
    hues: { type: 'FLOAT', count: 4, pointer: null },
    lights: { type: 'FLOAT', count: 4, pointer: null },
    alphas: { type: 'FLOAT', count: 4, pointer: null }
}

const stride = Object.values(attribs).reduce((total, attrib) => {
    return total + (attrib.count * 4)
}, 0)

for (let i = 0, offset = 0; i < Object.keys(attribs).length; i++) {
    let attrib = Object.values(attribs)[i]
    //@ts-ignore
    attrib.offset = offset
    offset += (attrib.count * 4)
}

let colorTexture
let sizeFrozen

export const TxRender: React.FC<{
  onBlockDataChange: (val: BitcoinBlock) => void, 
  size: number,
  blockData: BitcoinBlock | undefined;
}> = ({onBlockDataChange, blockData, size = 300}) => {
    const running = useRef(false);
    const [isRunning, setRunning] = useState(false);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const divRef = useRef<HTMLDivElement | null>(null);
    const [ready, setReady] = useState(false);
    const [selectedTx, setSelectedTx] = useState<BitcoinTx>();
    const controllerRef = useRef<TxController>();

    function windowReady () {
        resizeCanvas()
    }

    let resizeTimer;
    function resizeCanvas () {
        if (resizeTimer) clearTimeout(resizeTimer)
        resizeTimer = null
        if (canvasRef.current && !sizeFrozen) {
            cssWidth = (divRef.current?.getBoundingClientRect().left || 0 ) + 300
            cssHeight = 300
            displayWidth = cssWidth
            displayHeight = cssHeight
            canvasRef.current.width = (divRef.current?.getBoundingClientRect().left || 0 ) + 300
            if (gl) gl.viewport(0, 0, displayWidth, 300)
        } else {
            resizeTimer = setTimeout(resizeCanvas, 500)
        }
    }

    function getTxPointArray () {
        if (controller) {

          return controller.getVertexData()
        } else return new Float32Array()
    }

    function getDebugTxPointArray () {
        if (controller && true) {
          return controller.getDebugVertexData()
        } else return new Float32Array()
    }
    
    function compileShader(src, type) {
        let shader = gl.createShader(type)
    
        gl.shaderSource(shader, src)
        gl.compileShader(shader)
        return shader
    }

    function buildShaderProgram(shaderInfo) {
        let program = gl.createProgram()
    
        shaderInfo.forEach(function(desc) {
          let shader = compileShader(desc.src, desc.type)
          if (shader) {
            gl.attachShader(program, shader)
          }
        })
    
        gl.linkProgram(program)
    
        return program
    }

    function run () {
        let now = performance.now()
        pointArray = getTxPointArray()
    
        gl.uniform2f(gl.getUniformLocation(shaderProgram, 'screenSize'), cssWidth, cssHeight)
        gl.uniform1f(gl.getUniformLocation(shaderProgram, 'now'), now)
        gl.uniform1i(gl.getUniformLocation(shaderProgram, 'colorTexture'), 0);
        Object.keys(attribs).forEach((key, i) => {
          gl.vertexAttribPointer(attribs[key].pointer,
              attribs[key].count, 
              gl[attribs[key].type], 
              false,
              stride,  
              attribs[key].offset); 
        })
        if (pointArray.length) {
          gl.bufferData(gl.ARRAY_BUFFER, pointArray, gl.DYNAMIC_DRAW)
          //@ts-ignore
          gl.drawArrays(gl.TRIANGLES, 0, pointArray.length / TxSprite.vertexSize)
        }
    
        if (running) {
          animationFrameRequest = requestAnimationFrame(run)
        }
    }

    function computeColorTextureData(width, height) {
        return [...Array(Math.floor(height)).keys()].flatMap(row => {
          return [...Array(width).keys()].flatMap(step => {
            let rgb = color(hcl((row/height) * 360, 78.225, (step / width) * 150)).rgb()
            return [
              rgb.r,
              rgb.g,
              rgb.b,
              255
            ]
          })
        })
    }

  function loadColorTexture(gl, width, height) {
    const texture = gl.createTexture();
    gl.bindTexture(gl.TEXTURE_2D, texture);

    const colorData = computeColorTextureData(width, height);

    const level = 0;
    const internalFormat = gl.RGBA;
    const border = 0;
    const srcFormat = gl.RGBA;
    const srcType = gl.UNSIGNED_BYTE;
    const pixels = new Uint8Array(
      colorData
    )

    gl.texImage2D(gl.TEXTURE_2D, level, internalFormat,
                  width, height, border, srcFormat, srcType,
                  pixels);

    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);

    return texture;
  }

  function initCanvas () {
    gl.clearColor(0.0, 0.0, 0.0, 0.0)
    gl.clear(gl.COLOR_BUFFER_BIT)

    const shaderSet = [
      {
        type: gl.VERTEX_SHADER,
        src: verticalShader
      },
      {
        type: gl.FRAGMENT_SHADER,
        src: horShader
      }
    ]

    shaderProgram = buildShaderProgram(shaderSet)

    gl.useProgram(shaderProgram)

    gl.enable(gl.BLEND);
    gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_ALPHA);

    colorTexture = loadColorTexture(gl, 512, 512);

    const glBuffer = gl.createBuffer()
    gl.bindBuffer(gl.ARRAY_BUFFER, glBuffer)

    Object.keys(attribs).forEach((key, i) => {
      attribs[key].pointer = gl.getAttribLocation(shaderProgram, key)
      gl.enableVertexAttribArray(attribs[key].pointer);
    })

    running.current = true
    setRunning(true);
  }

  function handleContextLost(event) {
    event.preventDefault()
    cancelAnimationFrame(animationFrameRequest)
    animationFrameRequest = null
    running.current = false
    setRunning(false);
  }

  function handleContextRestored(event) {
    initCanvas();
    running.current = true
    setRunning(true);
  }

  useEffect(() => {
    if (!canvasRef.current) {
        return;
    }

    canvasRef.current.addEventListener("webglcontextlost", handleContextLost, false)
    canvasRef.current.addEventListener("webglcontextrestored", handleContextRestored, false)
    gl = canvasRef.current.getContext('webgl')
    initCanvas();
    window.addEventListener('resize', function(event) {
        resizeCanvas();
    }, true);
    window.addEventListener('load', function(event) {
        windowReady();
        setReady(true);
    }, true);
  }, []);

  useEffect(() => {
    setReady(true);
  }, [])

  const controller = useMemo(() => {
    if (!ready)
      return;
    return new TxController({width: size, height: size, onSelected: val => {
      setSelectedTx(val)
    }, divRef:  divRef.current?.getBoundingClientRect().left});
  }, [ready]);
  controllerRef.current = controller;

  const isDoneRef = useRef(false);
  useEffect(() => {
    if (controller && blockData && !isDoneRef.current) {
      gl.clear(gl.DEPTH_BUFFER_BIT);
      gl.clear(gl.DEPTH_BUFFER_BIT | gl.COLOR_BUFFER_BIT);
      controller.explore(blockData);
      run();
      resizeCanvas();
      isDoneRef.current = true;
    }
  }, [controller, blockData]);


  function pointerMove (e) {
    if (!canvasRef.current || !divRef.current)
      return;
    if (true) {
      const position = {
        x: e.clientX - canvasRef.current.getBoundingClientRect().left - divRef.current.getBoundingClientRect().left,
        y: -e.clientY + canvasRef.current.getBoundingClientRect().bottom
      }
      if (controller) controller.mouseClick(position)
    }
  }

  function pointerLeave (e) {
    if (controller) {
      setSelectedTx(undefined);
    }
  }

  useEffect(() => {
    return () => {
      //@ts-ignore
      controllerRef.current?.explorerBlockScene?.expire(0);
    }
  }, [])

  return <div>
    <div ref={divRef} style={{width: 300, height: 300}}></div>
    <canvas
    onPointerMove={pointerMove} onMouseLeave={pointerLeave}
        width={(divRef.current?.getBoundingClientRect().left || 0 ) + 300}
        height={300}
        style={{width: (divRef.current?.getBoundingClientRect().left || 0 ) + 300+'px', height: size+'px', position: 'absolute', left: 0, top: 155}}
        ref={canvasRef}
    ></canvas>
    <TxTip selectedBlock={selectedTx} size={size}/>
  </div>
}