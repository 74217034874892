import React, { useEffect, useState, useMemo } from "react";
import classes from "./Orders.module.scss";
import { isEmpty, uniqueId } from "lodash";
import { Wrapper } from "../Wrapper/Wrapper.tsx";
import { OrderCard } from "../OrderCard/OrderCard.tsx";
import { MarketplaceType, SORT_BY } from "../../constants.ts";
import classNames from "classnames";
import { SearchTicker } from "../SearchTicker/SearchTicker.tsx";
import { BigPurpuleButton } from "../Buttons/BigPurpuleButton/BigPurpuleButton.tsx";
import { Dropdown } from "../Dropdown/Dropdown.tsx";
import { OrderType, SortOrdersMenu } from "../../types/orders.ts";
import { NoOrders } from "./components/NoOrders/NoOrders.tsx";
import { CreateOrderModal } from "../Modals/CreateOrderModal/CreateOrderModal.tsx";
import {
  IResultSellRequest,
  IPagination,
  SellRequestStatus,
} from "../../types/ApiResponseTypes.ts";
import { MarketplaceRole } from "../../Axios/constants.ts";
import {
  getAllListedDomains,
  getAllListedOrders,
  getAllListedStxmaps,
  getAllMyDomainOrders,
  getAllMyMapOrders,
  getAllMyOrders,
} from "../../Axios/AxiosInstance.ts";
import { SellRequestStatusTitle, removeEmptyFields } from "../../helpers.ts";
import { Pagination } from "../Pagination/Pagination.tsx";
import useData from "../../hooks/useData.ts";
import { HeaderCard } from "../HeaderCard/HeaderCard.tsx";
import { ConnectButton } from "../ConnectButton/ConnectButton.tsx";
import { SkeletonOrderCard } from "../OrderCard/SkeletonOrderCard.tsx";
import { CheckBox } from "../CheckBox/CheckBox.tsx";
import { RangeFilter } from "../RangeFilter/RangeFilter.tsx";

type Props = {};
export const Orders = ({}: Props) => {
  const sortMenus: SortOrdersMenu[] = [
    {
      text: SORT_BY.LOW_TO_HIGH,
      label: "Price:",
      filter: "price_asc",
    },
    {
      text: SORT_BY.HIGH_TO_LOW,
      label: "Price:",
      filter: "price_dsc",
    },
    {
      text: SORT_BY.LATEST_TO_RECENT,
      label: "Date:",
      filter: "created_asc",
    },
    {
      text: SORT_BY.RECENT_TO_LATEST,
      label: "Date:",
      filter: "created_dsc",
    },
    {
      text: SORT_BY.SMALL_TO_BIG,
      label: "Number:",
      filter: "map_asc",
    },
    {
      text: SORT_BY.BIG_TO_SMALL,
      label: "Number:",
      filter: "map_dsc",
    },
  ];

  const sortByStatus: SortOrdersMenu[] = [
    {
      text: "All",
      label: "Status:",
      filter: "all",
    },
    ...Object.values(SellRequestStatus)
      .filter((status) => status !== SellRequestStatus.DRAFT)
      .map((status) => ({
        text: SellRequestStatusTitle(status),
        label: "Status:",
        filter: status,
      })),
  ];

  const sortByStatusPurchases: SortOrdersMenu[] = [
    {
      text: "All",
      label: "Status:",
      filter: "all",
    },
    ...Object.values(SellRequestStatus)
      .filter(
        (status) =>
          status === SellRequestStatus.BUYING_IN_PROCESS ||
          status === SellRequestStatus.BUYING_COMPLETED
      )
      .map((status) => ({
        text: SellRequestStatusTitle(status),
        label: "Status:",
        filter: status,
      })),
  ];

  const LIMIT = 24;
  const LIMIT_STXMAP = 10;
  const WHITELIST_TICKERS = [
    "STXS",
    "MUNEEB",
    "MOON",
    "NAKAMOJO",
    "STX",
    "NAKAMOTO",
    "FOXINU",
    "RIZZ",
    "BULLDOG",
    "PEPE",
  ];
  const FIRST_SEARCH_TICKER = "STXS";

  const [inputSearch, setInputSearch] = useState(FIRST_SEARCH_TICKER);
  const [search, setSearch] = useState(FIRST_SEARCH_TICKER);
  const [searchError, setSearchError] = useState("");
  const [isShowOrderModal, setShowOrderModal] = useState(false);
  const [orderSort, setOrderSort] = useState<SortOrdersMenu>(sortMenus[0]);
  const [statusSort, setStatusSort] = useState<SortOrdersMenu>(sortByStatus[0]);
  const [statusSortPurchases, setStatusSortPurchases] =
    useState<SortOrdersMenu>(sortByStatusPurchases[0]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pagination, setPagination] = useState<IPagination>();
  const [currentTab, setCurrentTab] = useState<MarketplaceType>(
    MarketplaceType.TOKENS
  );
  const [currentMyOrdersTab, setCurrentMyOrdersTab] = useState<OrderType>(
    OrderType.TICKER
  );
  const [currentMyPurchasesTab, setCurrentMyPurchasesTab] = useState<OrderType>(
    OrderType.TICKER
  );
  const [orders, setOrders] = useState<IResultSellRequest[]>();
  const { isAuthorized } = useData();
  const [isLoading, setLoading] = useState(false);
  const [isHidePendingPurchases, setHidePendingPurchases] = useState(false);
  const [isFirstSearch, setFirstSearch] = useState(true);
  const [stxmapTo, setStxmapTo] = useState("");
  const [stxmapFrom, setStxmapFrom] = useState("");

  useEffect(() => {
    if (!isFirstSearch) {
      setSearch("");
      setInputSearch("");
    }
  }, [currentTab]);

  const getData = async (searchVal: string) => {
    // if (!checkTicker(searchVal)) return; TODO: check ticker

    const filtersQuery = removeEmptyFields({
      ...{ sort: orderSort.filter },
      ...{ page: currentPage.toString() },
      ...{
        limit: currentTab !== MarketplaceType.STXMAP ? LIMIT : LIMIT_STXMAP,
      },
    }) as Record<string, string>;

    switch (currentTab) {
      case MarketplaceType.TOKENS: {
        setLoading(true);
        setOrders([]);
        const orderResponse = await getAllListedOrders(
          search,
          !isHidePendingPurchases,
          filtersQuery
        );
        setPagination(orderResponse.pagination);
        setOrders(orderResponse.data);
        setLoading(false);
        isFirstSearch && setFirstSearch(false);
        return;
      }
      case MarketplaceType.MYORDERS: {
        setOrders([]);
        if (!isAuthorized) return;
        setLoading(true);

        const orderResponse =
          currentMyOrdersTab === OrderType.TICKER
            ? await getAllMyOrders(
                search,
                statusSort.filter !== "all"
                  ? [statusSort.filter]
                  : [...Object.values(SellRequestStatus)],
                MarketplaceRole.SELLER,
                filtersQuery
              )
            : currentMyOrdersTab === OrderType.STXMAP
            ? await getAllMyMapOrders(
                search,
                statusSort.filter !== "all"
                  ? [statusSort.filter]
                  : [...Object.values(SellRequestStatus)],
                MarketplaceRole.SELLER,
                filtersQuery
              )
            : await getAllMyDomainOrders(
                search,
                statusSort.filter !== "all"
                  ? [statusSort.filter]
                  : [...Object.values(SellRequestStatus)],
                MarketplaceRole.SELLER,
                filtersQuery
              );
        const orders = orderResponse.data;
        setPagination(orderResponse.pagination);
        setOrders(orders);
        setLoading(false);
        return;
      }
      case MarketplaceType.MY_PURCHASES: {
        setOrders([]);
        if (!isAuthorized) return;
        setLoading(true);
        const orderResponse =
          currentMyPurchasesTab === OrderType.TICKER
            ? await getAllMyOrders(
                search,
                statusSortPurchases.filter !== "all"
                  ? [statusSortPurchases.filter]
                  : [
                      ...Object.values(SellRequestStatus).filter(
                        (status) =>
                          status === SellRequestStatus.BUYING_IN_PROCESS ||
                          status === SellRequestStatus.BUYING_COMPLETED
                      ),
                    ],
                MarketplaceRole.BUYER,
                filtersQuery
              )
            : currentMyPurchasesTab === OrderType.STXMAP
            ? await getAllMyMapOrders(
                search,
                statusSortPurchases.filter !== "all"
                  ? [statusSortPurchases.filter]
                  : [
                      ...Object.values(SellRequestStatus).filter(
                        (status) =>
                          status === SellRequestStatus.BUYING_IN_PROCESS ||
                          status === SellRequestStatus.BUYING_COMPLETED
                      ),
                    ],
                MarketplaceRole.BUYER,
                filtersQuery
              )
            : await getAllMyDomainOrders(
                search,
                statusSortPurchases.filter !== "all"
                  ? [statusSortPurchases.filter]
                  : [
                      ...Object.values(SellRequestStatus).filter(
                        (status) =>
                          status === SellRequestStatus.BUYING_IN_PROCESS ||
                          status === SellRequestStatus.BUYING_COMPLETED
                      ),
                    ],
                MarketplaceRole.BUYER,
                filtersQuery
              );
        // @ts-ignore
        setOrders(orderResponse.data);
        setPagination(orderResponse.pagination);
        setLoading(false);
        return;
      }
      case MarketplaceType.DOMAINS: {
        setLoading(true);
        setOrders([]);
        const orderResponse = await getAllListedDomains(
          search,
          !isHidePendingPurchases,
          filtersQuery
        );
        setPagination(orderResponse.pagination);
        setOrders(orderResponse.data);
        setLoading(false);
        isFirstSearch && setFirstSearch(false);
        return;
      }
      case MarketplaceType.STXMAP: {
        setLoading(true);
        setOrders([]);
        const searchVal = search.endsWith(".stxmap")
          ? search.slice(0, -7)
          : search;
        const orderResponse = await getAllListedStxmaps(
          searchVal,
          !isHidePendingPurchases,
          filtersQuery,
          stxmapFrom,
          stxmapTo
        );
        setPagination(orderResponse.pagination);
        setOrders(orderResponse.data);
        setLoading(false);
        isFirstSearch && setFirstSearch(false);
        return;
      }
    }
  };
  useEffect(() => {
    getData(search);
  }, [
    orderSort,
    currentPage,
    statusSort,
    statusSortPurchases,
    currentTab,
    currentMyOrdersTab,
    currentMyPurchasesTab,
    search,
    isHidePendingPurchases,
    // isFirstSearch,
    stxmapTo,
    stxmapFrom,
  ]);

  const isShowFilters = useMemo(() => {
    if (orders && orders.length === 0 && !isLoading) {
      if (currentTab === MarketplaceType.TOKENS && !search) {
        return false;
      }
      if (
        currentTab === MarketplaceType.MYORDERS &&
        !search &&
        statusSort.filter === sortByStatus[0].filter
      ) {
        return false;
      }
      if (
        currentTab === MarketplaceType.MY_PURCHASES &&
        !search &&
        statusSortPurchases.filter === sortByStatusPurchases[0].filter
      ) {
        return false;
      }
    }
    return true;
  }, [
    orders,
    currentTab,
    orderSort,
    statusSort,
    sortByStatusPurchases,
    search,
  ]);

  useEffect(() => {
    !isAuthorized && setOrders([]);
  }, [isAuthorized]);

  // inputSearch;
  useEffect(() => {
    if (!isFirstSearch) {
      setSearch("");
      setInputSearch("");
    }
  }, [currentMyOrdersTab, currentMyPurchasesTab]);

  const handleTabChange = (type: MarketplaceType) => {
    if (
      type === MarketplaceType.MY_PURCHASES ||
      type === MarketplaceType.MYORDERS
    ) {
      const sortByDate = sortMenus.find(
        (sortItem) => sortItem.text === SORT_BY.RECENT_TO_LATEST
      );
      if (sortByDate) setOrderSort(sortByDate);
    } else {
      setOrderSort(sortMenus[0]);
    }
    setCurrentTab(type);
  };

  const handleCreateOrder = () => {
    setShowOrderModal(true);
  };
  const handleChangeChecked = () => {
    setHidePendingPurchases((prevState) => !prevState);
  };

  const currentOrderType = useMemo(() => {
    return currentTab === MarketplaceType.TOKENS ||
      (currentTab === MarketplaceType.MYORDERS &&
        currentMyOrdersTab === OrderType.TICKER) ||
      (currentTab === MarketplaceType.MY_PURCHASES &&
        currentMyPurchasesTab === OrderType.TICKER)
      ? OrderType.TICKER
      : currentTab === MarketplaceType.STXMAP ||
        (currentTab === MarketplaceType.MYORDERS &&
          currentMyOrdersTab === OrderType.STXMAP) ||
        (currentTab === MarketplaceType.MY_PURCHASES &&
          currentMyPurchasesTab === OrderType.STXMAP)
      ? OrderType.STXMAP
      : OrderType.STXS;
  }, [currentTab, currentMyOrdersTab, currentMyPurchasesTab]);

  const handleFilterRange = (from: string, to: string) => {
    console.log("-+-+", from, to);
    setStxmapTo(to);
    setStxmapFrom(from);
  };

  return (
    <div className={classes.component}>
      <Wrapper className={classes.wrapper}>
        <div className={classes.headerWrapper}>
          <div className={classes.tabsWrapper}>
            {Object.values(MarketplaceType).map((type) => (
              <div
                key={uniqueId(type)}
                onClick={() => handleTabChange(type)}
                className={classNames(
                  classes.tabButton,
                  currentTab === type ? classes.tabButtonActive : ""
                )}
              >
                {type}
              </div>
            ))}
          </div>
          {(currentTab === MarketplaceType.MYORDERS ||
            currentTab === MarketplaceType.MY_PURCHASES) && (
            <div className={classes.tabsMyWrapper}>
              {Object.values(OrderType).map((type) => (
                <div
                  key={uniqueId(type)}
                  onClick={() =>
                    currentTab === MarketplaceType.MYORDERS
                      ? setCurrentMyOrdersTab(type)
                      : setCurrentMyPurchasesTab(type)
                  }
                  className={classNames(
                    classes.switchButtonGradientNew,
                    currentTab === MarketplaceType.MYORDERS &&
                      currentMyOrdersTab === type
                      ? classes.switchButtonGradientActive
                      : "",
                    currentTab === MarketplaceType.MY_PURCHASES &&
                      currentMyPurchasesTab === type
                      ? classes.switchButtonGradientActive
                      : ""
                  )}
                >
                  <span>{type}</span>
                </div>
              ))}
            </div>
          )}
          {currentTab === MarketplaceType.TOKENS && (
            <div className={classes.listedAlert}>
              Please temporary do not speed up the processed transaction by
              additional transactions, it can cause tx tracking issues. Instead,
              set a higher fee while creating the original transaction.
            </div>
          )}
          {isShowFilters && (
            <div
              className={classNames(
                classes.searchWrapper,
                isAuthorized &&
                  currentTab === MarketplaceType.MYORDERS &&
                  classes.searchWrapper4Btn
              )}
            >
              {((currentOrderType === OrderType.TICKER && isAuthorized) ||
                (!isAuthorized && currentTab === MarketplaceType.TOKENS)) && (
                <div className={classes.searchTickerWrapper}>
                  <SearchTicker
                    name="searchTicker"
                    value={inputSearch}
                    placeholder="Ticker"
                    onChange={(val) => {
                      setInputSearch(val);
                    }}
                    onSearch={(val) => {
                      setSearch(val);
                    }}
                    className={classes.searchTickerInput}
                    classNameWrapper={classes.searchTickerInputWrapper}
                    error={searchError}
                    listTickers={WHITELIST_TICKERS}
                  />
                </div>
              )}
              {(currentOrderType === OrderType.STXS ||
                currentOrderType === OrderType.STXMAP) && (
                <div className={classes.searchTickerWrapper}>
                  <SearchTicker
                    name="searchValue"
                    value={inputSearch}
                    placeholder={
                      currentOrderType === OrderType.STXS
                        ? "Domain name"
                        : "Block number"
                    }
                    onChange={(val) => {
                      setInputSearch(val);
                    }}
                    onSearch={(val) => {
                      setSearch(val);
                    }}
                    className={classes.searchTickerInput}
                    classNameWrapper={classes.searchTickerInputWrapper}
                    error={searchError}
                    listTickers={[]}
                  />
                </div>
              )}
              {isAuthorized &&
                currentTab !== MarketplaceType.TOKENS &&
                currentTab !== MarketplaceType.DOMAINS &&
                currentTab !== MarketplaceType.STXMAP && (
                  <Dropdown
                    selectedItem={
                      currentTab === MarketplaceType.MYORDERS
                        ? statusSort
                        : statusSortPurchases
                    }
                    dropdownItems={
                      currentTab === MarketplaceType.MYORDERS
                        ? sortByStatus
                        : sortByStatusPurchases
                    }
                    hideLabelInList={true}
                    onChange={(val) => {
                      setCurrentPage(0);
                      currentTab === MarketplaceType.MYORDERS
                        ? setStatusSort(val)
                        : setStatusSortPurchases(val);
                    }}
                    className={classes.dropdown}
                  />
                )}
              {(currentTab === MarketplaceType.TOKENS ||
                currentTab === MarketplaceType.DOMAINS ||
                currentTab === MarketplaceType.STXMAP) && (
                <div className={classes.checkWrapper}>
                  <CheckBox
                    label="Hide pending purchases"
                    checked={isHidePendingPurchases}
                    onChecked={handleChangeChecked}
                  />
                </div>
              )}
              {currentTab === MarketplaceType.STXMAP && (
                <div className={classes.checkWrapper}>
                  <RangeFilter
                    toValue={stxmapTo}
                    fromValue={stxmapFrom}
                    onApply={handleFilterRange}
                  />
                </div>
              )}

              {(isAuthorized ||
                (!isAuthorized &&
                  currentTab !== MarketplaceType.MYORDERS &&
                  currentTab !== MarketplaceType.MY_PURCHASES)) && (
                <Dropdown
                  selectedItem={orderSort}
                  dropdownItems={
                    currentOrderType === OrderType.STXMAP
                      ? sortMenus
                      : sortMenus.filter((sort) => sort.label !== "Number:")
                  }
                  onChange={(val) => {
                    setCurrentPage(0);
                    setOrderSort(val);
                  }}
                  className={classes.dropdown}
                />
              )}
              {isAuthorized && currentTab === MarketplaceType.MYORDERS && (
                <div className={classes.createOrderWrap}>
                  <BigPurpuleButton
                    onClick={handleCreateOrder}
                    text={"+ Create Sell Order"}
                    className={classes.button}
                  />
                </div>
              )}
            </div>
          )}
        </div>
        <>
          {isLoading && (
            <div className={classes.ordersWrapper}>
              <div
                className={classNames(
                  classes.orderList,
                  currentOrderType === OrderType.STXMAP && classes.orderList5,
                  classes.orderListSkeleton
                )}
              >
                {[0, 1, 2, 3, 4, 5, 6, 7].map((val) => (
                  <SkeletonOrderCard
                    orderType={currentOrderType}
                    key={uniqueId(`skeleton-${val}`)}
                  />
                ))}
              </div>
            </div>
          )}
          {!isAuthorized &&
          (currentTab === MarketplaceType.MYORDERS ||
            currentTab === MarketplaceType.MY_PURCHASES) ? (
            <>
              <HeaderCard
                title="Connect wallet"
                caption="Connect your Stacks wallet to start trading the inscriptions."
                image="/images/icon-connect.svg"
              />
              <div className={classes.connectButtonWrapper}>
                <ConnectButton />
              </div>
            </>
          ) : (
            <>
              {!isLoading &&
                !isAuthorized &&
                (currentTab === MarketplaceType.TOKENS ||
                  currentTab === MarketplaceType.DOMAINS ||
                  currentTab === MarketplaceType.STXMAP) && (
                  <>
                    {isEmpty(orders) ? (
                      <NoOrders
                        title={
                          // isShowFilters
                          search !== ""
                            ? "No results found"
                            : "No active orders"
                        }
                        isInitialData={isShowFilters}
                      />
                    ) : (
                      <div className={classes.ordersWrapper}>
                        <div
                          className={classNames(
                            classes.orderList,
                            currentOrderType === OrderType.STXMAP &&
                              classes.orderList5
                          )}
                        >
                          {orders?.map((order) => (
                            <OrderCard
                              onSuccess={() => getData(search)}
                              key={order._id}
                              order={order}
                              marketplaceType={currentTab}
                              orderType={currentOrderType}
                            />
                          ))}
                        </div>
                        <Pagination
                          currentPage={pagination?.page || 0}
                          pageCount={Math.ceil(
                            Number(pagination?.count || 1) / LIMIT
                          )}
                          onChange={(val) => setCurrentPage(val)}
                        />
                      </div>
                    )}
                  </>
                )}
            </>
          )}
        </>
        <>
          {!isLoading && isAuthorized && (
            <>
              {isEmpty(orders) ? (
                <NoOrders
                  isInitialData={isShowFilters}
                  onClick={
                    currentTab === MarketplaceType.MYORDERS
                      ? handleCreateOrder
                      : undefined
                  }
                  title={
                    // isShowFilters
                    search !== "" ? "No results found" : "No active orders"
                  }
                />
              ) : (
                <div className={classes.ordersWrapper}>
                  <div
                    className={classNames(
                      classes.orderList,
                      currentOrderType === OrderType.STXMAP &&
                        classes.orderList5
                    )}
                  >
                    {orders?.map((order) => (
                      <OrderCard
                        onSuccess={() => getData(search)}
                        key={order._id}
                        order={order}
                        marketplaceType={currentTab}
                        orderType={currentOrderType}
                      />
                    ))}
                  </div>
                  <Pagination
                    currentPage={pagination?.page || 0}
                    pageCount={Math.ceil(
                      Number(pagination?.count || 1) / LIMIT
                    )}
                    onChange={(val) => setCurrentPage(val)}
                  />
                </div>
              )}
            </>
          )}
        </>
      </Wrapper>
      {isShowOrderModal && (
        <CreateOrderModal
          onSuccess={() => getData(search)}
          onClose={() => setShowOrderModal(false)}
        />
      )}
    </div>
  );
};
