import React, { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { HeaderCard } from "./components/HeaderCard/HeaderCard.tsx";
import { ExploreForm } from "./components/ExploreForm/ExploreForm.tsx";
import { MainPage } from "./pages/MainPage.tsx";
import useData from "./hooks/useData.ts";
import { InscribeForm } from "./components/InscribeForm/InscribeForm.tsx";
import { ConnectButton } from "./components/ConnectButton/ConnectButton.tsx";
import { Orders } from "./components/Orders/Orders.tsx";
import styles from "./pages/MainPageStyles.module.scss";
import { AppRoutes } from "./constants.ts";
import ReactGA from "react-ga4";
import { WrapForm } from "./components/WrapForm/WrapForm.tsx";

export const Navigation = () => {
  const { isAuthorized } = useData();
  ReactGA.initialize("G-JPNL8GMCDK");
  const navigateToDefault = <Navigate to="/Explore" replace />;
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<MainPage />}>
        <Route index path="/" element={navigateToDefault} />
        <Route
          path={AppRoutes.Explore}
          element={
            <>
              <HeaderCard
                title="Explore more details"
                caption="Paste a wallet address/BNS name to get the balance or a token ticker/stxmap to get the metadata and tap Enter."
                image="/images/rocket.png"
                afterText="The very first token on STX20 - STXS - is minted out."
              />
              <ExploreForm />
            </>
          }
        />
        <Route
          path={AppRoutes.Inscribe}
          element={
            <>
              {isAuthorized && (
                <>
                  <HeaderCard
                    title="Inscribe"
                    caption="Deploy your new token, mint or transfer existing ones in a single inscribing interface."
                    image="/images/thunderbolt.png"
                    afterText="The very first token on STX20 — STXS — is minted out."
                  />
                  <div className={styles.hr} />
                  <InscribeForm />
                </>
              )}
              {!isAuthorized && (
                <>
                  <HeaderCard
                    title="Connect wallet"
                    caption="Connect your Stacks wallet to create new inscriptions."
                    image="/images/icon-connect.svg"
                  />
                  <div className={styles.connectButtonWrapper}>
                    <ConnectButton />
                  </div>
                </>
              )}
            </>
          }
        />
        <Route
          path={AppRoutes.Wrap}
          element={
            <>
              {isAuthorized && (
                <>
                  <HeaderCard
                    title="Wrap Tokens"
                    caption="Convert your STX20 tokens using the Xlink Bridge for compatibility with Stacks smart contracts"
                    image="/images/switch.png"
                  />
                  <div className={styles.hr} />
                  <WrapForm />
                </>
              )}
              {!isAuthorized && (
                <>
                  <HeaderCard
                    title="Connect wallet"
                    caption="Connect your Stacks wallet to create new inscriptions."
                    image="/images/icon-connect.svg"
                  />
                  <div className={styles.connectButtonWrapper}>
                    <ConnectButton />
                  </div>
                </>
              )}
            </>
          }
        />
        <Route path={AppRoutes.Marketplace} element={<Orders />} />
        <Route path="*" element={navigateToDefault} />
      </Route>
      <Route path="/" element={navigateToDefault} />
    </Routes>
  );
};
