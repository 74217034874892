import React, { useEffect } from "react";
import classes from "./SuccessModalStyles.module.scss";
import { Status } from "../../../constants.ts";

type Props = {
  status: Status;
  onClose: () => void;
};

export const SuccessModal = ({ status, onClose }: Props) => {
  useEffect(() => {
    const closeModal = () => onClose();
    const timerId = setTimeout(closeModal, 5000);

    return () => clearTimeout(timerId);
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.component}>
        <div className={classes.wrapper}>
          {status === Status.SUCCESS && (
            <>
              <img src="/images/success_icon.svg" width={40} height={40} />
              <span className={classes.statusWrapper}>Success</span>
            </>
          )}
          {status === Status.FAIL && (
            <>
              <img src="/images/error.svg" width={40} height={40} />
              <span className={classes.statusWrapper}>Error</span>
            </>
          )}
          <button className={classes.close} onClick={onClose}>
            <img src="/images/close.svg" width={20} height={20} alt="" />
          </button>
        </div>
      </div>
    </div>
  );
};
