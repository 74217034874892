export enum DisplayType {
  EXPLORE = "Explore",
  INSCRIBE = "Inscribe",
  WRAP = "Wrap",
  MARKETPLACE = "Marketplace",
  POINTS = "Points",
}

export enum LocalStorageKeys {
  WALLET_TYPE = "walletType",
  SESSION = "blockstack-session",
  NETWORK = "network",
  JWT_TOKEN = "token",
  JWT_REFRESH = "refresh",
}

export enum Network {
  TESTNET = "Testnet",
  MAINNET = "Mainnet",
}

export const currentPage = "http://localhost:3000";

export enum Links {
  PROTOCOL = "https://github.com/fess-v/stx20-standard",
  TWITTER = "https://twitter.com/stx20stacks",
  GITHUB = "",
  TELEGRAM = "",
  DISCORD = "https://discord.gg/stx-20",
}

export enum InscribeType {
  DEPLOY = "Deploy token",
  MINT = "Mint token",
  TRANSFER = "Transfer",
  DOMAIN = "Domain name",
  STXMAP = "Stxmap",
}
export enum WrapType {
  WRAP = "Wrap as SIP-10",
  UNWRAP = "Unwrap as STX20",
}

export enum Status {
  SUCCESS = "success",
  FAIL = "fail",
}

export enum MarketplaceType {
  TOKENS = "Tokens",
  DOMAINS = "Domains",
  STXMAP = "Stxmap",
  MYORDERS = "My orders",
  MY_PURCHASES = "My Purchases",
}

export enum PROVIDERS {
  Asigna = "Asigna",
  Xverse = "Xverse",
  Leather = "Leather",
  Okx = "OKX",
}

export const CONNECT_MESSAGE = "STX20 Lift off";
export const CANCEL_MESSAGE = "Cancel order #";

export const STX_DECIMALS = 6;
export const IS_PROD = true;

export const NETWORK = IS_PROD ? Network.MAINNET : Network.TESTNET;

export const MarketplaceReceiverAddress = {
  [Network.TESTNET]: "ST2KQZ51TZXFJKNG8W4M7J1919S6JERXDVSE3YJ9Q",
  [Network.MAINNET]: "SP06W0FY7PMW0VZ5A05814C20FCZ7VFR3WSX2QW2",
};

export const CONFIRMATION_BLOCKS = 6;

export enum AppRoutes {
  Explore = "/explore",
  Inscribe = "/inscribe",
  Marketplace = "/marketplace",
  Wrap = "/wrap",
}

export enum ProviderInstallLinks {
  ASIGNA = "https://chromewebstore.google.com/detail/asigna-multisig/gopddoinbenlokghakiafackadkbnlid?hl=en",
  LEATHER = "https://chromewebstore.google.com/detail/leather/ldinpeekobnhjjdofggfgjlcehhmanlj?hl=en",
  XVERSE = "https://chromewebstore.google.com/detail/xverse-wallet/idnnbdplmphpflfnlkomgpfbpcgelopg?hl=en",
  OKX = "https://chromewebstore.google.com/detail/okx-wallet/mcohilncbfahbmgdjkbpemcciiolgcge",
}

export enum SORT_BY {
  LOW_TO_HIGH = "From Low to High",
  HIGH_TO_LOW = "From High to Low",
  LATEST_TO_RECENT = "From Latest to Recent",
  RECENT_TO_LATEST = "From Recent to Latest",
  SMALL_TO_BIG = "From Small to Big",
  BIG_TO_SMALL = "From Big to Small",
}
const wrapperContractAddress = {
  [Network.TESTNET]: "ST18ZXDS7K2ZZQ1B335X3FV4EEJTYC4AM168MHFJH",
  [Network.MAINNET]: "SP3K8BC0PPEVCV7NZ6QSRWPQ2JE9E5B6N3PA0KBR9",
};

const wrapperContractName = {
  [Network.TESTNET]: "wrapper-v1",
  [Network.MAINNET]: "stx20-bridge-registry-v1-01",
};

export const getWrapperContractAddress = () => {
  return wrapperContractAddress[NETWORK];
};

export const getWrapperContractName = () => {
  return wrapperContractName[NETWORK];
};

const unwrapperContractAddress = {
  [Network.TESTNET]: "ST18ZXDS7K2ZZQ1B335X3FV4EEJTYC4AM168MHFJH",
  [Network.MAINNET]: "SP3K8BC0PPEVCV7NZ6QSRWPQ2JE9E5B6N3PA0KBR9",
};

const unwrapperContractName = {
  [Network.TESTNET]: "wrapper-v1",
  [Network.MAINNET]: "stx20-bridge-endpoint-v1-02",
};

export const getUnwrapperContractAddress = () => {
  return unwrapperContractAddress[NETWORK];
};

export const getUnwrapperContractName = () => {
  return unwrapperContractName[NETWORK];
};

const wrapperTicker = {
  [Network.TESTNET]: "mmmm",
  [Network.MAINNET]: "stxs",
};

export const getWrapperTicker = () => {
  return wrapperTicker[NETWORK];
};
