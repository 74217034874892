import React from "react";
import classes from "./Loader.module.scss";
import classNames from "classnames";

type Props = {
  small?: boolean;
  noMargin?: boolean;
};
export const Loader = ({ small = false, noMargin }: Props) => {
  return (
    <div className={classNames(classes.loader, small && classes.loaderMini, noMargin && classes.noMargin)} />
  );
};
