import React, { useCallback, useEffect, useMemo, useState } from "react";
import classes from "./InscribeTransfer.module.scss";
import { validateStacksAddress } from "micro-stacks/crypto";
import { Input } from "../InscribeInput/Input.tsx";
import { BigPurpuleButton } from "../../Buttons/BigPurpuleButton/BigPurpuleButton.tsx";
import useData from "../../../hooks/useData.ts";
import { Status } from "../../../constants.ts";
import { SuccessModal } from "../../Modals/SuccessModal/SuccessModal.tsx";
import { TickerInputWithBalance } from "../../TickerInput/TickerInputWithBalance.tsx";
import { validateIntegerValue } from "../../../helpers.ts";
import { useAddress } from "../../../store/address.ts";
import classNames from "classnames";
import { uniqueId } from "lodash";
import {
  resolveBnsName,
  resolveStxsName,
  validateDomain,
  validateStxmap,
} from "../../../utils/validation.ts";
import {
  getDomainDetail,
  getStxMapDetail,
} from "../../../Axios/AxiosInstance.ts";
import { debounce } from "lodash";
import { shortenAddress } from "../../../helpers.ts";
import { resolveContractName } from "../../../utils/index.ts";

type Props = {};
enum Tabs {
  STX20 = "STX20",
  STXS = ".stxs",
  STXMAP = ".stxmap",
}
const tabs = [Tabs.STX20, Tabs.STXS, Tabs.STXMAP];
export const InscribeTransfer = ({}: Props) => {
  const { stacksNetwork, makeStxTransfer } = useData();
  const [address] = useAddress();
  const [ticker, setTicker] = useState("");
  const [tickerError, setTickerError] = useState("");
  const [destination, setDestination] = useState("");
  const [destinationError, setDestinationError] = useState("");
  const [total, setTotal] = useState("");
  const [totalError, setTotalError] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [totalCharacterSizeError, setTotalCharacterSizeError] = useState("");
  const [balance, setBalance] = useState("");
  const [status, setStatus] = useState<Status | null>();
  const [domain, setDomain] = useState("");
  const [stxmap, setStxmap] = useState("");
  const [domainError, setDomainError] = useState("");
  const [stxMapError, setStxMapError] = useState("");
  const [selectedTab, setSelctedTab] = useState(tabs[0]);
  const [domainLoading, setDomainLoading] = useState(false);
  const [stxmapLoading, setStxMapLoading] = useState(false);
  const [bnsContent, setBnsContent] = useState<string | null>("");

  const handleTransfer = async () => {
    let transferMemo = "";
    if (selectedTab === Tabs.STX20) {
      transferMemo = `t${ticker}${total}`;
    }
    if (selectedTab === Tabs.STXS) {
      transferMemo = `${domain}.stxs`;
    }
    if (selectedTab === Tabs.STXMAP) {
      transferMemo = `${stxmap}.stxmap`;
    }
    if (transferMemo.length > 33) {
      setTotalCharacterSizeError(
        "Total character limit exceeded for a single inscription. Try reducing the amount of decimals."
      );
      return;
    } else {
      setTotalCharacterSizeError("");
    }
    setLoading(true);

    try {
      await makeStxTransfer({
        data: {
          recipient: bnsContent ? bnsContent : destination,
          amount: "1",
          memo: transferMemo,
          network: stacksNetwork,
          stxAddress: address || "",
        },
        onFinish: () => {
          setStatus(Status.SUCCESS);
          setTicker("");
          setTotal("");
          setDestination("");
          setTickerError("");
          setStxmap("");
          setDomain("");
          setLoading(false);
        },
        onCancel: () => {
          setStatus(Status.FAIL);
          setLoading(false);
        },
      });
    } catch (error) {
      setStatus(Status.FAIL);
      setLoading(false);
      console.error(error);
    }
  };

  const handleDestinationChange = (val) => {
    setDestination(val);
    setDestinationError("");
    if (!validateStacksAddress(val) && val.length > 0) {
      setDestinationError("Invalid STX address");
    }
    if (address === val) {
      setDestinationError("Connected address");
    }
    if (val.endsWith(".stxs")) {
      resolveStxsName(val).then((res) => {
        if (res) {
          setDestinationError("");
          setBnsContent(res);
        } else {
          setBnsContent(null);
          setDestinationError("Invalid STXS name");
        }
      });
    } else if (val.includes(".")) {
      resolveBnsName(val).then((res) => {
        if (res) {
          setDestinationError("");
          setBnsContent(res);
        } else {
          resolveContractName(val, stacksNetwork).then((res) => {
            if (res) {
              setDestinationError("");
              setBnsContent(null);
            } else setDestinationError("Invalid BNS name");
          });
        }
      });
    } else setBnsContent(null);
  };

  const handleTotalChange = (val) => {
    setTotal(val);
    validateIntegerValue(val, setTotalError, "", "Invalid total");
    if (
      Number(val) > Number(balance) &&
      tickerError.length === 0 &&
      ticker.length > 0
    ) {
      setTotalError("Insufficient funds");
    }
  };

  const fetchDomain = async (val: string) => {
    if (validateDomain(val)) return;
    setDomainError("");
    setDomainLoading(true);
    try {
      const response = await getDomainDetail(val + ".stxs");
      if (response.data.owner === address) {
        setDomainError("");
      } else {
        setDomainError("Invalid domain name");
      }
    } catch (error) {
      setDomainError("Invalid domain name");
      console.error(error);
    } finally {
      setDomainLoading(false);
    }
  };

  const fetchBlock = async (val: string) => {
    if (validateStxmap(val)) {
      return;
    }
    setStxMapError("");
    setStxMapLoading(true);
    try {
      const response = await getStxMapDetail(val);
      if (response.data.owner === address) {
        setStxMapError("");
      } else {
        setStxMapError("Invalid block height");
      }
    } catch (error) {
      setStxMapError("Invalid block height");
    } finally {
      setStxMapLoading(false);
    }
  };

  const debounceFnDomain = useCallback(debounce(fetchDomain, 300), []);
  const debounceFnBlock = useCallback(debounce(fetchBlock, 300), []);

  const handleDomainChange = async (val: string) => {
    setDomain(val);
    setDomainLoading(true);
    setDomainError(validateDomain(val));
    debounceFnDomain(val);
  };

  const handleBlockNumberChange = (val: string) => {
    setStxmap(val);
    setStxMapLoading(true);
    setStxMapError(validateStxmap(val));
    debounceFnBlock(val);
  };

  const isDisabled = useMemo(() => {
    if (destination === "" || destinationError !== "") {
      return true;
    }
    if (selectedTab === Tabs.STX20) {
      return (
        total === "" || totalError !== "" || ticker === "" || tickerError !== ""
      );
    }
    if (selectedTab === Tabs.STXS) {
      return domainLoading || domain === "" || domainError !== "";
    }
    if (selectedTab === Tabs.STXMAP) {
      return stxmapLoading || stxmap === "" || stxMapError !== "";
    }
  }, [
    selectedTab,
    total,
    totalError,
    ticker,
    tickerError,
    destination,
    destinationError,
    stxmap,
    stxMapError,
    domainLoading,
    stxmapLoading,
  ]);

  return (
    <div className={classes.wrapper}>
      {!!status && (
        <SuccessModal status={status} onClose={() => setStatus(null)} />
      )}
      <div className={classes.tabs}>
        {tabs.map((tab) => {
          return (
            <div
              key={uniqueId(tab)}
              className={classNames(
                classes.tabItem,
                tab === selectedTab && classes.tabItemActive
              )}
              onClick={() => setSelctedTab(tab)}
            >
              {tab}
            </div>
          );
        })}
      </div>
      <div className={classes.inputWrapper}>
        {selectedTab === Tabs.STX20 && (
          <div className={classes.item}>
            <TickerInputWithBalance
              setBalance={setBalance}
              onTickerErrorHandler={setTickerError}
              ticker={ticker}
              onChangeTicker={setTicker}
              tickerError={tickerError}
            />
          </div>
        )}
        {selectedTab === Tabs.STXS && (
          <div className={classes.item}>
            <Input
              name="domain"
              placeholder="Domain name"
              value={domain}
              onChange={handleDomainChange}
              error={domainError}
            />
          </div>
        )}
        {selectedTab === Tabs.STXMAP && (
          <div className={classes.item}>
            <Input
              name="blockNumber"
              placeholder="Block number"
              value={stxmap}
              onChange={handleBlockNumberChange}
              error={stxMapError}
            />
          </div>
        )}
        <div className={classes.item}>
          <Input
            name="destination"
            placeholder="Enter Address or BNS name"
            value={destination}
            onChange={handleDestinationChange}
            error={destinationError}
            info={shortenAddress(bnsContent || "")}
          />
        </div>
        {selectedTab === Tabs.STX20 && (
          <div className={classes.item}>
            <Input
              name="total"
              placeholder="Amount"
              value={total}
              onChange={handleTotalChange}
              error={totalError}
            />
          </div>
        )}
      </div>
      <div className={classes.buttonWrapper}>
        {totalCharacterSizeError && (
          <span className={classes.error}>{totalCharacterSizeError}</span>
        )}
        <BigPurpuleButton
          onClick={handleTransfer}
          text={"Transfer"}
          disabled={isDisabled}
          className={classes.button}
        />
      </div>
    </div>
  );
};
