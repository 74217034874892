import React from "react";
import classes from "./OrderCard.module.scss";
import { Skeleton } from "../Skeleton/Skeleton.tsx";
import classNames from "classnames";
import { OrderType } from "../../types/orders.ts";

type Props = {
  orderType: OrderType;
};
export const SkeletonOrderCard = ({ orderType }: Props) => {
  return orderType === OrderType.STXMAP ? (
    <div
      className={classNames(
        classes.component,
        classes.componentStxmapCard,
        classes.skeletonComponent
      )}
    >
      <Skeleton height="200px" width="100%" />
      <div className={classes.skeletonContentWrapper}>
        <div className={classes.skeletonContentWrapper}>
          <div className={classes.skeletonContentWrapper}>
            <Skeleton height="24px" width="100%" />
          </div>
          <Skeleton height="24px" width="100%" />
        </div>
        <Skeleton height="48px" width="100%" />
      </div>
    </div>
  ) : orderType === OrderType.STXS ? (
    <div
      className={classNames(
        classes.component,
        classes.skeletonComponent,
        classes.componentSmallCard,
        classes.pd24
      )}
    >
      <div className={classes.skeletonContentWrapper}>
        <div className={classes.skeletonContentWrapper}>
          <Skeleton height="24px" width="100%" />
        </div>
        <div className={classes.row}>
          <Skeleton height="24px" width="56px" />
          <Skeleton height="24px" width="48px" />
        </div>
        <Skeleton height="48px" width="100%" />
      </div>
    </div>
  ) : (
    <div
      className={classNames(
        classes.component,
        classes.skeletonComponent,
        classes.pd24
      )}
    >
      <div className={classes.skeletonContentWrapper}>
        <div className={classes.skeletonContentWrapper}>
          <Skeleton height="24px" width="100%" />
          <div className={classes.skeletonContentWrapper}>
            <Skeleton height="24px" width="100%" />
            <div className={classes.skeletonContentWrapper}>
              <Skeleton height="24px" width="100%" />
            </div>
          </div>
        </div>
        <div className={classes.mt12} />
        <div className={classes.row}>
          <Skeleton height="24px" width="56px" />
          <Skeleton height="24px" width="48px" />
        </div>
        <Skeleton height="48px" width="100%" />
      </div>
    </div>
  );
};
