import React, { useCallback, useEffect, useState } from "react";
import { SuccessModal } from "../../Modals/SuccessModal/SuccessModal.tsx";
import { Status } from "../../../constants.ts";
import classes from "./Domain.module.scss";
import { Input } from "../InscribeInput/Input.tsx";
import { BigPurpuleButton } from "../../Buttons/BigPurpuleButton/BigPurpuleButton.tsx";
import { validateStacksAddress } from "micro-stacks/crypto";
import { debounce } from "lodash";
import { getDomainDetail } from "../../../Axios/AxiosInstance.ts";
import useData from "../../../hooks/useData.ts";
import { useAddress } from "../../../store/address.ts";
import {
  resolveBnsName,
  resolveStxsName,
  validateDomain,
} from "../../../utils/validation.ts";
import { shortenAddress } from "../../../helpers.ts";

type Props = {};

export const Domain = ({}: Props) => {
  const [status, setStatus] = useState<Status>();
  const [domainValue, setDomainValue] = useState("");
  const [destinationValue, setDestinationValue] = useState("");
  const [domainError, setDomainError] = useState("");
  const [destinationError, setDestinationError] = useState("");
  const [domainLoading, setDomainLoading] = useState(false);
  const { stacksNetwork, makeStxTransfer } = useData();
  const [address] = useAddress();
  const [loading, setLoading] = useState(false);
  const [bnsContent, setBnsContent] = useState<string | null>("");

  useEffect(() => {
    setDomainError(validateDomain(domainValue));
  }, [domainValue]);

  const handleDestinationChange = (val) => {
    setDestinationValue(val);
    setDestinationError("");
    if (!validateStacksAddress(val) && val.length > 0) {
      setDestinationError("Invalid STX address");
    }
    if (val === address) {
      setDestinationError("Connected address");
    }
    if (val.endsWith(".stxs")) {
      resolveStxsName(val).then((res) => {
        if (res) {
          setDestinationError("");
          setBnsContent(res);
        } else {
          setBnsContent(null);
          setDestinationError("Invalid STXS name");
        }
      });
    } else if (val.includes(".")) {
      resolveBnsName(val).then((res) => {
        if (res) {
          setDestinationError("");
          setBnsContent(res);
        } else {
          setDestinationError("Invalid BNS name");
        }
      });
    } else setBnsContent(null);
  };

  const fetchDomain = async (val: string) => {
    if (validateDomain(val)) return;
    setDomainError("");
    setDomainLoading(true);
    try {
      await getDomainDetail(val + ".stxs");
      setDomainError("Domain already exists");
    } catch (error) {
      setDomainError("");
      console.error(error);
    } finally {
      setDomainLoading(false);
    }
  };

  const debounceFn = useCallback(debounce(fetchDomain, 300), []);

  const handleSubmit = async () => {
    if (!!domainError || !!destinationError) {
      return;
    }
    try {
      await makeStxTransfer({
        data: {
          recipient: bnsContent ? bnsContent : destinationValue,
          amount: "1",
          memo: domainValue.toLowerCase() + ".stxs",
          network: stacksNetwork,
          stxAddress: address || "",
        },
        onFinish: () => {
          setStatus(Status.SUCCESS);
          setDestinationValue("");
          setDomainValue("");
          setLoading(false);
        },
        onCancel: () => {
          setStatus(Status.FAIL);
          setLoading(false);
        },
      });
    } catch (error) {
      setStatus(Status.FAIL);
      setLoading(false);
      console.error(error);
    }
  };

  return (
    <div className={classes.component}>
      {!!status && (
        <SuccessModal status={status} onClose={() => setStatus(undefined)} />
      )}
      <div className={classes.wrapper}>
        <h2 className={classes.title}>Claim your .stxs domain here!</h2>
        <span className={classes.subtitle}>
          Only latin letters, numbers and characters "-" "_" are allowed
        </span>
        <div className={classes.inputsWrapper}>
          <div className={classes.domainWrapper}>
            <Input
              className={classes.input}
              name={"domain"}
              placeholder={"Domain name"}
              value={domainValue}
              onChange={(value) => {
                setDomainLoading(true);
                setDomainValue(value.trim());
                debounceFn(value.trim());
              }}
              error={domainError}
            />
          </div>
          <Input
            name={"destination"}
            placeholder={"Enter Address or BNS name"}
            value={destinationValue}
            onChange={handleDestinationChange}
            error={destinationError}
            info={shortenAddress(bnsContent || "")}
          />
        </div>
        <span className={classes.warning}>
          Make sure you are claiming to a different stacks address than the
          wallet you have connected
        </span>
        <BigPurpuleButton
          disabled={
            !domainValue ||
            !destinationValue ||
            !!domainError ||
            !!destinationError
          }
          className={classes.button}
          text={"Claim"}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};
