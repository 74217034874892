export const DISPLAY_DIGITS_AFTER_ZERO = 12;

export const formatBalance = (amount: number, decimals: number): number => {
  const formatted = amount.toFixed(decimals);
  const parts = formatted.split('.');
  if (parts.length === 2) {
    return Number(parts[0] + '.' + parts[1].slice(0, DISPLAY_DIGITS_AFTER_ZERO));
  } else {
    return Number(formatted);
  }
};

export const castCreateOrderFee = (value: number) => {
  return (value - 1) / Math.pow(10, 6);
}

export const displayFullAmount = (amount: number, decimals: number) =>
  amount.toFixed(decimals).replace(/\.?0+$/, '');
