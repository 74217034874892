import { AppConfig, UserSession } from "@stacks/connect";
import { NETWORK, Network, PROVIDERS } from "../constants.ts";
import { getProvider } from "./getProvider.ts";

export const getUserSessionData = (): string | null => {
    const selectedProvider = getProvider();
    if (selectedProvider && selectedProvider === getProvider(PROVIDERS.Okx))
        return null;
    const appConfig = new AppConfig(['store_write'], document.location.href);
    const userSession = new UserSession({appConfig});
    if (userSession.isUserSignedIn()) {
        const stxAddress = userSession.loadUserData().profile.stxAddress;
        return NETWORK === Network.MAINNET ? stxAddress.mainnet : stxAddress.testnet;
    }
    return '';
}