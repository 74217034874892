import React, { useEffect, useMemo, useState } from "react";
import classes from "./OrderCard.module.scss";
import { StatusButton } from "../Buttons/StatusButton/StatusButton.tsx";
import {
  BuyStx20Modal,
  getPurchaseTxList,
} from "../Modals/BuyStx20Modal/BuyStx20Modal.tsx";
import {
  IPurchaseTransaction,
  IResultSellRequest,
  SellRequestStatus,
} from "../../types/ApiResponseTypes.ts";
import { UseBlockchainCalls } from "../../hooks/useBlockchainCalls.ts";
import { useFees } from "../../hooks/useFees.ts";
import {
  CONFIRMATION_BLOCKS,
  MarketplaceType,
  NETWORK,
  Network,
  Status,
  STX_DECIMALS,
} from "../../constants.ts";
import { displayFullAmount } from "../../types/balance-tools.ts";
import useData from "../../hooks/useData.ts";
import { SellRequestStatusTitle, countStxAmount } from "../../helpers.ts";
import { SuccessModal } from "../Modals/SuccessModal/SuccessModal.tsx";
import { SuccessOrderModal } from "../Modals/SuccessOrderModal/SuccessOrderModal.tsx";
import { ConnectButton } from "../ConnectButton/ConnectButton.tsx";
import { PendingButton } from "../Buttons/PendingButton/PendingButton.tsx";
import classNames from "classnames";
import { CancelOrderModal } from "../Modals/CancelOrderModal/CancelOrderModal.tsx";
import { getBlockHeight, getPurchaseTransactionInformation } from "../../Axios/AxiosInstance.ts";
import { useAddress } from "../../store/address.ts";
import { formatBalance } from "../../types/balance-tools.ts";
import { OrderType } from "../../types/orders.ts";
import { SkeletonStxmap } from "./SkeletonStxmap.tsx";

type Props = {
  order: IResultSellRequest;
  marketplaceType: MarketplaceType;
  orderType: OrderType;
  onSuccess?: () => void;
};

export const OrderCard = ({
  order,
  marketplaceType,
  orderType,
  onSuccess,
}: Props) => {
  const isProcessingQueue =
    order.requestStatus === SellRequestStatus.WAITING_FOR_TRANSACTION;
  const [isBuyModalOpened, setIsBuyModalOpened] = useState(false);

  const [isCancelModalOpened, setIsCancelModalOpened] =
    useState<IPurchaseTransaction[]>();
  const { isAuthorized } = useData();
  const [address] = useAddress();

  const { cancelOrderWithSignature } = UseBlockchainCalls();
  const { stxPrice } = useFees();
  const [txId, setTxId] = useState("");
  const [status, setStatus] = useState<Status | null>();
  const [isCancelBlocked, setCancelBlocked] = useState(false);
  const [blockData, setBlockData] = useState<any>();

  useEffect(() => {
    if (orderType === OrderType.STXMAP && order.map) {
      getBlockHeight(order.map).then(res => setBlockData(res));
    }
  }, [order, orderType]);

  const renderButton = () => {
    const createdByCurrentUser = order.creatorAddress === address;

    if (!isAuthorized) {
      return <ConnectButton className={classes.connectButton} />;
    }
    if (isProcessingQueue) {
      return (
        <StatusButton
          status={SellRequestStatus.WAITING_FOR_TRANSACTION}
          text={"In processing queue"}
          onClick={() => {}}
        />
      );
    }
    if (order.requestStatus === SellRequestStatus.CREATED) {
      if (!createdByCurrentUser) {
        return <StatusButton text={"Buy"} onClick={handleBuyClick} />;
      }
      if (createdByCurrentUser) {
        return <StatusButton text={"Cancel"} onClick={handleCancelClick} />;
      }
    } else {
      return (
        <StatusButton
          text={SellRequestStatusTitle(order.requestStatus)}
          status={order.requestStatus}
          disabled={!!order.requestStatus}
        />
      );
    }
  };

  const isShowPendingBtn =
    order.requestStatus === SellRequestStatus.CREATED &&
    order.pendingPurchaseTx?.length !== 0;

  const stxAmount = countStxAmount(order.stxValue);
  const stxDollarAmount = stxAmount * Number(stxPrice);

  const handleBuyClick = () => {
    if (order.requestStatus === SellRequestStatus.CREATED) {
      setIsBuyModalOpened(true);
    }
    return;
  };

  const handleCancelClick = async () => {
    const txsData = await getPurchaseTransactionInformation(
      order._id,
      orderType
    );
    const data = getPurchaseTxList(txsData, order);
    const isPendingData = txsData.length > 0;
    const isConfirmedTxs = txsData.some((tx) => tx.tx_status === "success");

    if (isPendingData) {
      setIsCancelModalOpened(data);
      setCancelBlocked(isConfirmedTxs);
    } else doCancel();
  };

  const doCancel = () => {
    if (isCancelBlocked) {
      return;
    }
    setIsCancelModalOpened(undefined);
    if (order.requestStatus === SellRequestStatus.CREATED) {
      cancelOrderWithSignature(
        order,
        orderType,
        (res) => {
          setTxId(res);
        },
        () => setStatus(Status.FAIL)
      );
    }
    return;
  };

  const handleItemClick = () => {
    if (order.requestStatus !== SellRequestStatus.WAITING_FOR_TRANSACTION)
      return;
    window.open(
      `https://explorer.hiro.so/txid/${order.creationTxId}?chain=${
        NETWORK === Network.MAINNET ? "mainnet" : "testnet"
      }`,
      `_blank`
    );
  };

  const orderPrice = order.priceRate / Math.pow(10, STX_DECIMALS);

  // console.log("order", order);
  return (
    <div className={classes.root} onClick={handleItemClick}>
      <div
        className={classNames(
          classes.component,
          isProcessingQueue && classes.componentPending,
          order.domain && classes.componentSmallCard,
          order.map && classes.componentStxmapCard
        )}
      >
        <div className={classes.externalLink}>
          <img src="/images/arrow-explore.svg" alt="Explore" />
        </div>
        {txId && (
          <SuccessOrderModal
            txId={txId}
            onClose={() => {
              setTxId("");
              if (onSuccess) onSuccess();
            }}
            title={"Refund transaction sent to your address"}
            info={
              <>
                {" "}
                Please note that it will require{" "}
                <span>{CONFIRMATION_BLOCKS} confirmation blocks</span> before
                your balance updates.
              </>
            }
          />
        )}
        {!!status && (
          <SuccessModal status={status} onClose={() => setStatus(null)} />
        )}
        {order.map && (
          <div className={classes.stxmapImage}>
            {!!blockData?.img && <img src={blockData.img} alt="" />}
            {!blockData?.img && <SkeletonStxmap />}
          </div>
        )}
        <div
          className={classNames(
            classes.contentWrapper,
            isShowPendingBtn && classes.contentWrapperPending
          )}
        >
          <div className={classes.headWrapper}>
            <h3
              className={classNames(
                classes.title,
                isProcessingQueue && classes.titlePending,
                order.map && classes.titleStxsmap
              )}
            >
              {order.ticker && (
                <>
                  {order.value}{" "}
                  <span className={classes.titleCoinSymbol}>
                    {order.ticker}
                  </span>
                </>
              )}
              {order.map && `${order.map}.stxmap`}
              {order.domain && order.domain}
            </h3>
            {order.ticker && (
              <>
                <div className={classes.priceWrapper}>
                  <span className={classes.price}>
                    {displayFullAmount(orderPrice, 6)}
                    {" STX "}
                  </span>
                  <span className={classes.priceToken}> / token</span>
                </div>
                <div className={classes.priceWrapper}>
                  <span className={classes.priceToken}>
                    {" "}
                    ${formatBalance(orderPrice * Number(stxPrice), 3)}
                  </span>
                  <span className={classes.priceToken}> / token</span>
                </div>
              </>
            )}

            {isShowPendingBtn && (
              <PendingButton countTx={order.pendingPurchaseTx?.length || 1} />
            )}
          </div>
          <div className={classes.hr} />
          <div className={classes.dataWrapper}>
            <div className={classes.dataRow}>
              <img width={24} height={24} src="/images/stx-icon.png" alt="" />
              <div className={classes.tokenData}>
                <div className={classes.token}>
                  {displayFullAmount(stxAmount, 4)}
                </div>
                <div className={classes.value}>
                  ${displayFullAmount(stxDollarAmount, 2)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.buttonWrapper}>{renderButton()}</div>
      </div>
      {isBuyModalOpened && (
        <BuyStx20Modal
          onSuccess={onSuccess}
          onClose={() => setIsBuyModalOpened(false)}
          order={order}
          orderType={
            order.ticker
              ? OrderType.TICKER
              : order.map
              ? OrderType.STXMAP
              : OrderType.STXS
          }
          isShowPending={isShowPendingBtn}
        />
      )}
      {!!isCancelModalOpened && (
        <CancelOrderModal
          purchaseTx={isCancelModalOpened}
          onClose={() => setIsCancelModalOpened(undefined)}
          onApprove={doCancel}
          isError={isCancelBlocked}
          orderType={orderType}
        />
      )}
    </div>
  );
};
