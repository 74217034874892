import { StacksMainnet } from "@stacks/network";
import { contractPrincipalCV } from "@stacks/transactions";
import { validateStacksAddress } from "micro-stacks/crypto";

import { getDomainDetail } from "../Axios/AxiosInstance.ts";

export const validateDomain = (domainValue: string) => {
  const maxLength = 25;
  if (domainValue.length > maxLength) {
    return "Max domain length is " + maxLength;
  } else if (
    !/^([A-Za-z0-9_-]{0,25})$/.test(domainValue) &&
    domainValue.length
  ) {
    return "Invalid domain";
  }
  return "";
};

export const validateStxmap = (blockNumberValue: string) => {
  const maxLength = 25;
  if (blockNumberValue.length > maxLength) {
    return "Max block number length is " + maxLength;
  } else if (!/^[0-9]+$/.test(blockNumberValue) && blockNumberValue.length) {
    return "Invalid block number";
  }
  return "";
};

export const resolveBnsName = (
  // network: StacksNetwork,
  name: string
): Promise<string | null> => {
  return fetch(`${new StacksMainnet().coreApiUrl}/v1/names/${name}`)
    .then((r) => r.json())
    .then((r) => {
      if (r.error) {
        return null;
      }

      return r.address;
    });
};

export const resolveStxsName = async (domain: string) => {
  try {
    const response = await getDomainDetail(domain);
    return response.data.owner;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const contractPrincipalCVFromString = (s: string) => {
  const [a, b] = s.split(".");

  return contractPrincipalCV(a, b);
};

export const isValidRecipient = (r: string) => {
  if (r === "") {
    return false;
  }

  if (validateStacksAddress(r)) {
    return true;
  }

  try {
    contractPrincipalCVFromString(r);

    return true;
  } catch (e) {}

  return false;
};

export const wrapTxId = (txId: string) => {
  return txId.startsWith("0x") ? txId : `0x${txId}`;
};
