import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  getBalanceAddress,
  getTokenTicker,
} from "../../Axios/AxiosInstance.ts";
import { BalanceAddress, TokenTicker } from "../../types/ApiResponseTypes.ts";
import useData from "../../hooks/useData.ts";
import { debounce } from "lodash";
import { Input } from "../InscribeForm/InscribeInput/Input.tsx";
import { useAddress } from "../../store/address.ts";
import { getAccountBalances } from "../../utils/index.ts";

type Props = {
  onTickerErrorHandler: (error: string) => void;
  ticker: string;
  placeholder?: string;
  onChangeTicker: (ticker: string) => void;
  tickerError: string;
  setBalance?: (balance: string) => void;
  editable?: boolean;
  contractTicker?: boolean;
};
export const TickerInputWithBalance = ({
  onTickerErrorHandler,
  ticker,
  placeholder = "Ticker",
  onChangeTicker,
  tickerError,
  setBalance,
  editable = true,
  contractTicker = false,
}: Props) => {
  const [searchTickerResult, setSearchTickerResult] = useState<
    TokenTicker | undefined
  >();
  const [isLoading, setLoading] = useState(false);
  const [searchAddressResult, setSearchAddressResult] = useState<
    BalanceAddress | undefined
  >();
  const [fungibleTokensBalance, setFungibleTokensBalance] = useState(0);
  const [address] = useAddress();
  const { stacksNetwork } = useData();

  const balance = useMemo(() => {
    return contractTicker
      ? fungibleTokensBalance.toString()
      : searchAddressResult?.balances.find(
          (balance) => balance.ticker === ticker.toUpperCase()
        )?.balance || "0";
  }, [
    searchAddressResult,
    searchTickerResult,
    ticker,
    contractTicker,
    fungibleTokensBalance,
  ]);

  useEffect(() => {
    if (setBalance) setBalance(balance);
  }, [balance]);

  const fetchAddres = async () => {
    setLoading(true);
    setSearchAddressResult(undefined);
    try {
      const balanceResponse = await getBalanceAddress(address || "");
      balanceResponse.balances.length > 0 &&
        setSearchAddressResult(balanceResponse);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchFungible = async () => {
    setLoading(true);
    setSearchAddressResult(undefined);
    try {
      const balanceResponse = await getAccountBalances(
        stacksNetwork,
        address || ""
      );

      const accountBalanceResponse =
        balanceResponse.fungible_tokens[
          "SP3K8BC0PPEVCV7NZ6QSRWPQ2JE9E5B6N3PA0KBR9.stx20-stxs::stx20-stxs"
        ];

      setFungibleTokensBalance(
        Number(accountBalanceResponse.balance) / Math.pow(10, 8)
      );
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTicker = async (val: string) => {
    onTickerErrorHandler("");
    if (val.length === 0) {
      return;
    }
    if (
      (!/^[A-Z]+$/.test(val.toUpperCase()) ||
        val.length < 3 ||
        val.length > 8) &&
      !contractTicker
    ) {
      onTickerErrorHandler("Invalid ticker");
      return;
    }
    setLoading(true);

    if (!contractTicker) {
      fetchAddres();

      try {
        const tokenResponse = await getTokenTicker(val.toUpperCase());
        // console.log(tokenResponse);
        setSearchTickerResult(tokenResponse);
      } catch (error) {
        console.error(error);
        onTickerErrorHandler("Ticker does not exist");
      } finally {
        setLoading(false);
      }
    } else {
      fetchFungible();
    }
  };

  const debounceFn = useCallback(debounce(fetchTicker, 300), []);

  const displayBalance = useMemo(
    () =>
      !tickerError && ticker
        ? `Balance: ${balance.length > 0 ? Number(balance) : 0}`
        : "",
    [balance, tickerError, ticker]
  );
  useEffect(() => {
    if (!editable) fetchTicker(ticker);
  }, []);

  return (
    <Input
      name="ticker"
      placeholder={placeholder}
      value={ticker}
      onChange={(val) => {
        onChangeTicker(val);
        debounceFn(val);
      }}
      error={tickerError}
      info={displayBalance}
      disabled={!editable}
      contract={contractTicker}
    />
  );
};
