import React, { useEffect, useMemo, useState } from "react";
import classes from "./BuyStx20ModalStyles.module.scss";
import {
  CONFIRMATION_BLOCKS,
  MarketplaceReceiverAddress,
  NETWORK,
  Network,
  Status,
} from "../../../constants.ts";
import { BigPurpuleButton } from "../../Buttons/BigPurpuleButton/BigPurpuleButton.tsx";
import useData from "../../../hooks/useData.ts";
import { SuccessModal } from "../SuccessModal/SuccessModal.tsx";
import { openSTXTransfer } from "@stacks/connect";
import {
  IPurchaseTransaction,
  IResultSellRequest,
  SellRequestStatus,
} from "../../../types/ApiResponseTypes.ts";
import { SuccessOrderModal } from "../SuccessOrderModal/SuccessOrderModal.tsx";
import { countStxAmount, shortenAddress } from "../../../helpers.ts";
import {
  finalizeOrderPurchase,
  getPurchaseTransactionInformation,
} from "../../../Axios/AxiosInstance.ts";
import { Loader } from "../../Loader/Loader.tsx";
import { useAddress } from "../../../store/address.ts";
import { OrderType } from "../../../types/orders.ts";

type Props = {
  order: IResultSellRequest;
  isShowPending: boolean;
  onSuccess?: () => void;
  onClose: () => void;
  orderType: OrderType;
};

export const PendingData = ({
  purchaseTx,
}: {
  purchaseTx: IPurchaseTransaction[] | undefined;
}) => {
  const renderStatus = (tx: IPurchaseTransaction) => {
    const status = tx.tx_status;
    const errrorStatuses = [
      "abort_by_response",
      "abort_by_post_condition",
      "dropped_replace_across_fork",
      "dropped_too_expensive",
      "dropped_stale_garbage_collect",
      "dropped_replace_by_fee",
    ];
    if (status === "success") {
      return (
        <div className={classes.txStatus}>
          <img src="/images/status-confirmed.svg" alt="" /> Confirmed
        </div>
      );
    }
    if (errrorStatuses.includes(status)) {
      return (
        <div className={classes.txStatus}>
          <img src="/images/status-error.svg" alt="" /> Error
        </div>
      );
    }
    if (status === "pending") {
      return (
        <div className={classes.txStatus}>
          <Loader small /> <span>Pending</span>
        </div>
      );
    }
    return <div className={classes.txStatus}>-</div>;
  };

  return (
    <div className={classes.listTxs}>
      {(purchaseTx || []).map((tx) => {
        return (
          <div className={classes.dataRow} key={tx.tx_id}>
            <div
              className={classes.txWrap}
              onClick={() =>
                window.open(
                  `https://explorer.hiro.so/txid/${tx.tx_id}?chain=${
                    NETWORK === Network.MAINNET ? "mainnet" : "testnet"
                  }`,
                  "_blank"
                )
              }
            >
              <span className={classes.txTitle}>
                {shortenAddress(tx.tx_id)}
              </span>
              <img src="/images/icon-link.svg" alt="" />
            </div>
            {renderStatus(tx)}
          </div>
        );
      })}
    </div>
  );
};

export const getPurchaseTxList = (
  txsData: IPurchaseTransaction[],
  order: IResultSellRequest
) => {
  const orderTxs = new Set([
    ...txsData.map((tx) => tx.tx_id),
    ...(order.pendingPurchaseTx || []),
  ]);
  const data = Array.from(orderTxs).map((txId) => {
    const item = txsData.find((item) => item.tx_id === txId);
    if (item) return item;
    return {
      tx_id: txId,
      sender_address: "",
      tx_status: "",
      fee_rate: "",
    };
  });
  return data;
};

export const BuyStx20Modal = ({
  order,
  isShowPending,
  onClose,
  onSuccess,
  orderType,
}: Props) => {
  const [status, setStatus] = useState<Status | null>();
  const { stacksNetwork, makeStxTransfer } = useData();
  const [txId, setTxId] = useState("");
  const [step, setStep] = useState(1);
  const [isLoading, setLoading] = useState(true);
  const [purchaseTx, setPurchaseTx] = useState<IPurchaseTransaction[]>();
  const [haveMyPendingOrders, setHaveMyPendingOrders] = useState(false);
  const [address] = useAddress();

  const handleBuy = async () => {
    if (isBuyDisabled) return;

    const memoValue =
      orderType === OrderType.TICKER
        ? `orderId: ${order._id}`
        : orderType === OrderType.STXMAP
        ? `mapId: ${order._id}`
        : `stxsId: ${order._id}`;
    await makeStxTransfer({
      data: {
        recipient: MarketplaceReceiverAddress[NETWORK],
        amount: order.totalStxValue,
        memo: memoValue,
        network: stacksNetwork,
        stxAddress: address || "",
      },
      onFinish: (res) => {
        const retryFinalize = () => {
          finalizeOrderPurchase(order._id, res.txId, orderType)
            .then()
            .catch((e) => {
              console.log(e);
              setTimeout(() => retryFinalize(), 2000);
            });
        };
        retryFinalize();
        setTxId(res.txId);
      },
      onCancel: () => {
        setStatus(Status.FAIL);
      },
    });
  };

  const fetchPurchaseTxs = async () => {
    setLoading(true);
    try {
      const txsData = await getPurchaseTransactionInformation(
        order._id,
        orderType
      );
      const data = getPurchaseTxList(txsData, order);
      setHaveMyPendingOrders(
        txsData.some((transaction) => transaction.sender_address === address)
      );
      setPurchaseTx(data);
    } catch (error) {
      // console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPurchaseTxs();
  }, [order]);

  const isBuyDisabled =
    isLoading || purchaseTx?.some((tx) => tx.tx_status === "success");

  const showPendingWarning =
    !isLoading &&
    order.requestStatus === SellRequestStatus.CREATED &&
    haveMyPendingOrders;
  const isTwoSteps = isShowPending || !!purchaseTx?.length;

  if (isLoading) return <></>;

  if (!!txId) {
    return (
      <>
        {!!status && (
          <SuccessModal status={status} onClose={() => setStatus(null)} />
        )}
        <SuccessOrderModal
          txId={txId}
          onClose={() => {
            onClose();
            if (onSuccess) onSuccess();
          }}
          title={"Transaction broadcasted successfully"}
          info={
            <>
              {" "}
              Please wait for{" "}
              <span>{CONFIRMATION_BLOCKS} confirmation blocks</span> until the
              transfer is verified by the marketplace. Then the purchase order
              will appear in My Purchases tab.
            </>
          }
          note={
            "if someone's purchase transaction for this order reaches before yours, you'll get a refund with the Network fee retained."
          }
        />
      </>
    );
  } else {
    return (
      <div className={classes.container}>
        <div className={classes.menuBg} onClick={onClose} />
        {!!status && (
          <SuccessModal status={status} onClose={() => setStatus(null)} />
        )}
        <div className={classes.root}>
          <div className={classes.component}>
            {step === 1 ? (
              <div className={classes.wrapper}>
                <div className={classes.head}>
                  <span className={classes.title}>Buy STX20</span>
                  <button className={classes.close} onClick={onClose}>
                    <img
                      src="/images/close.svg"
                      width={24}
                      height={24}
                      alt=""
                    />
                  </button>
                </div>
                <div className={classes.content}>
                  {showPendingWarning && (
                    <div className={classes.warningWrapper}>
                      <span className={classes.warningContent}>
                        You already have pending purchase request for this
                        order.
                      </span>
                    </div>
                  )}
                  <div className={classes.warningWrapper}>
                    <span className={classes.warningContent}>
                      Transaction with higher Gas fee has faster processing and
                      confirmation times. We recommend setting no less than{" "}
                      <span>0.2 STX</span>
                    </span>
                  </div>
                  <div className={classes.dataRow}>
                    <span className={classes.dataTitle}>Amount</span>
                    <span className={classes.dataValue}>
                      {order.value} {order.ticker}
                    </span>
                  </div>
                  <div className={classes.dataRow}>
                    <span className={classes.dataTitle}>Price</span>
                    <span className={classes.dataValue}>
                      {countStxAmount(order.stxValue)} STX
                    </span>
                  </div>
                  <div className={classes.dataRow}>
                    <span className={classes.dataTitle}>
                      Fees included (2.5%)
                    </span>
                    <span className={classes.dataValue}>
                      {countStxAmount(order.marketFeeValue)} STX
                    </span>
                  </div>
                  <div className={classes.dataRow}>
                    <span className={classes.dataTitle}>Network fee</span>
                    <span className={classes.dataValue}>
                      {countStxAmount(order.gasFeeValueBuyer)} STX
                    </span>
                  </div>
                  <div className={classes.hr} />
                  <div className={classes.dataRow}>
                    <span className={classes.dataTitle}>Total</span>
                    <span className={classes.dataValue}>
                      {countStxAmount(order.totalStxValue)} STX
                    </span>
                  </div>
                  {!isTwoSteps ? (
                    <BigPurpuleButton
                      onClick={handleBuy}
                      text="Buy"
                      className={classes.button}
                      disabled={isBuyDisabled}
                    />
                  ) : (
                    <BigPurpuleButton
                      onClick={() => setStep(2)}
                      text="Review Transactions"
                      className={classes.button}
                    />
                  )}
                </div>
              </div>
            ) : (
              <div className={classes.wrapperTxs}>
                <div className={classes.head}>
                  <span className={classes.title}>Purchase transactions</span>
                  <button className={classes.close} onClick={onClose}>
                    <img
                      src="/images/close.svg"
                      width={24}
                      height={24}
                      alt=""
                    />
                  </button>
                </div>
                <div className={classes.content}>
                  <div className={classes.dataNote}>
                    {!isBuyDisabled && (
                      <div className={classes.noteText}>
                        There are active purchase transactions for this order.
                        The possibility to successfully process your transaction
                        is quite low. In case of transaction failure, you'll get
                        a refund with the Network fee retained.
                      </div>
                    )}
                    {isBuyDisabled && (
                      <div className={classes.noteText}>
                        This order has confirmed purchase transaction. The
                        possibility to purchase this item is slim for you. We
                        recommend to explore other available options.
                      </div>
                    )}
                  </div>
                  <PendingData purchaseTx={purchaseTx} />
                  <div className={classes.dataRowGap}>
                    <BigPurpuleButton
                      onClick={() => setStep(1)}
                      text="Back"
                      className={classes.simpleButton}
                    />
                    <BigPurpuleButton
                      onClick={handleBuy}
                      text="Buy"
                      className={classes.button}
                      disabled={isBuyDisabled}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
};
