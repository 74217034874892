import { STXTransferOptions, useConnect } from "@stacks/connect-react";
import {
  ClarityValue,
  cvToHex,
  FungibleConditionCode,
  makeContractSTXPostCondition,
  makeStandardFungiblePostCondition,
  makeStandardSTXPostCondition,
  PostConditionMode,
  contractPrincipalCV,
  uintCV,
  createAssetInfo,
} from "@stacks/transactions";

import {
  LocalStorageKeys,
  NETWORK,
  Network,
  PROVIDERS,
  getUnwrapperContractAddress,
  getUnwrapperContractName,
} from "../constants.ts";
import { getStacksNetwork } from "../helpers.ts";
import { useAtom } from "jotai";
import {
  ContractCallRegularOptions,
  openContractCall,
  openSTXTransfer,
} from "@stacks/connect";
import { useEffect } from "react";
import { authAtom } from "../store/auth.ts";
import { useOkxWallet } from "./useOkxWallet.ts";
import { getProvider } from "../utils/getProvider.ts";
import { useAddress } from "../store/address.ts";

const useData = () => {
  const { doAuth } = useConnect();
  const [isAuthorized, setIsAuthorized] = useAtom(authAtom);
  const {
    authenticateOkxWallet,
    signTransactionOkxWallet,
    signContractCallOkxWallet,
  } = useOkxWallet();

  const [address, setAddress] = useAddress();

  useEffect(() => {
    if (isAuthorized) return;
    const authToken = localStorage.getItem(LocalStorageKeys.JWT_TOKEN);
    if (authToken) setIsAuthorized(true);
  }, [isAuthorized]);

  const makeStxTransfer = async ({
    data,
    onFinish,
    onCancel,
  }: {
    data: STXTransferOptions;
    onFinish?: ({ txId }: { txId: string }) => void;
    onCancel?: () => void;
  }) => {
    const selectedProvider = getProvider();
    if (selectedProvider === getProvider(PROVIDERS.Okx)) {
      try {
        const response = await signTransactionOkxWallet({
          recipient: data.recipient,
          amount: data.amount,
          memo: data.memo,
          stxAddress: data.stxAddress,
        });
        onFinish &&
          onFinish({
            txId: response.txHash.split("0x")[1],
          });
      } catch (e) {
        onCancel && onCancel();
      }
      return;
    }
    return openSTXTransfer(
      {
        recipient: data.recipient,
        amount: data.amount,
        memo: data.memo,
        network: data.network,
        stxAddress: data.stxAddress,
        onFinish: (res) => {
          const id = res.txId;
          onFinish &&
            onFinish({
              txId: id,
            });
        },
        onCancel,
      },
      getProvider()
    );
  };

  const makeAuthRequest = (provider: PROVIDERS) => {
    if (provider) {
      if (provider === PROVIDERS.Okx) {
        authenticateOkxWallet().then((response) => {
          setAddress(response.address);
        });
      } else {
        const selectedProvider = getProvider(provider);
        doAuth({}, selectedProvider);
      }
      localStorage.setItem(LocalStorageKeys.WALLET_TYPE, provider);
      localStorage.setItem(LocalStorageKeys.NETWORK, Network.MAINNET);
    }
  };

  const stacksNetwork = getStacksNetwork(NETWORK);

  const logout = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  const doContractCall = async (
    options: ContractCallRegularOptions,
    onFinish?: ({ txId }: { txId: string }) => void,
    onCancel?: () => void
  ) => {
    const selectedProvider = getProvider();
    if (!selectedProvider) return;
    if (selectedProvider === getProvider(PROVIDERS.Okx)) {
      try {
        const response = await signContractCallOkxWallet({
          ...options,
          functionArgs: options.functionArgs.map((x) =>
            cvToHex(x as ClarityValue)
          ),
          network: undefined,
        });
        console.log("response==", response);
        onFinish &&
          onFinish({
            txId: response.txHash.split("0x")[1],
          });
      } catch (e) {
        console.log("doContractCall e", e);
        onCancel && onCancel();
      }
      return;
    }
    openContractCall(
      {
        ...options,
        onFinish: (response) => {
          console.log("response==", response);
          onFinish &&
            onFinish({
              txId: response.txId,
            });
        },
        onCancel,
      },
      getProvider()
    );
  };

  const unWrap = async (
    amount: number,
    onFinish: () => void,
    onCancel: () => void
  ) => {
    const functionArgs = [
      contractPrincipalCV(
        "SP3K8BC0PPEVCV7NZ6QSRWPQ2JE9E5B6N3PA0KBR9",
        "stx20-stxs"
      ),
      uintCV(amount),
    ];

    doContractCall(
      {
        functionArgs,
        postConditionMode: PostConditionMode.Deny,
        contractAddress: getUnwrapperContractAddress(),
        contractName: getUnwrapperContractName(),
        functionName: "finalize-peg-out",
        postConditions: [
          makeStandardFungiblePostCondition(
            address || "",
            FungibleConditionCode.Equal,
            amount,
            createAssetInfo(
              "SP3K8BC0PPEVCV7NZ6QSRWPQ2JE9E5B6N3PA0KBR9",
              "stx20-stxs",
              "stx20-stxs"
            )
          ),
          makeContractSTXPostCondition(
            "SP3K8BC0PPEVCV7NZ6QSRWPQ2JE9E5B6N3PA0KBR9",
            "stx20-bridge-registry-v1-01",
            FungibleConditionCode.Equal,
            1
          ),
          makeStandardSTXPostCondition(
            address || "",
            FungibleConditionCode.Equal,
            1000000
          ),
        ],
        network: stacksNetwork,
        stxAddress: address || "",
      },
      onFinish,
      onCancel
    );
  };

  return {
    makeAuthRequest,
    logout,
    isAuthorized,
    setIsAuthorized,
    stacksNetwork,
    makeStxTransfer,
    unWrap,
  };
};

export default useData;
