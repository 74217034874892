import React, { useState } from "react";
import classes from "./InscribeForm.module.scss";
import { uniqueId } from "lodash";
import classNames from "classnames";
import { InscribeDeploy } from "./InscribeDeploy/InscribeDeploy.tsx";
import { InscribeTransfer } from "./InscribeTransfer/InscribeTransfer.tsx";
import { InscribeMint } from "./InscribeMint/InscribeMint.tsx";
import { Wrapper } from "../Wrapper/Wrapper.tsx";
import { InscribeType } from "../../constants.ts";
import { Domain } from "./Domain/Domain.tsx";
import { UseBreakpoints } from "../../hooks/useBreakpoints.ts";
import { StxsmapForm } from "./StxsmapForm/StxsmapForm.tsx";

type Props = {};
export const InscribeForm = ({}: Props) => {
  const [inscribeType, setInscribeType] = useState<InscribeType>(
    InscribeType.DEPLOY
  );
  const { isMobile } = UseBreakpoints();

  return (
    <Wrapper className={classes.wrapper}>
      <div className={classes.tabsWrapper}>
        {Object.values(InscribeType).map((type) => (
          <div
            key={uniqueId(type)}
            onClick={() => setInscribeType(type)}
            className={classNames(
              classes.tabButton,
              inscribeType === type ? classes.tabButtonActive : ""
            )}
          >
            {isMobile && type === InscribeType.DOMAIN ? "Domain" : type}
          </div>
        ))}
      </div>
      <div className={classes.tabContentWrapper}>
        {inscribeType === InscribeType.DEPLOY && <InscribeDeploy />}
        {inscribeType === InscribeType.MINT && <InscribeMint />}
        {inscribeType === InscribeType.TRANSFER && <InscribeTransfer />}
        {inscribeType === InscribeType.DOMAIN && <Domain />}
        {inscribeType === InscribeType.STXMAP && <StxsmapForm />}
      </div>
    </Wrapper>
  );
};
