import React, { useState } from "react";
import classes from "./SearchInput.module.scss";
import { Wrapper } from "../Wrapper/Wrapper.tsx";
import classNames from "classnames";

type Props = {
  name: string;
  placeholder: string;
  value: string;
  onChange: (val: string) => void;
  onSearch: () => void;
  className?: string;
  classNameWrapper?: string;
};
export const SearchInput = ({
  name,
  placeholder,
  value,
  onChange,
  onSearch,
  className,
  classNameWrapper,
}: Props) => {
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onSearch();
    }
  };
  return (
    <div className={classNames(classes.component, classNameWrapper || "")}>
      <Wrapper className={classes.wrapper}>
        <img src="/images/icon-search.svg" alt="" className={classes.icon} />
        <input
          value={value}
          type="text"
          autoComplete="off"
          name={name}
          placeholder={placeholder}
          className={classNames(classes.input, className || "")}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          onKeyDown={handleKeyDown}
        />
      </Wrapper>
    </div>
  );
};
