import React, { useState } from "react";
import classes from "./AddressResult.module.scss";
import { uniqueId } from "lodash";
import { Wrapper } from "../../Wrapper/Wrapper.tsx";
import { Token } from "../../Token/Token.tsx";
import { BalanceAddress } from "../../../types/ApiResponseTypes.ts";

type Props = {
  search: BalanceAddress;
};
export const AddressResult = ({ search }: Props) => {
  return search ? (
    <div className={classes.component}>
      <Wrapper className={classes.wrapper}>
        <div className={classes.title}>Token holdings</div>
        <div className={classes.contentWrapper}>
          {search.balances.map((balance) => (
            <Token
              key={uniqueId(balance.ticker)}
              name={balance.ticker}
              amount={balance.balance}
            />
          ))}
        </div>
      </Wrapper>
    </div>
  ) : (
    <></>
  );
};
