import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import { Connect } from "@stacks/connect-react";
import { AppConfig, UserSession } from "@stacks/connect";
import { Navigation } from "./Navigation.tsx";
import { useAddress } from "./store/address.ts";
import { NETWORK, Network } from "./constants.ts";
import { WalletConnection } from "./WalletConnection.tsx";
import { useAtom } from "jotai";
import { stxPriceAtom } from "./store/stxPrice.ts";
import { getStxPrice } from "./Axios/AxiosInstance.ts";

function App() {
  const [, setStxPrice] = useAtom(stxPriceAtom);

  const appConfig = new AppConfig(
    ["store_write", "publish_data"],
    document.location.href
  );
  const userSession = new UserSession({ appConfig });
  const [, setAddress] = useAddress();

  const authOptions = {
    manifestPath: "/static/manifest.json",
    redirectTo: "/",
    userSession,
    onFinish: (res) => {
      const userData = res.userSession.loadUserData().profile.stxAddress;
      setAddress(
        NETWORK === Network.MAINNET ? userData.mainnet : userData.testnet
      );
    },
    appDetails: {
      name: "STX20",
      icon: "/images/logo.svg",
    },
  };

  useEffect(() => {
    const fetchStxPrice = async () => {
      try {
        const res = await getStxPrice();
        const currentPrice = res.data.priceUsd;
        setStxPrice(currentPrice);
      } catch (error) {
        console.error("Error fetching STX price:", error);
      }
    };
    fetchStxPrice();
  }, []);

  return (
    <Connect authOptions={authOptions}>
      <WalletConnection>
        <Router>
          <Navigation />
        </Router>
      </WalletConnection>
    </Connect>
  );
}

export default App;
