export const DEV_URL = "https://api-testnet.dev.stx20.com/api/v1/";
export const PROD_URL = "https://api.stx20.com/api/v1/";
export const DEV_MARKETPLACE_URL = "https://api-marketplace.dev.stx20.com/api/v1/";
export const PROD_MARKETPLACE_URL = "https://api-marketplace.stx20.com/api/v1/";
export const DEV_STXMAP_URL = "https://api-stxmap.dev.stx20.com/api/v1/stxmap/";
export const PROD_STXMAP_URL = "https://api-stxmap.stx20.com/api/v1/stxmap/";

export enum OrderStatus {
  CREATED = "Created",
  BUY_IN_PROGRESS = "Buying in progress",
  COMPLETED = "Completed",
  CANCELLING_IN_PROGRESS = "Cancelling in progress",
  CANCELLED = "Cancelled",
}

export enum MarketplaceRole {
  BUYER = "buyer",
  SELLER = "seller",
}
