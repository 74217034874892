import React from "react";
import classes from "./NoOrders.module.scss";
import { Wrapper } from "../../../Wrapper/Wrapper.tsx";
import { BigPurpuleButton } from "../../../Buttons/BigPurpuleButton/BigPurpuleButton.tsx";

type Props = {
  title: string;
  onClick?: () => void;
  isInitialData: boolean;
};
export const NoOrders = ({ title, onClick, isInitialData }: Props) => {
  return (
    <div className={classes.component}>
      <Wrapper className={classes.wrapper}>
        <img className={classes.icon} src={!isInitialData ? "/images/icon-book.svg" : "/images/icon-search-no-results.svg"} alt="" />
        <h3 className={classes.alertTitle}>{title}</h3>
        {onClick && !isInitialData ? (
          <BigPurpuleButton
            onClick={onClick}
            text="+ Create Sell Order"
            className={classes.createOrderButton}
          />
        ) : (
          <></>
        )}
      </Wrapper>
    </div>
  );
};
